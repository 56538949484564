import { from } from '@nuts/auto-delivery-sdk/dist/utils/money';

import { BaseCustomizer, decodeCustomizerSelections, SkuSelection } from '@/lib/customizers/common';
import { SkuIngredient } from '@/lib/customizers/ingredients';
import { isDefined } from '@/utils/isDefined';
import { Money } from '@/utils/money';

export const INGREDIENT_LIMITS = { min: 6, max: 6 };
const MAX_CATEGORY_DEPTH = 0;
export const BYOB_PRODUCT_KEY = '3719';
export const BYOB_PRODUCT_SKU = '3719-01';

// eslint-disable-next-line import/prefer-default-export
export class BoxCustomizer extends BaseCustomizer<SkuSelection> {
  static readonly maxCategoryDepth = MAX_CATEGORY_DEPTH;

  basePrice: Money = from(0);

  readonly type = 'Boxes';

  constructor(selections?: BoxCustomizer['selections'], quantity?: number, redeemable?: boolean) {
    super(INGREDIENT_LIMITS, selections, quantity, redeemable);
  }

  /**
   * Derive the price from the {@link selections}.
   *
   * @returns a {@link Money} object representing the price of a single quantity of this box customizer
   */
  calculatePrice() {
    const selections = this.selections.filter(isDefined);

    let basePriceByPortion = this.basePrice;
    if (selections.length < INGREDIENT_LIMITS.min) {
      basePriceByPortion = Money.multiply(
        Money.divide(this.basePrice, INGREDIENT_LIMITS.min),
        selections.length,
      );
    }

    const selectionsPrice = Money.sumBy(selections, (s) => s.singlePiecePrice);
    return Money.add(basePriceByPortion, selectionsPrice);
  }

  /**
   * Derive the price from the {@link selections} and {@link quantity}.
   *
   * @returns a {@link Money} object representing the total price of this box customizer
   */
  calculateTotal() {
    return Money.multiply(this.calculatePrice(), this.quantity);
  }

  prefillSelections(options: SkuIngredient[], code?: string) {
    if (!code) return;
    const productKeys = decodeCustomizerSelections(code);
    const ingredients = options.filter((o) => productKeys.includes(o.productKey));
    this.selections = productKeys.map((key) => {
      const ingredient = ingredients.find((i) => i.productKey === key);
      if (!ingredient) return undefined;
      return SkuIngredient.toSelection(ingredient);
    });
  }

  setSelection(slot: number, ingredient?: SkuIngredient) {
    let selection;
    if (ingredient) {
      selection = SkuIngredient.toSelection(ingredient);
    }
    this.selections[slot] = selection;
  }
}
