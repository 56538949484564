<script setup lang="ts">
import { computed } from 'vue';

import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';

interface Props {
  backgroundColor?: string;
  text: string;
  textColor?: string;
}
const props = withDefaults(defineProps<Props>(), {
  backgroundColor: 'nuts-sky',
  textColor: 'white',
});

const bgColor = computed(() => TWColorsBackground[props.backgroundColor] ?? 'bg-nuts-sky-600');
const color = computed(() => TWColorsText[props.textColor] ?? 'white');
</script>

<script lang="ts">
// eslint-disable-next-line import/first
import { BuilderComponent, TWColorsBackground, TWColorsText } from '@/utils/cms';
// eslint-disable-next-line import/prefer-default-export
export const MarqueeBannerRegistration: BuilderComponent = {
  name: 'MarqueeBanner',
  canHaveChildren: true,
  inputs: [
    {
      name: 'backgroundColor',
      type: 'string',
      enum: Object.keys(TWColorsBackground),
      defaultValue: 'nuts-sky',
    },
    {
      name: 'text',
      type: 'string',
      defaultValue: 'Text here',
    },
    {
      name: 'textColor',
      type: 'string',
      enum: Object.keys(TWColorsText),
      defaultValue: 'white',
    },
  ],
};
</script>

<template>
  <div class="flex gap-4 overflow-x-hidden select-none" :class="bgColor" role="marquee">
    <ul class="flex justify-around min-w-full gap-4 py-2 marquee whitespace-nowrap shrink-0">
      <li v-for="item in 10" :key="item">
        <SmallBodyText class="font-semibold" :class="color">
          {{ text }}
        </SmallBodyText>
      </li>
    </ul>
    <ul
      class="flex justify-around min-w-full gap-4 py-2 marquee whitespace-nowrap shrink-0"
      aria-hidden="true"
    >
      <li v-for="item in 10" :key="item">
        <SmallBodyText class="font-semibold" :class="color">
          {{ text }}
        </SmallBodyText>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.marquee {
  animation: marquee 50s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(calc(-100% - 1rem));
  }
}
</style>
