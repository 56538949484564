import { Order, TypedMoney } from '@commercetools/platform-sdk';
import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import mapValues from 'lodash/mapValues';
import type { IsEqual } from 'type-fest/source/internal';

import type { PaymentItem } from '@/composables/usePayment';
import { NutsLineItem } from '@/lib/cart/lineItem';
import {
  CartViewedProductsItem,
  CheckoutStartedProductsItem,
  OrderPlaced,
  OrderPlacedProductsItem,
  ProductClicked,
  productClicked,
  ProductRemoved,
  productsSearched,
  ProductSubscriptionCreated,
} from '@/rudder-typer';
import { isGiftLineItem } from '@/utils/cart';
import { Money } from '@/utils/money';
import { getUnitName } from '@/utils/product';
import { ProductCardData } from '@/utils/productCard';
import { reportError } from '@/utils/reportError';

export interface GoogleEventObjItem {
  coupon?: string;
  index?: number;
  item_id: string;
  item_name?: string;
  item_variant?: string;
  price: number;
  quantity?: number;
}

export interface GoogleEventObj {
  items: GoogleEventObjItem[];
}

export interface NutsProductSubscriptionCreated
  extends Omit<ProductSubscriptionCreated, 'page_section' | 'price'> {
  /**
   * Predefined keywords maintained in Webfront types (e.g. `Added to Auto-Delivery Modal`, `Auto-Delivery Sign-Up Modal`, ...)
   * `Added to Auto-Delivery Modal` - User added a product to auto-delivery from the recommendations in added to auto delivery modal (using the flag `autoDeliveryUpsell`)
   * `Auto-Delivery Sign-Up Modal` - User signed up for auto-delivery from the impulse upsell flow
   * `Auto-Delivery Sign-Up Modal - Easy Reorder` - User signed up for auto-delivery from the easy reorder page
   * `Auto-Delivery Sign-Up Modal - Portal` - User signed up for auto-delivery from the impulse upsell flow in auto-delivery page
   */
  page_section:
    | 'Added to Auto-Delivery Modal'
    | 'Auto-Delivery Sign-Up Modal'
    | 'Auto-Delivery Sign-Up Modal - Easy Reorder'
    | 'Auto-Delivery Sign-Up Modal - Portal';
  /**
   * Single-piece price of variant
   */
  price: Money;
}
export type StandardProductsItem = CartViewedProductsItem;

// assert that various cart/checkout-related RS *ProductsItem types are identical
type StaticAssert<T extends true> = T;
type _check = StaticAssert<IsEqual<StandardProductsItem, CartViewedProductsItem>> &
  StaticAssert<IsEqual<StandardProductsItem, CheckoutStartedProductsItem>> &
  StaticAssert<IsEqual<StandardProductsItem, OrderPlacedProductsItem>>;

export const formatStandardProductsItem = (lineItem: NutsLineItem): StandardProductsItem => ({
  name: lineItem.name?.en,
  price: dollars(lineItem.piecePrice),
  product_id: lineItem.productKey,
  quantity: lineItem.quantity,
  reporting_category: lineItem.variant.attributes?.find((attr) => attr.name === 'reportingCategory')
    ?.value.key,
  sku: lineItem.variant.sku,
  variant: getUnitName(lineItem.variant.variantName) ?? undefined,
});

export const formatOrderPlaced = (context: {
  order: Order;
  paymentItems: PaymentItem[];
}): OrderPlaced => {
  const { order } = context;

  // augment (Nuts)LineItems with extendedListPrice for convenience
  const lineItems: (NutsLineItem & { extendedListPrice: Money })[] = order.lineItems.map((li) => ({
    ...NutsLineItem.fromCt(li),
    extendedListPrice: Money.multiply(
      li.variant.prices?.find((p) => !p.channel)?.value ?? li.price.value,
      li.quantity,
    ),
  }));

  // key payment items amounts by label
  const paymentItems = Object.fromEntries(
    context.paymentItems.map(({ label, amount }) => [label, amount]),
  ) as Record<PaymentItem['label'], TypedMoney | undefined>;

  const coupon = order.discountCodes?.[0]?.discountCode.obj?.name?.en;

  const digitalGiftAdjustment = Money.sumBy(
    order.customLineItems.filter((cli) => cli.slug.match(/GIFT_ADJ_.*-amount/)),
    (cli) => cli.totalPrice,
  );

  const giftWithPurchase = Money.sumBy(
    lineItems.filter(isGiftLineItem),
    (li) => li.extendedListPrice,
  );

  const lineLevelSavings = Money.sumBy(lineItems, (li) => li.totalSavings?.value);

  const dollarAmounts = {
    discount: Money.negate(
      Money.sum([
        digitalGiftAdjustment,
        paymentItems.Adjustment,
        paymentItems.Discount,
        Money.negate(giftWithPurchase),
        Money.negate(lineLevelSavings),
      ]),
    ),
    revenue: Money.sumBy(lineItems, (li) => li.extendedListPrice),
    shipping:
      paymentItems.Shipping &&
      Money.sum([paymentItems['Heat-Resistant Packaging'], paymentItems.Shipping]),
    subtotal: Money.sum([
      digitalGiftAdjustment,
      paymentItems.Adjustment,
      paymentItems.Discount,
      paymentItems.Subtotal,
    ]),
    tax: Money.sum([paymentItems.Duties, paymentItems['GST/HST'], paymentItems.Tax]),
    total: order.taxedPrice?.totalGross,
  };

  return {
    checkout_id: order.cart?.id,
    coupon,
    currency: 'USD',
    order_id: order.orderNumber!, // We are confident that placeOrder always sets this
    products: lineItems.map(formatStandardProductsItem),
    ...mapValues(dollarAmounts, (amount) => (amount ? dollars(amount) : undefined)),
  };
};

export const formatProductRemoved = (lineItem: NutsLineItem, cartId: string): ProductRemoved => ({
  ...formatStandardProductsItem(lineItem),
  cart_id: cartId,
});

export const formatProductClicked = (
  productCard: ProductCardData,
  position?: number,
): ProductClicked => ({
  coupon: productCard.totalSavings?.description?.en,
  name: productCard.name,
  position,
  price: dollars(productCard.piecePrice),
  product_id: productCard.productKey,
  quantity: 1,
  reporting_category: productCard.reportingCategory,
  sku: productCard.sku,
  variant: productCard.unitName,
});

export const sendProductClickedEvent = (productCard: ProductCardData, position?: number): void => {
  try {
    const payload = formatProductClicked(productCard, position);
    productClicked(payload);
  } catch (error) {
    reportError(error);
  }
};

export const sendProductsSearchedEvent = (query: string): void => {
  try {
    if (query) {
      productsSearched({ query });
    }
  } catch (error) {
    reportError(error);
  }
};
