<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import Container from '@/components/base/layout/Container.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { SecondaryNavLink, staticSecondaryNavLinks } from '@/data/nav/secondaryNav';
import { sendNavigationMenuEngagement } from '@/utils/analytics/navigation';

const props = defineProps<{ dynamicNavLinks?: SecondaryNavLink[] }>();

const { flags } = useFeatureFlags();

const linksContainer = ref<HTMLElement>();

const items = computed(() => props.dynamicNavLinks ?? staticSecondaryNavLinks);

const getDataPromo = (name: string) => ({
  'data-promo': '1',
  'data-promo-creative': 'Header Links',
  'data-promo-name': `Secondary Nav: ${name}`,
});

useRouterLinks(linksContainer, useRouter());

function trackEngagement(text: string) {
  sendNavigationMenuEngagement({
    link_text: text,
    menu_name: 'Secondary Navigation',
    source: 'Desktop Secondary Navigation',
  });
}
</script>

<template>
  <div
    :class="flags.layoutRebrandingChangesV1 ? 'bg-white' : 'bg-nuts-orange-50'"
    ref="linksContainer"
  >
    <Container tag="nav" aria-label="Secondary">
      <ul
        class="flex flex-wrap items-center justify-center divide-x divide-solid divide-neutral-300"
        data-test="secondary-nav"
      >
        <li v-for="item in items" :key="item.url" class="flex my-3">
          <a
            v-bind="getDataPromo(item.name)"
            class="flex link"
            :href="item.url"
            @click="trackEngagement(item.name)"
          >
            <span
              class="text-sm leading-[100%] mx-3"
              :class="{ 'text-neutral-500': flags.layoutRebrandingChangesV1 }"
            >
              {{ item.name }}
            </span>
          </a>
        </li>
      </ul>
    </Container>
  </div>
</template>

<style scoped>
.link {
  @apply text-neutral-600;
}
</style>
