<script setup lang="ts">
import { Subscription } from '@nuts/auto-delivery-sdk';
import { from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { useSessionStorage } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

import AddedToSubscriptionActions from '@/components/auto-delivery/added-to-auto-delivery/AddedSubscriptionControls.vue';
import AddToCartOrCustomize from '@/components/base/add-to-cart/AddToCartOrCustomize.vue';
import { useCallback } from '@/composables/useCallback';
import { getProposedDeliveryInterval } from '@/lib/autoDelivery';
import { AnalyticsMetadata } from '@/lib/personalization/dynamicYield';
import { useAutoDelivery } from '@/stores/autoDelivery';
import { sendProductSubscribedEvent } from '@/utils/analytics/productSubscribedEvent';
import { NutsProductSubscriptionCreated } from '@/utils/analytics/rudderstack';
import { BuiltProposedDelivery, getDeliverySubscriptionBySku } from '@/utils/autoDelivery';
import { ProductCardData } from '@/utils/productCard';

const props = defineProps<{
  delivery: BuiltProposedDelivery;
  mode: 'one-time' | 'recurring';
  product: ProductCardData;
}>();

const emit = defineEmits<{
  added: [];
  removed: [];
}>();

const addedSubscription = ref<Subscription>();

const autoDeliveryStore = useAutoDelivery();
const { autoDeliveryDefaultChannel } = storeToRefs(autoDeliveryStore);

async function refreshOrders() {
  await autoDeliveryStore.getOrders({
    preloadPaymentMethod: false,
    preloadShippingAddresses: false,
  });
}

const autoDeliveryUpsellAttribution = useSessionStorage<{
  analyticsMetadata?: AnalyticsMetadata;
}>('autoDeliveryUpsell.attribution', {});

const onAddToAutoDelivery = useCallback(async () => {
  const interval =
    props.mode === 'recurring' ? getProposedDeliveryInterval(props.delivery, 'days') : undefined;

  await autoDeliveryStore.createSubscription({
    active: true,
    interval,
    nextShipOn: props.delivery.shipOn,
    offerLocation: 'Added to Auto-Delivery Modal',
    offerType: props.mode === 'recurring' ? 'IU Recurring' : 'IU Upsell',
    quantity: 1,
    shippingAddressId: props.delivery.shippingAddressId,
    sku: props.product.sku,
  });
  await refreshOrders();
  addedSubscription.value = getDeliverySubscriptionBySku(props.delivery, props.product.sku);

  const { analyticsMetadata } = autoDeliveryUpsellAttribution.value;

  if (addedSubscription.value) {
    const payload: NutsProductSubscriptionCreated = {
      product_subscription_id: addedSubscription.value.id,
      currency: 'USD',
      dy_campaign_id: analyticsMetadata?.campaignId,
      dy_campaign_name: analyticsMetadata?.campaignName,
      dy_experience_id: analyticsMetadata?.experienceId,
      dy_experience_name: analyticsMetadata?.experienceName,
      dy_variation_id: analyticsMetadata?.variationId,
      dy_variation_name: analyticsMetadata?.variationName,
      interval: interval ?? 0,
      name: props.product.name,
      page_section: 'Added to Auto-Delivery Modal',
      price: from(
        props.product.prices.find(
          (p) => p.channel?.obj.key === autoDeliveryDefaultChannel.value.key,
        )!.value,
      ),
      product_id: props.product.productKey,
      quantity: 1,
      reporting_category: props.product.reportingCategory,
      sku: props.product.sku,
    };
    sendProductSubscribedEvent(payload);
  }

  emit('added');
});

const handleRemove = useCallback(async () => {
  if (!addedSubscription.value) return;
  await autoDeliveryStore.cancelSubscription(
    addedSubscription.value,
    'Removed with remove button',
    undefined,
    false,
  );
  await refreshOrders();
  addedSubscription.value = undefined;
  emit('removed');
});
</script>

<template>
  <Transition class="w-full" mode="out-in" name="fade">
    <AddedToSubscriptionActions
      v-if="addedSubscription"
      :delivery
      :isLoading="handleRemove.isPending"
      :subscription="addedSubscription"
      @remove="handleRemove.execute"
    />
    <AddToCartOrCustomize
      v-else-if="product.autoDeliveryEligible"
      class="gap-1 px-4"
      fullWidth
      :isLoading="onAddToAutoDelivery.isPending"
      theme="blue"
      @add-to-cart="onAddToAutoDelivery.execute"
    >
      <template #add-to-cart-text>
        <template v-if="onAddToAutoDelivery.isPending">Adding</template>
        <template v-else-if="mode === 'recurring'">
          Add to
          <img
            alt="Auto delivery"
            class="h-3.5 w-20 mt-0.5"
            src="@/assets/auto-delivery/logo-white.svg"
          />
        </template>
        <template v-else>Ship One-Time</template>
      </template>
    </AddToCartOrCustomize>
  </Transition>
</template>
