import { HeadObject, useHead } from '@vueuse/head';
import { ComponentOptionsMixin, computed, getCurrentInstance, inject } from 'vue';

declare module '@vue/runtime-core' {
  interface ComponentCustomOptions {
    metaInfo?: HeadObject | (() => HeadObject);
  }
}

export default {
  created() {
    const instance = getCurrentInstance();

    // If we're running in an Algolia clone, useHead() isn't installed and we
    // don't want duplicate metainfo anyway.
    if (inject('is-ais-clone', false)) {
      return;
    }

    const { metaInfo } = (instance?.type as any) ?? {};
    if (typeof metaInfo === 'function') {
      useHead(computed(metaInfo.bind(this)));
    } else if (metaInfo) {
      useHead(metaInfo);
    }
  },
} as ComponentOptionsMixin;
