<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

import { ChatStatus, useChat } from '@/composables/useChat';
import { CmsRegisteredComponent } from '@/utils/cms';

const ChatNow = defineComponent({
  name: 'ChatNow',
  setup() {
    const { openChat } = useChat();
    const chatAvailable = ref(false);

    onMounted(() => {
      const status = window.Kustomer.isChatAvailable();
      chatAvailable.value = status.availability === ChatStatus.ONLINE;
    });

    return {
      chatAvailable,
      openChat,
    };
  },
});

export const ChatNowRegistration: CmsRegisteredComponent = {
  component: ChatNow,
  name: 'Chat Now',
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Chat CTA text here' } },
    },
  ],
};

export default ChatNow;
</script>

<template>
  <div v-if="chatAvailable" class="flex flex-col items-center cursor-pointer" @click="openChat">
    <slot />
  </div>
  <a v-else href="mailto:help@nuts.com" class="flex flex-col items-center cursor-pointer">
    <span class="w-0 h-0">Mailto link</span>
    <slot />
  </a>
</template>
