<script setup lang="ts">
import { computed } from 'vue';

import { IconDetails, IconSize } from '@/utils/svg';

interface Props {
  fill: string;
  /**
   * Single size or a tuple for mobile (< md) and desktop (>= md).
   */
  size?: IconSize | [IconSize, IconSize];
  viewBox: string;
}

const props = withDefaults(defineProps<Props>(), {
  size: 24 as IconSize,
});

const size = computed<[IconSize, IconSize]>(() => {
  if (typeof props.size === 'number') {
    return [props.size, props.size];
  }
  return props.size;
});

const sizeClasses = computed(() => {
  const [mobile, desktop] = size.value;

  const mobileDimensions = IconDetails[mobile].dimensions.default;
  let classes = mobileDimensions;

  if (mobile !== desktop) {
    const desktopDimensions = IconDetails[desktop].dimensions.md;
    classes += ` ${desktopDimensions}`;
  }

  return classes;
});
</script>

<template>
  <svg class="shrink-0" :class="sizeClasses" :viewBox="viewBox" :fill="fill">
    <slot />
  </svg>
</template>
