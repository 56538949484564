<script setup lang="ts">
import { useIntervalFn } from '@vueuse/core';
import dayjs from 'dayjs';
import { computed, ref, watch } from 'vue';

import { ShippingCutoff } from '@/api/predictedShipping';
import { friendlyFormat, secondsToHoursAndMinutes } from '@/utils/dateTime';

const props = defineProps<{
  countdownColor?: string;
  includeSeconds?: boolean;
  shippingCutoff: ShippingCutoff;
}>();

const expiresAt = computed(() => dayjs.unix(props.shippingCutoff.expiresAt));
const expiresInSeconds = ref<number>(expiresAt.value.diff(dayjs(), 'seconds'));

watch(
  () => props.shippingCutoff,
  (newValue) => {
    const updatedExpiration = dayjs.unix(newValue.expiresAt);
    const now = dayjs();
    expiresInSeconds.value =
      now.unix() < updatedExpiration.unix() ? updatedExpiration.diff(now, 'seconds') : 0;
  },
);

const { pause } = useIntervalFn(() => {
  if (expiresInSeconds.value <= 0) {
    pause();
    return;
  }
  expiresInSeconds.value = expiresAt.value.diff(dayjs(), 'seconds');
}, 1000);

const countDownText = computed(() =>
  secondsToHoursAndMinutes(expiresInSeconds.value, props.includeSeconds),
);
</script>

<template>
  <template v-if="shippingCutoff.friendly === 'today' && expiresInSeconds > 0">
    If you order within the next
    <strong :class="countdownColor">{{ countDownText }}</strong> we'll ship
    <strong>today!</strong>
  </template>
  <template v-else-if="expiresInSeconds > 0">
    Order now and we'll ship
    <strong :class="countdownColor">
      <template v-if="shippingCutoff.friendly === 'tomorrow'">tomorrow</template>
      <template v-else>{{ friendlyFormat(shippingCutoff.date) }}</template></strong
    >.
  </template>
</template>
