/* eslint-disable camelcase */
import { fromAxiosNutsJson, fromNutsJson, NutsJson, webstore } from '@/api';

interface BusinessUnitRequest {
  readonly name?: string;
  readonly phone?: string;
  readonly industry?: string;
  readonly otherIndustry?: string;
  readonly employeeCount?: string;
  readonly annualRevenue?: string;
  readonly useCases?: string[];
  readonly otherUseCases?: string;
  readonly businessFeatures?: string[];
  readonly businessNeeds?: string;
  readonly salesSupport?: boolean;
  readonly bookAppointment?: boolean;
  readonly source?: string;
}

interface BusinessUnitResponse {
  readonly name: string;
  readonly phone: string;
  readonly industry: string;
  readonly otherIndustry: string;
  readonly employeeCount: string;
  readonly annualRevenue: string;
  readonly useCases: string[];
  readonly otherUseCases: string;
  readonly businessFeatures: string[];
  readonly businessNeeds: string;
  readonly salesSupport: boolean;
  readonly bookAppointment: boolean;
}

const account = {
  async getBusinessUnit() {
    const { data } = await fromNutsJson(
      webstore.sessionSpecific.get<NutsJson<BusinessUnitResponse>>(
        '/api/business-units/me/profile',
      ),
    );
    return data;
  },
  async createBusinessUnit(request?: BusinessUnitRequest) {
    await fromAxiosNutsJson(
      webstore.sessionSpecific.post<NutsJson<void>>('/api/business-units', request),
      {},
    );
  },
  async updateBusinessUnit(request: BusinessUnitRequest) {
    await fromAxiosNutsJson(
      webstore.sessionSpecific.put<NutsJson<void>>('/api/business-units/me/profile', request),
      {},
    );
  },
};

export default account;
