<script setup lang="ts">
import { useVModel, whenever } from '@vueuse/core';
import { SearchResponse } from 'instantsearch.js';
import { inject, onMounted, ref } from 'vue';

import { AlgoliaSuggestedSearch, init as initAlgoliaClient } from '@/api/algolia';
import RecentSearches from '@/components/layout/header/search/RecentSearches.vue';
import SearchSuggestions from '@/components/layout/header/search/SearchSuggestions.vue';

const props = defineProps<{
  recentSearches: string[];
  query: string;
}>();
const emit = defineEmits<{
  search: [query: string];
  'update:recentSearches': [string[]];
}>();

const algoliaClient = inject('algoliaClient', () => initAlgoliaClient(), true);

const suggestedSearches = ref<SearchResponse<AlgoliaSuggestedSearch>>();

const recentSearches = useVModel(props, 'recentSearches', emit);

const handleClick = (search: string) => {
  emit('search', search);
};

onMounted(async () => {
  const suggestionsIndex = algoliaClient.initIndex('Products_query_suggestions');

  whenever(
    () => props.query,
    async (value) => {
      suggestedSearches.value = await suggestionsIndex.search<AlgoliaSuggestedSearch>(value, {
        hitsPerPage: 6,
      });
    },
  );
});
</script>

<template>
  <div class="flex flex-col gap-3 bg-white" :class="{ 'py-4': query }">
    <RecentSearches
      v-show="!query && recentSearches.length"
      v-model="recentSearches"
      class="py-4"
      @click="handleClick"
    />
    <SearchSuggestions
      v-if="suggestedSearches"
      v-show="query"
      v-model:recentSearches="recentSearches"
      :query="query"
      :suggestions="suggestedSearches.hits"
      @click="handleClick"
    />
  </div>
</template>
