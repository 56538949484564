<script setup lang="ts">
import { useHead } from '@vueuse/head';
import { computed, useTemplateRef } from 'vue';

import { proxiedReportEngagement } from '@/api/dynamic-yield/engagement';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { ContentChoice } from '@/lib/personalization/dynamicYield';
import { isDefined } from '@/utils/isDefined';

const props = defineProps<{
  banner?: ContentChoice;
  containerId?: string;
}>();

const validVariation = (choice?: ContentChoice): choice is ContentChoice =>
  isDefined(choice) &&
  isDefined(choice.variations[0]) &&
  Object.keys(choice.variations[0].payload.data).length > 0;

const variation = computed(() =>
  validVariation(props.banner) ? props.banner.variations[0] : undefined,
);
const content = computed(() => variation.value?.payload.data);

const backgroundImages = computed(() => {
  if (!content.value?.css) return [];
  const pattern = /background-image:[^;]*\burl\(['"]?([^'");]+)['"]?\);/g;
  return Array.from(content.value.css.matchAll(pattern)).map((m) => m[1]);
});

try {
  useHead(
    computed(() => ({
      link: backgroundImages.value.map((href) => ({ rel: 'preload', as: 'image', href })),
      script: content.value?.script ? [{ children: content.value.script, body: true }] : [],
      style: content.value?.css ? [{ children: content.value.css }] : [],
    })),
  );
} catch {
  // do nothing; we are in a surrogate component -- useHead() throws an error if the plugin isn't
  // registered via `app.use()`, which only SPA entry points do. The non-SPA page should be managed differently.
}

const engagement = (choice: ContentChoice) => proxiedReportEngagement(choice.decisionId, choice.id);

const bannerContent = useTemplateRef('bannerContent');
useRouterLinks(bannerContent);
</script>

<template>
  <div v-if="validVariation(banner)" :id="containerId" @click="engagement(banner)">
    <div class="dynamic-content" :data-decision-id="banner.decisionId">
      <div v-if="variation" :data-variation-id="`variation-id-${variation.id}`">
        <div v-if="content?.html" v-html="content.html" ref="bannerContent" />
      </div>
    </div>
  </div>
</template>
