<script setup lang="ts">
import { computed, ref } from 'vue';

import ChevronIcon from '@/components/base/assets/ChevronIcon.vue';
import Collapse from '@/components/base/layout/Collapse.vue';
import Header5 from '@/components/base/typography/Header5.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import { TypographyTags } from '@/utils/accessibility';

interface Props {
  defaultExpanded?: boolean;
  expanded?: boolean;
  headerTag?: TypographyTags;
  id?: string;
  tag?: keyof HTMLElementTagNameMap;
  triggerClasses?: string;
}

const props = withDefaults(defineProps<Props>(), {
  expanded: undefined,
  headerTag: 'span',
  tag: 'div',
  triggerClasses: 'py-4',
});

const emit = defineEmits<{
  (e: 'update:expanded', value: boolean): void;
}>();

const control = ref(props.defaultExpanded);
const controlledByCallsite = computed(() => typeof props.expanded === 'boolean');
const isExpanded = computed({
  get: () => (controlledByCallsite.value ? props.expanded : control.value),
  set: (val) => {
    if (controlledByCallsite.value) {
      emit('update:expanded', val);
    } else {
      control.value = val;
    }
  },
});
</script>

<template>
  <component :is="tag">
    <component :is="headerTag" class="m-0">
      <UnstyledButton
        :aria-controls="id ? `${id}-content` : undefined"
        :aria-expanded="isExpanded"
        class="flex items-center justify-between w-full"
        :class="triggerClasses"
        :id="id ? `${id}-label` : undefined"
        @click="isExpanded = !isExpanded"
      >
        <slot name="title">
          <Header5 headerTag="span"><slot /></Header5>
        </slot>
        <slot name="icon" :isExpanded="isExpanded">
          <ChevronIcon
            aria-hidden="true"
            class="text-neutral-500"
            :direction="isExpanded ? 'up' : 'down'"
            :size="[24, 32]"
          />
        </slot>
      </UnstyledButton>
    </component>
    <Collapse :expanded="isExpanded">
      <section
        :aria-labelledby="id ? `${id}-label` : undefined"
        :hidden="!isExpanded"
        :id="id ? `${id}-content` : undefined"
      >
        <slot name="content" />
      </section>
    </Collapse>
  </component>
</template>
