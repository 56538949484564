/* eslint-disable camelcase */
import { DiscountCode, Money } from '@commercetools/platform-sdk';
import { from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { AxiosRequestConfig } from 'axios';

import { NutsJson, webstore } from '@/api';
import { NutsAddress } from '@/utils/address';
import { DateString } from '@/utils/dateTime';

interface ShipDatesRequest {
  /** array of two letter country code */
  readonly countries: string[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ShippingCalculatorRequestAddress extends Omit<NutsAddress, 'email' | 'phone1'> {}

export interface ShippingCalculatorRequest {
  readonly shipments: {
    readonly address: ShippingCalculatorRequestAddress;
    readonly key: string;
    readonly lines: {
      readonly id?: string;
      readonly meltable?: boolean;
      readonly quantity: number;
      readonly sku: string;
      readonly weight?: number;
    }[];
    readonly shipmentValue?: Money;
  }[];
  readonly allowBlueStreak?: boolean;
  readonly allowCdl?: boolean;
  readonly allowGrandHusky?: boolean;
  readonly allowLasership?: boolean;
  readonly allowOntrac?: boolean;
  readonly allowSplitShipments?: boolean;
  readonly allowTforce?: boolean;
  readonly allowUds?: boolean;
  readonly allowUspsGroundAdvantage?: boolean;
  readonly customerId?: string;
  readonly deliveryDateCalendar?: boolean;
  readonly discountCodes?: DiscountCode[];
  readonly filter?: string;
  readonly requestedDeliveryDate?: DateString;
  readonly requestedShipDate?: DateString;
  readonly warehouses?: ('NJ' | 'NJ2' | 'NV' | 'IN' | 'GA' | 'TX')[];
}

export interface ShipmentPickup {
  readonly carrier: string;
  readonly carrierCode: string;
  readonly carrierPickup?: string;
  readonly earliestArrivalOn: DateString;
  readonly estimatedCarrierCharge: Money;
  readonly latestArrivalOn: DateString;
  readonly shipOn?: DateString;
  readonly shippingOption: string;
  readonly warehouse: 'nj' | 'nj2' | 'nv' | 'in' | 'ga' | 'tx';
  readonly zone: string;
}

export interface ShippingOffer {
  readonly carrier: string;
  readonly carrierCode: string;
  readonly componentOffers: ShippingOffer[];
  readonly containsMeltables: boolean;
  readonly contiguousUs: boolean;
  /** number of "transitDates" */
  readonly days: number;
  readonly earliestArrivalOn: DateString;
  readonly flatRate: boolean;
  readonly heatResistantIncluded: boolean;
  readonly hidden: boolean;
  readonly latestArrivalOn: DateString;
  readonly listPrice: Money;
  readonly meltablePackagesAtRiskCount: number;
  readonly name: string;
  readonly position: number;
  readonly price: Money;
  readonly reliableForHoliday: boolean;
  readonly requestedDeliveryOn?: DateString;
  readonly requestedShipOn: DateString | null;
  readonly shipmentPickup: ShipmentPickup;
  readonly shippingMethodId: number;
  readonly targetPickupCutoff?: string;
  readonly transitDates: DateString[];
  readonly usStates: boolean;
  readonly warnings: string[];
}

const pickupShippingOffer: ShippingOffer = {
  carrier: 'None',
  carrierCode: '00',
  componentOffers: [],
  containsMeltables: false,
  contiguousUs: true,
  days: 0,
  earliestArrivalOn: DateString('0000-00-00'),
  flatRate: true,
  heatResistantIncluded: false,
  hidden: false,
  latestArrivalOn: DateString('0000-00-00'),
  listPrice: from(0),
  meltablePackagesAtRiskCount: 0,
  name: 'Pickup',
  position: 80,
  price: from(0),
  reliableForHoliday: true,
  requestedShipOn: null,
  shipmentPickup: {
    carrier: 'None',
    carrierCode: '00',
    earliestArrivalOn: DateString('0000-00-00'),
    estimatedCarrierCharge: from(0),
    latestArrivalOn: DateString('0000-00-00'),
    shipOn: DateString('0000-00-00'),
    shippingOption: 'Pickup',
    warehouse: 'nj',
    zone: '',
  },
  shippingMethodId: 38,
  transitDates: [],
  usStates: true,
  warnings: [],
};

export interface ShippingOfferSet {
  readonly key: string;
  readonly address: NutsAddress;
  readonly shippingOffers: ShippingOffer[];
}

export interface FutureDeliveryDates {
  dates: [date: DateString, price?: Money, optionName?: string][];
  defaultOptionName: string;
  defaultPrice: Money;
}

export interface ShippingCalculatorResponse {
  offerSets: ShippingOfferSet[];
  futureDeliveryDates?: FutureDeliveryDates;
}

export async function calculateShippingOffers(
  request: ShippingCalculatorRequest,
  options?: AxiosRequestConfig,
  throwError?: boolean,
) {
  const { data } = await webstore.sessionSpecific.post<NutsJson<ShippingCalculatorResponse>>(
    '/api/shipping-calculator',
    { ...request, throwError },
    options,
  );
  return data;
}

export async function getShipDates(request: ShipDatesRequest) {
  const { data } = await webstore.get<NutsJson<{ shipDates: DateString[] }>>(
    '/api/shipping-calculator/ship-dates',
    { params: request },
  );
  return data;
}

export function getStaticPickupShippingOffer(pickupDate: DateString) {
  return {
    ...pickupShippingOffer,
    earliestArrivalOn: pickupDate,
    latestArrivalOn: pickupDate,
    shipmentPickup: {
      ...pickupShippingOffer.shipmentPickup,
      earliestArrivalOn: pickupDate,
      latestArrivalOn: pickupDate,
      shipOn: pickupDate,
    },
  };
}
export default {};
