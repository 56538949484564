<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { sendNavigationMenuEngagement } from '@/utils/analytics/navigation';
import { desktopAttribution, LargeMenu } from '@/utils/navMenu';

const emit = defineEmits<{ close: [] }>();
const props = defineProps<{ menu: LargeMenu }>();

const onLinkClicked = (linkText: string) => {
  sendNavigationMenuEngagement({
    link_text: linkText,
    menu_name: props.menu.header.text,
    source: 'Desktop Mega Menu',
  });

  emit('close');
};

const linksContainer = ref<HTMLElement>();

useRouterLinks(linksContainer, useRouter());
</script>

<template>
  <div class="bg-white">
    <div ref="linksContainer" class="justify-center gap-12 xl:flex">
      <nav class="flex justify-center gap-12 xl:justify-end">
        <div v-for="(section, si) in menu.sections" :key="si" class="w-[10rem]">
          <ul
            v-for="(groupField, index) in section.linkGroups"
            :key="groupField.group.headerText || index"
            :aria-label="`submenu from ${menu.header.text || menu.header.url}`"
            class="mt-8 first:mt-0"
            data-test="mega-menu-submenu"
            role="menu"
          >
            <li v-if="groupField.group.headerText" role="none">
              <a
                v-if="groupField.group.headerUrl"
                v-bind="desktopAttribution(menu, groupField.group.headerText)"
                class="hover:no-underline focus:no-underline"
                :href="groupField.group.headerUrl"
                role="menuitem"
                @click="onLinkClicked(groupField.group.headerText)"
              >
                <BaseBodyText
                  class="font-semibold text-black hover:underline hover:font-semibold decoration-nuts-amber-400 decoration-2 underline-offset-4"
                >
                  {{ groupField.group.headerText }}
                </BaseBodyText>
              </a>
              <BaseBodyText v-else class="font-semibold">
                {{ groupField.group.headerText }}
              </BaseBodyText>
            </li>
            <li
              v-for="(linkField, index) in groupField.group.links"
              :key="linkField.link.url"
              :class="{ 'mt-3': groupField.group.headerText || index > 0 }"
              role="none"
            >
              <a
                v-bind="desktopAttribution(menu, groupField.group.headerText, linkField.link.text)"
                class="hover:no-underline focus:no-underline"
                :href="linkField.link.url"
                role="menuitem"
                @click="onLinkClicked(linkField.link.text)"
              >
                <BaseBodyText
                  class="text-neutral-600 hover:underline hover:font-semibold decoration-nuts-amber-400 decoration-2 underline-offset-4"
                >
                  {{ linkField.link.text }}
                </BaseBodyText>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div
        v-if="menu.promoZones?.length"
        class="flex justify-center gap-6 mt-12 xl:flex-col xl:mt-0"
      >
        <div
          v-for="zone in menu.promoZones"
          :key="zone.url"
          class="flex flex-col max-w-xs"
          role="menu"
        >
          <a
            v-bind="desktopAttribution(menu, zone.text)"
            class="hover:no-underline focus:no-underline gap-y-1"
            :href="zone.url"
            role="menuitem"
            @click="onLinkClicked(zone.text)"
          >
            <img :src="zone.image" :alt="`${zone.text} Image`" class="h-40 w-80" loading="lazy" />
            <BaseBodyText
              class="mt-1 font-semibold text-black hover:underline decoration-nuts-amber-400 decoration-2 underline-offset-4"
            >
              {{ zone.text }}
            </BaseBodyText>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
