import {
  CategoryReference,
  ClientResponse,
  ProductProjectionPagedQueryResponse,
} from '@commercetools/platform-sdk';

import { ctApi } from '@/api';
import { ProductPathsById } from '@/graphql/productPathsById';

interface PrimaryCategoryAttribute {
  name: 'primaryCategory';
  value: CategoryReference;
}

interface UrlPathAttribute {
  name: 'urlName';
  value: string;
}

interface ProductUrlPathPieces {
  masterVariant: {
    attributesRaw: [PrimaryCategoryAttribute, UrlPathAttribute];
    id: string;
  };
  categories: {
    id: string;
    custom: {
      customFieldsRaw: [UrlPathAttribute];
    };
    ancestors: {
      id: string;
      custom: null | {
        customFieldsRaw: [UrlPathAttribute];
      };
    }[];
  }[];
}

interface PartialProductProjectionSearchResult extends ProductUrlPathPieces {
  id: string;
  key: string;
}

export interface ProductPathsByIdQueryResults {
  data: {
    productProjectionSearch: Pick<ProductProjectionPagedQueryResponse, 'count' | 'total'> & {
      results: PartialProductProjectionSearchResult[];
    };
  };
}

function buildProductPath(pathPieces: ProductUrlPathPieces): string | undefined {
  type FlattenedAttributes = Record<'primaryCategory' | 'urlName', string>;
  const { primaryCategory, urlName } =
    pathPieces.masterVariant.attributesRaw.reduce<FlattenedAttributes>((hash, attribute) => {
      const value = attribute.name === 'primaryCategory' ? attribute.value.id : attribute.value;
      return { ...hash, [attribute.name]: value };
    }, {} as FlattenedAttributes);

  const primaryCategoryPieces = pathPieces.categories.find((c) => c.id === primaryCategory);
  if (!primaryCategoryPieces) return undefined;

  const [{ value: primaryCategoryUrlPiece }] = primaryCategoryPieces.custom.customFieldsRaw;
  const ancestors = primaryCategoryPieces.ancestors.reduce<string[]>((pieces, ancestor) => {
    if (!ancestor.custom) return pieces;
    const [{ value: pathPiece }] = ancestor.custom.customFieldsRaw;
    return [...pieces, pathPiece];
  }, []);

  return `/${[...ancestors, primaryCategoryUrlPiece, urlName].join('/')}.html`;
}

export async function productPathsById(ids: string[]) {
  let pathsById: { [productId: string]: string } = {};

  try {
    const {
      body: { data },
    } = (await ctApi
      .graphql()
      .post({
        body: {
          query: ProductPathsById,
          variables: { ids },
        },
      })
      .execute()) as unknown as ClientResponse<ProductPathsByIdQueryResults>;

    pathsById = data.productProjectionSearch.results.reduce((accumulator, result) => {
      const { categories, masterVariant } = result;
      const pathPieces = { categories, masterVariant };
      const productPath = buildProductPath(pathPieces);
      return productPath
        ? {
            ...accumulator,
            [result.id]: productPath,
          }
        : accumulator;
    }, {});
  } catch (_) {
    console.error('Failed to fetch images for products: %o', ids);
  }

  return pathsById;
}
