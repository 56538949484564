<script setup lang="ts">
import { computed, toRef, useAttrs } from 'vue';

import { useForm } from '@/composables/useForm';

export interface SelectOption {
  label: string;
  value: string | number | object;
}

const props = withDefaults(
  defineProps<{
    dataTest?: string;
    invalid?: boolean;
    id?: string;
    label?: string;
    labelSROnly?: boolean;
    modelValue?: string | number | object | undefined;
    options: SelectOption[];
    placeholder?: string;
    showPlaceholder?: boolean;
    size?: 'small' | 'default';
  }>(),
  {
    size: 'default',
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number | object | undefined): void;
  (e: 'blur'): void;
}>();

const attrs = useAttrs();
const { useStyles } = useForm();

const { borderRules, fontRules, sizeRules } = useStyles(
  toRef(props, 'invalid'),
  toRef(props, 'size'),
);
const borderUtilities = computed(() => borderRules.value.join(' '));
const fontUtilities = computed(() => fontRules.value.join(' '));
const sizeUtilities = computed(() => sizeRules.value.join(' '));
const model = computed({
  get: () => props.modelValue ?? (attrs.value as typeof props.modelValue),
  set: (value) => emit('update:modelValue', value),
});
</script>

<template>
  <select
    v-model="model"
    :aria-labelledby="id && label ? `label-${id}` : undefined"
    class="overflow-hidden bg-white appearance-none pr-7 themed-select font-proxima-nova text-ellipsis whitespace-nowrap"
    :class="[borderUtilities, fontUtilities, sizeUtilities]"
    :id="id"
    :data-test="dataTest"
    @blur="$emit('blur', $event)"
  >
    <!-- default slot for default option -->
    <slot />
    <template v-if="showPlaceholder && placeholder && !model">
      <option v-if="model === ''" value="" disabled>{{ placeholder }}</option>
      <option v-if="model === undefined" :value="undefined" disabled>{{ placeholder }}</option>
    </template>
    <option v-for="option in options" :key="`${option.value}`" :value="option.value">
      {{ option.label }}
    </option>
  </select>
</template>

<style lang="scss" scoped>
.themed-select {
  background-image: url('@/assets/caret-down-thick.svg');
  background-position: top 50% right 16px;
  background-repeat: no-repeat;
}
</style>
