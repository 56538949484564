/* eslint-disable no-shadow */
import { Cart, Money } from '@commercetools/platform-sdk';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { fromAxiosNutsJson } from '@/api';
import { getSafcToken } from '@/api/checkout/payment';
import { GiftCertificateResponse } from '@/api/giftCertificates';
import type { RootState } from '@/store/createStore';

export interface ImageUrlsByProductId {
  [id: string]: string | undefined;
}

const namespaced = true;

export const state = () => ({
  rawCart: undefined as Cart | undefined,
  giftCertificate: null as GiftCertificateResponse | null,
  listingImageUrlsByProductId: {} as ImageUrlsByProductId,
  maxQuantity: 4999,
  productPathsById: {} as { [productId: string]: string },
  safcToken: null as string | null,
  totalDiscountedAmount: null as Money | null,
  copiedFromOrderNumber: null as number | null,
});

export type CartState = ReturnType<typeof state>;

/** ********** */
// GETTERS //
/** ********** */
export const getters: GetterTree<CartState, RootState> = {};

/** ********** */
// MUTATIONS //
/** ********** */
export const mutations: MutationTree<CartState> = {
  SET_RAW_CART(state, rawCart: Cart) {
    state.rawCart = rawCart;
  },
  SET_GIFT_CERTIFICATE(state, giftCertificate?: GiftCertificateResponse) {
    state.giftCertificate = giftCertificate ?? null;
  },
  SET_SAFC_TOKEN(state, safcToken?: string) {
    state.safcToken = safcToken ?? null;
  },
  SET_COPIED_FROM_ORDER_NUMBER(state, orderNumber: number | null) {
    state.copiedFromOrderNumber = orderNumber;
  },
  UPDATE_PRODUCT_PATHS_BY_ID(state, pathsById: { [productId: string]: string }) {
    state.productPathsById = {
      ...state.productPathsById,
      ...pathsById,
    };
  },
  UPDATE_VARIANT_IMAGE_CACHE(state, imageMap: ImageUrlsByProductId) {
    state.listingImageUrlsByProductId = {
      ...state.listingImageUrlsByProductId,
      ...imageMap,
    };
  },
};

/** ******** */
// ACTIONS //
/** ******** */
export const actions: ActionTree<CartState, RootState> = {
  cacheProductImageMap({ commit }, imageMap: ImageUrlsByProductId) {
    commit('UPDATE_VARIANT_IMAGE_CACHE', imageMap);
  },

  async fetchSafcToken({ commit }, email?: string) {
    try {
      const { safcToken } = await fromAxiosNutsJson(getSafcToken(email), {
        onMessages: 'default',
      });
      commit('SET_SAFC_TOKEN', safcToken);
    } catch (e) {
      /* it's okay, if not signed in or not privileged, there won't be a token */
    }
  },

  setGiftCertificate({ commit }, giftCertificate?: GiftCertificateResponse) {
    commit('SET_GIFT_CERTIFICATE', giftCertificate);
  },

  setCopiedFromOrderNumber({ commit }, orderNumber: number | null) {
    commit('SET_COPIED_FROM_ORDER_NUMBER', orderNumber);
  },
};

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
