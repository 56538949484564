<script setup lang="ts">
import { useEventListener } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';

import CloseModalButton from '@/components/base/layout/modal/CloseModalButton.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import { useDialog } from '@/stores/dialog';

const props = withDefaults(
  defineProps<{
    anchor?: 'center' | 'mobile-bottom' | 'right';
    backgroundColor?: string;
    borderRadius?: string;
    disableConfirmButton?: boolean;
    hasControls?: boolean;
    hasDefaultPadding?: boolean;
    isLoading?: boolean;
    isOpen: boolean;
    skipFirstElementOnFocus?: boolean;
    width?: string;
  }>(),
  {
    anchor: 'center',
    backgroundColor: 'bg-white',
    disableConfirmButton: false,
    hasControls: true,
    hasDefaultPadding: true,
    isLoading: false,
    isOpen: false,
  },
);

const emit = defineEmits(['handle-cancel', 'handle-close', 'handle-confirm']);

const handleCancel = () => emit('handle-cancel');
const handleClose = () => emit('handle-close');
const handleConfirm = () => emit('handle-confirm');

const modalRoot = ref<HTMLDivElement>();

const dialogStore = useDialog();
const { activate, deactivate } = useFocusTrap(modalRoot, {
  immediate: false,
  allowOutsideClick: true,
  clickOutsideDeactivates: true,
  preventScroll: true,
});

const onModalShown = () => {
  dialogStore.activeModalCount += 1;
  window.Kustomer?.stop();
  nextTick().then(() => activate());
};

const onModalHidden = () => {
  dialogStore.activeModalCount = Math.max(0, dialogStore.activeModalCount - 1);
  deactivate();
  if (dialogStore.activeModalCount === 0) {
    window.Kustomer?.start();
  }
};

onMounted(() => {
  if (props.isOpen) onModalShown();

  useEventListener('keydown', (e: KeyboardEvent) => {
    if (!props.isOpen) return;
    if (e.key === 'Escape') {
      handleClose();
      e.preventDefault();
    }
  });
});

onBeforeUnmount(onModalHidden);

watch(
  () => props.isOpen,
  (val) => {
    if (val) onModalShown();
    else {
      onModalHidden();
    }
  },
);
</script>

<template>
  <div
    v-if="isOpen"
    aria-modal="true"
    class="fixed inset-0 z-50 overflow-y-auto scrollbar-hide overscroll-none"
    ref="modalRoot"
    role="dialog"
  >
    <!-- Overlay -->
    <div
      class="absolute inset-0 z-0 bg-black opacity-40 touch-none h-[calc(100%+0.5px)]"
      @click="handleClose"
    />
    <!-- Modal container -->
    <div class="absolute inset-0 flex overflow-hidden opacity-100 pointer-events-none z-1">
      <div
        class="flex flex-1 overflow-hidden"
        :class="{
          'items-end justify-end md:justify-center md:items-center': anchor === 'mobile-bottom',
          'items-center justify-center': anchor === 'center',
          'items-end justify-end md:items-stretch md:justify-end': anchor === 'right',
        }"
      >
        <div
          class="max-h-full overflow-y-auto border-solid shadow-lg pointer-events-auto modal-main isolate overscroll-contain touch-pan-y touch-pan-x"
          :class="[
            backgroundColor,
            borderRadius,
            width,
            {
              'custom-height': anchor === 'center',
              'anchor-bottom-height': anchor === 'mobile-bottom',
              'right-anchor-height': anchor === 'right',
            },
          ]"
        >
          <div class="sticky top-0 right-0 z-50 flex justify-end text-right">
            <slot name="close-button">
              <CloseModalButton class="absolute top-4 right-4" @click="handleClose" />
            </slot>
          </div>
          <div :class="{ 'px-4 py-6 sm:px-6': hasDefaultPadding }">
            <div :class="{ 'mt-4': hasDefaultPadding }">
              <slot name="header" />
            </div>

            <div class="modal-body">
              <slot name="body" />
            </div>

            <slot name="modal-controls">
              <div v-if="hasControls" class="flex justify-between mt-6 md:mt-8">
                <slot name="cancel-button">
                  <ThemedButton
                    class="mr-4 md:w-40"
                    theme="white"
                    type="button"
                    @click="handleCancel"
                    data-test="cancel"
                  >
                    <slot name="cancel-label">Cancel</slot>
                  </ThemedButton>
                </slot>

                <slot name="confirm-button">
                  <ThemedButton
                    class="md:w-40"
                    :disabled="disableConfirmButton"
                    :isLoading="isLoading"
                    theme="gray"
                    type="button"
                    @click="handleConfirm"
                    data-test="confirm"
                  >
                    <slot name="confirm-label">Save</slot>
                  </ThemedButton>
                </slot>
              </div>
            </slot>
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.anchor-bottom-height {
  max-height: 80vh;
}

.custom-height {
  max-height: 100vh;

  @media screen and (min-width: 768px) {
    max-height: 80vh;
  }
}

.right-anchor-height {
  max-height: 80vh;
  @media screen and (min-width: 768px) {
    max-height: 100vh;
  }
}

.signin-modal-custom-width {
  width: 424px;
}
</style>
