import {
  ClientResponse,
  Image,
  ProductProjectionPagedQueryResponse,
} from '@commercetools/platform-sdk';

import { ctApi } from '@/api';
import { ProductImagesById } from '@/graphql/productImagesById';

type PickedImage = Pick<Image, 'label' | 'url'>;

interface PartialProductProjectionSearchResult {
  id: string;
  masterVariant: {
    images?: PickedImage[];
  };
}

export interface ProductImagesByIdQueryResults {
  data: {
    productProjectionSearch: Pick<ProductProjectionPagedQueryResponse, 'count' | 'total'> & {
      results: PartialProductProjectionSearchResult[];
    };
  };
}

export async function productImagesById(ids: string[]) {
  const imagesById: { [productId: string]: PickedImage[] } = {};

  try {
    const {
      body: { data },
    } = (await ctApi
      .graphql()
      .post({
        body: {
          query: ProductImagesById,
          variables: { ids },
        },
      })
      .execute()) as unknown as ClientResponse<ProductImagesByIdQueryResults>;

    data.productProjectionSearch.results.forEach((productProjection) => {
      const { images } = productProjection.masterVariant;
      if (!images?.length) return;
      imagesById[productProjection.id] = images;
    });
  } catch (_) {
    console.error('Failed to fetch images for products: %o', ids);
  }

  return imagesById;
}
