<script>
export default {
  name: 'RouteLink',
  props: {
    to: { type: String, required: true },
  },
  emits: ['click'],
  inheritAttrs: false,
  computed: {
    isFullUrl() {
      return /^((http|https):\/\/)/.test(this.to);
    },
  },
};
</script>

<template>
  <RouterLink v-if="!isFullUrl && $router" custom :to="to" v-slot="{ href, navigate }">
    <a
      :href="href"
      v-bind="$attrs"
      @click="
        $emit('click');
        navigate($event);
      "
    >
      <slot />
    </a>
  </RouterLink>
  <a v-else :href="to" v-bind="$attrs" @click="$emit('click')">
    <slot />
  </a>
</template>
