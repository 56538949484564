import { BYOB_PRODUCT_KEY } from '@/lib/customizers/boxes';
import { MIX_PRODUCT_KEY } from '@/lib/customizers/mixes';
import {
  LARGE_CUSTOM_TRAY_PRODUCT_KEY,
  MEDIUM_CUSTOM_TRAY_PRODUCT_KEY,
} from '@/lib/customizers/trays';

export const requiresCustomizer = (productKey: string) =>
  [
    BYOB_PRODUCT_KEY,
    LARGE_CUSTOM_TRAY_PRODUCT_KEY,
    MEDIUM_CUSTOM_TRAY_PRODUCT_KEY,
    MIX_PRODUCT_KEY,
  ].includes(productKey);

export default {};
