<script setup lang="ts">
import { computed, ref } from 'vue';

import { Category } from '@/lib/customizers/common';

const props = defineProps<{
  activeFilters: string[];
  catalogCategoryTree: Category[];
}>();

const emit = defineEmits(['toggle-category']);

const allFilterActiveStatus = ref(false);

const handleAllCategoryFilter = () => {
  allFilterActiveStatus.value = true;
  emit('toggle-category', 'all');
};

const categoryTree = computed(() =>
  props.catalogCategoryTree?.map((category) => {
    const childrenWithActive = category.children.map((child) => ({
      ...child,
      active: props.activeFilters.includes(child.filter),
    }));
    return {
      ...category,
      active:
        props.activeFilters.includes(category.filter) ||
        childrenWithActive.find((child) => child.active),
      children: childrenWithActive,
    };
  }),
);

const toggleCategoryFilters = (filter: string) => {
  if (allFilterActiveStatus.value === true) {
    allFilterActiveStatus.value = false;
  }
  emit('toggle-category', filter);
};
</script>

<template>
  <div class="flex flex-col items-start">
    <div class="pl-4 lg:pl-0 mobile-scroll-snap-container">
      <div
        class="lg:hidden inline-block text-sm font-proxima-nova font-normal bg-nuts-amber-400 rounded-full mr-1.5 mb-1.5 py-2 px-4 cursor-pointer relative border border-solid"
        :class="allFilterActiveStatus ? 'border-black' : 'border-transparent'"
      >
        <label
          class="mb-0 lg:mt-0 lg:mb-1.5 text-sm lg:font-semibold font-normal leading-4 text-black cursor-pointer font-proxima-nova hover:underline"
          @click.prevent="handleAllCategoryFilter"
          @keydown.enter="handleAllCategoryFilter"
          tabindex="0"
          data-test="all categories"
        >
          All
          <input type="checkbox" :id="`category-filter-all`" class="sr-only" />
        </label>
      </div>
      <div
        v-for="category in categoryTree"
        :key="category.filter"
        class="inline-block text-sm font-proxima-nova font-normal bg-nuts-amber-400 rounded-full mr-1.5 mb-1.5 py-2 px-4 cursor-pointer relative lg:flex lg:flex-col lg:border-none lg:bg-transparent lg:mx-0 lg:mt-0 lg:p-0 border border-solid"
        :class="category.active ? 'border-black' : 'border-transparent'"
        :data-test="category.filter"
      >
        <label
          class="mb-0 lg:mt-0 lg:mb-1.5 text-sm lg:text-lg lg:font-semibold font-normal text-black cursor-pointer font-proxima-nova hover:underline"
          :class="{ underline: category.active }"
          @click.prevent="toggleCategoryFilters(category.filter)"
          @keydown.enter="toggleCategoryFilters(category.filter)"
          tabindex="0"
          :data-test="category.name"
        >
          {{ category.name }}
          <input type="checkbox" :id="`category-filter-${category.filter}`" class="sr-only" />
        </label>
        <div
          v-for="subcategory in category.children"
          :key="subcategory.filter"
          v-show="category.active"
          class="hidden lg:inline-block"
          :data-test="subcategory.filter"
        >
          <label
            class="mb-0 text-sm text-black cursor-pointer lg:text-base font-proxima-nova hover:underline"
            :class="subcategory.active ? 'font-bold' : 'font-extralight'"
            @click.prevent="toggleCategoryFilters(subcategory.filter)"
            @keydown.enter="toggleCategoryFilters(subcategory.filter)"
            tabindex="0"
          >
            {{ subcategory.name }}
            <input
              type="checkbox"
              :id="`subcategory-filter-${subcategory.filter}`"
              class="sr-only"
            />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hidden {
  visibility: visible;
}
.mobile-scroll-snap-container {
  white-space: nowrap;
  scroll-snap-type: x proximity;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
