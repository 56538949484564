<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{ percentage: number }>();
const percentageWidth = computed(() => `width: ${props.percentage}%`);
</script>

<template>
  <div class="transition-[width] duration-500" :style="percentageWidth" />
</template>
