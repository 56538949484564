import { Client, createClient } from '@nuts/auto-delivery-sdk';

import { getCtConfig } from '@/api/config';
import { getSessionId } from '@/utils/isomorphic/cookie';

export const init = () => {
  const accessToken = getSessionId();
  // @ts-expect-error projectKey is required but we never provided it. Fallbacks to `CT_PROJECT_KEY` or `VUE_APP_PROJECT_KEY`
  const client = createClient({ accessToken, auth: getCtConfig() });
  return client;
};

export const orders = async (
  client: Client,
  options: Parameters<Client['getNextDeliveries']>[0] = {
    preloadImagesAndPaths: true,
    preloadPaymentMethod: true,
    preloadShippingAddresses: true,
  },
) => {
  if (!client || !('getNextDeliveries' in client)) {
    return { orders: [] };
  }
  const nextDeliveries = await client.getNextDeliveries(options);

  return {
    orders: nextDeliveries,
  };
};

export default {};
