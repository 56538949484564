<script setup lang="ts">
import { ref } from 'vue';

import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import NavLink from '@/components/layout/footer/NavLink.vue';
import { useReferralElement } from '@/composables/useReferralElement';

interface Link {
  dataTest?: string;
  title: string;
  url: string;
}

interface NavLinkGroup {
  links: Link[];
  title: string;
}

const referralLink = ref<HTMLElement>();

const { showReferralElement } = useReferralElement(referralLink, 'global_footer');

const navLinkGroups: NavLinkGroup[] = [
  {
    title: 'Shop',
    links: [
      { dataTest: 'footer-account-link', title: 'Account', url: '/account' },
      { dataTest: 'footer-easy-reorder-link', title: 'Easy Reorder', url: '/account/easy-reorder' },
      { dataTest: 'footer-wholesale-link', title: 'Wholesale', url: '/wholesale' },
    ],
  },
  {
    title: 'Help',
    links: [
      { dataTest: 'footer-contact-link', title: 'Contact Us', url: '/contact' },
      { dataTest: 'footer-help-link', title: 'Help Center', url: 'https://help.nuts.com/' },
      { dataTest: 'footer-shipping-link', title: 'Shipping', url: '/shipping' },
      { dataTest: 'footer-accessibility-link', title: 'Accessibility', url: '/accessibility.html' },
    ],
  },
  {
    title: 'Company',
    links: [
      { dataTest: 'footer-about-us-link', title: 'About Us', url: '/about-us' },
      { dataTest: 'footer-career-link', title: 'Career', url: '/careers' },
      { dataTest: 'footer-media-link', title: 'Media Inquiries', url: '/media' },
      { dataTest: 'footer-testimonials-link', title: 'Testimonials', url: '/testimonials' },
    ],
  },
];
</script>

<template>
  <div
    v-for="navLinkGroup in navLinkGroups"
    :key="navLinkGroup.title"
    data-test="footer-navigation-links"
  >
    <BaseBodyText class="font-semibold text-white uppercase">
      {{ navLinkGroup.title }}
    </BaseBodyText>
    <ul class="grid gap-2 mt-2">
      <li v-if="navLinkGroup.title === 'Shop' && showReferralElement">
        <NavLink
          title="Refer & Get $20"
          url="/refer"
          data-test="footer-referral-link"
          ref="referralLink"
        />
      </li>
      <li v-for="link in navLinkGroup.links" :key="link.title">
        <NavLink :data-test="link.dataTest" :title="link.title" :url="link.url" />
      </li>
    </ul>
  </div>
</template>
