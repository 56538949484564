import { fromAxiosNutsJson, webstore } from '@/api';

export interface ShippingCutoff {
  date: string;
  expiresAt: number;
  expiresInSeconds?: number;
  friendly?: 'today' | 'tomorrow';
}

export async function getPredictedShippingCutoff() {
  const response = await fromAxiosNutsJson<ShippingCutoff | null>(
    webstore.sessionSpecific.get('/api/predicted-shipping'),
    {},
  );
  return response ?? undefined;
}
