<script lang="ts">
import { defineComponent, ref } from 'vue';

import MinusIcon from '@/components/base/assets/MinusIcon.vue';
import PlusIcon from '@/components/base/assets/PlusIcon.vue';
import Collapse from '@/components/base/layout/Collapse.vue';

export default defineComponent({
  name: 'AccordionTab',
  props: {
    collapsed: { required: false, type: Boolean, default: true },
  },
  components: {
    Collapse,
    MinusIcon,
    PlusIcon,
  },
  setup(props) {
    const contentCollapsed = ref(props.collapsed);
    const toggleContent = () => {
      contentCollapsed.value = !contentCollapsed.value;
    };

    return {
      contentCollapsed,
      plusIcon: nutshell['img/plus.svg'],
      minusIcon: nutshell['img/minus.svg'],
      toggleContent,
    };
  },
});
</script>

<template>
  <div class="py-7">
    <div @click="toggleContent" class="flex justify-between cursor-pointer">
      <div class="text-xl font-semibold font-sofia-pro">
        <slot name="header" />
      </div>
      <PlusIcon v-if="contentCollapsed" alt="plus icon" />
      <MinusIcon v-else alt="minus icon" />
    </div>
    <Collapse class="ml-2 text-base mt-7 md:ml-6" :expanded="!contentCollapsed">
      <slot name="content" />
    </Collapse>
  </div>
</template>
