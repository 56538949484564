import { defineStore } from 'pinia';
import { reactive, readonly } from 'vue';

export interface Notification {
  key?: string;
  message: string;
}
interface KeyedNotification extends Notification {
  key: string;
}
export interface NotificationsByType {
  errors: Notification[];
  notices: Notification[];
  warnings: Notification[];
}
export interface KeyedNotificationsByType {
  errors: KeyedNotification[];
  notices: KeyedNotification[];
  warnings: KeyedNotification[];
}

const hasKey = (n: Notification): n is KeyedNotification => !!n.key;
const hasMessage = (n: KeyedNotification | Notification) => !!n.message;

export const useNotifications = defineStore('notifications', () => {
  const notificationsByType = reactive<NotificationsByType>({
    errors: [],
    notices: [],
    warnings: [],
  });

  const addNotifications = (flashNotificationsByType: Partial<NotificationsByType>) => {
    ['errors', 'warnings', 'notices'].forEach((messageType) => {
      const notifications = flashNotificationsByType[messageType as keyof NotificationsByType];
      if (!notifications) return;

      const queue = notificationsByType[messageType as keyof NotificationsByType];
      const uniqueMessages = new Set(queue.map((m) => m.message));

      notifications?.filter(hasMessage).forEach((notification) => {
        if (notification.key) {
          const index = queue.findIndex((n) => n.key === notification.key);
          if (index > -1) {
            queue.splice(index, 1, notification);
            return;
          }
        }
        if (!uniqueMessages.has(notification.message)) {
          uniqueMessages.add(notification.message);
          queue.push(notification);
        }
      });
    });
  };
  const deleteNotification = (messageType: keyof NotificationsByType, key: string) => {
    const queue = notificationsByType[messageType];
    const index = queue.findIndex((n) => n.key === key);
    if (index > -1) {
      queue.splice(index, 1);
    }
  };
  const dismissAllNotifications = () => {
    Object.keys(notificationsByType).forEach((messageType) => {
      notificationsByType[messageType as keyof NotificationsByType] = [];
    });
  };
  const dismissAllUnkeyedNotifications = () => {
    Object.keys(notificationsByType).forEach((messageType) => {
      notificationsByType[messageType as keyof NotificationsByType] =
        notificationsByType[messageType as keyof NotificationsByType].filter(hasKey);
    });
  };
  const setNotification = (params: {
    key: string;
    message?: string;
    messageType: keyof NotificationsByType;
  }) => {
    const { messageType, ...notification } = params;
    if (!notification.message) {
      deleteNotification(messageType, notification.key);
    } else {
      addNotifications({ [messageType]: [notification] });
    }
  };

  return {
    addNotifications,
    deleteNotification,
    dismissAllNotifications,
    dismissAllUnkeyedNotifications,
    notificationsByType: readonly(notificationsByType),
    rawNotifications: notificationsByType,
    setNotification,
  };
});
