import buildURL from 'axios/lib/helpers/buildURL';
import combineURLs from 'axios/lib/helpers/combineURLs';

const addDebugLogging = (debug, axios) => {
  const describeRequest = (config) => {
    if (!debug.enabled) {
      return '';
    }
    const url = config.url.startsWith('https://')
      ? config.url
      : combineURLs(config.baseURL, config.url);
    return `${config.method.toUpperCase()} ${buildURL(
      url,
      config.params,
      config.paramsSerializer,
    )}`;
  };

  axios.interceptors.request.use((config) => {
    debug(describeRequest(config));
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      debug('%s %s (%s)', response.status, response.statusText, describeRequest(response.config));
      return response;
    },
    (error) => {
      const desc = error.config ? ` (${describeRequest(error.config)})` : '';
      debug('%s: %s%s', error.name, error.message, desc);
      throw error;
    },
  );

  return axios;
};

export default addDebugLogging;
