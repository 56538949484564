<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'FloatingLabel',
  props: {
    active: { required: false, type: Boolean, default: false },
    invalid: { required: false, type: Boolean, default: false },
    alternateBackground: { required: false, type: Boolean, default: false },
  },
  setup(props) {
    const fontUtilities = computed(() => {
      const utilities = ['font-light', 'leading-4', 'md:leading-4', 'text-sm', 'md:text-base'];
      utilities.push(props.invalid ? 'text-nuts-red-800' : 'text-neutral-500');
      return utilities.join(' ');
    });

    return {
      fontUtilities,
      positionUtilities: 'absolute m-4 top-0',
      transitionUtilities: 'transition duration-200',
    };
  },
});
</script>

<template>
  <label
    class="pointer-events-none floating-label touch-action-manipulation"
    :class="[
      fontUtilities,
      positionUtilities,
      transitionUtilities,
      { active },
      { 'gradient-background': alternateBackground },
    ]"
  >
    <slot />
  </label>
</template>

<style lang="scss">
.floating-label.active {
  transform: translateY(-1.25rem);
  font-size: 0.75rem;
  line-height: 0.625rem;
  background: #fff;
  padding: 0 0.125rem;
  &.gradient-background {
    background: linear-gradient(#f5f5f5, #fff);
  }
}
.placeholder-transparent:-webkit-autofill,
select.placeholder-transparent:valid,
input.placeholder-transparent:not(:placeholder-shown) {
  & + label {
    transform: translateY(-1.25rem);
    font-size: 0.75rem;
    line-height: 0.625rem;
    background: #fff;
    padding: 0 0.125rem;
  }
  &.gradient-background {
    background: linear-gradient(#f5f5f5, #fff);
  }
}
.touch-action-manipulation {
  touch-action: manipulation;
}
</style>
