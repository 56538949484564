<script>
export default {
  name: 'AdminCartLookupForm',
  data() {
    return {
      key: null,
      show: false,
    };
  },
  computed: {
    handle() {
      return this.show ? '&laquo;' : '&raquo;';
    },
    cssClasses() {
      return this.show ? 'expanded' : '';
    },
  },
  methods: {
    async lookupCart(e) {
      if (!this.$router) return;
      e.preventDefault();
      this.$router.push({ name: 'CustomerCart', query: { key: this.key } });
    },
  },
  mounted() {
    this.key = new URL(window.location).searchParams.get('key');
  },
};
</script>

<template>
  <div
    role="complementary"
    id="adminCartLookupForm"
    :class="cssClasses"
    data-test="admin-cart-lookup-form"
  >
    <span class="handle" v-html="handle" @click="show = !show" />
    <div v-show="show" class="clearfix cart-lookup-content">
      <form method="GET" action="/cart/customer-cart" @submit="lookupCart">
        <label aria-label="Cart lookup">Cart lookup:</label>
        <input
          type="text"
          class="textfield"
          name="key"
          placeholder="Enter a cart key"
          aria-label="Enter a cart key"
          v-model.trim="key"
        />
        <button aria-label="Find" class="ml-5 p-7 font-size-15 font-weight-400">Find</button>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ml-5 {
  margin-left: 5px;
}
.font-size-15 {
  font-size: 15px;
}
.font-weight-400 {
  font-weight: 400;
}
.p-7 {
  padding: 7px;
}

button {
  font-family: $font-family-proxima;
  font-style: normal;
  border-color: $dusty-grey-alt;
  border-width: 1px;
}
#adminCartLookupForm {
  &.expanded {
    width: 100%;
    opacity: 1;
  }
  background-color: #fee151;
  position: fixed;
  left: 0;
  top: 0;
  width: 25px;
  z-index: 999999;
  overflow: hidden;
  cursor: pointer;
  padding: 5px 0;
  height: 43px;
  opacity: 0.75;
  -webkit-box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.75);
  transition: width 0.5s, opacity 0.5s;

  .handle {
    font-size: 25px;
    display: block;
    height: 30px;
    line-height: 30px;
    float: left;
    margin-left: 5px;
    margin-right: 20px;
    cursor: pointer;
  }
  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 16px;
    margin-right: 10px;
  }
  input[type='submit'] {
    margin-left: 5px;
  }
}
</style>
