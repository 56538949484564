import { RouteLocation, RouteRecordRaw } from 'vue-router';

import { DyPageType } from '@/lib/personalization/dynamicYield';

const AutoDeliveryHelp = () => import('@/views/AutoDeliveryHelp.vue');
const B2BRegistrationPage = () => import('@/components/b2b/B2BRegistrationPage.vue');
const Cart = () => import('@/views/Cart.vue');
const CartAdded = () => import('@/views/CartAdded.vue');
const Checkout = () => import('@/views/Checkout.vue');
const CheckoutFooter = () => import('@/components/layout/footer/CheckoutFooter.vue');
const CheckoutHeader = () => import('@/components/layout/header/CheckoutHeader.vue');
const CMS = () => import('@/views/CMS.vue');
const CustomBox = () => import('@/views/CustomBox.vue');
const BoxCustomizer = () => import('@/views/BoxCustomizer.vue');
const CustomizerHeader = () => import('@/components/layout/header/CustomizerHeader.vue');
const CustomerCart = () => import('@/views/CustomerCart.vue');
const DigitalGiftBuilder = () => import('@/views/DigitalGiftBuilder.vue');
const DigitalGiftRedeemer = () => import('@/views/DigitalGiftRedeemer.vue');
const MixCustomizer = () => import('@/views/MixCustomizer.vue');
const OrderReceipt = () => import('@/views/OrderReceipt.vue');
const OrderUpload = () => import('@/views/OrderUpload.vue');
const Search = () => import('@/views/Search.vue');
const SignIn = () => import('@/views/SignIn.vue');
const SimplifiedHeader = () => import('@/components/layout/header/SimplifiedHeader.vue');
const BuilderPreview = () => import('@/components/cms/BuilderPreview.vue');

export type GtagPageType =
  | 'Account'
  | 'Cart'
  | 'CartAdded'
  | 'Checkout'
  | 'CLP'
  | 'CMS'
  | 'HP'
  | 'PDP'
  | 'PLP'
  | 'Search';
export interface PageTypeMetadata {
  meta: {
    dyPageType: DyPageType;
    gtagPageType?: GtagPageType;
    webstoreAssets?: {
      css?: (string | undefined)[];
      js?: (string | undefined)[];
    };
  };
}

export const routes: (RouteRecordRaw & PageTypeMetadata)[] = [
  {
    path: '/',
    name: 'Homepage',
    component: CMS,
    meta: { dyPageType: 'HOMEPAGE', gtagPageType: 'HP' },
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta: {
      dyPageType: 'CART',
      gtagPageType: 'Cart',
      announcer: {
        message: 'Cart page',
      },
      webstoreAssets: {
        css: [nutshell['css/cart.css']],
      },
    },
  },
  {
    path: '/cart/added',
    name: 'CartAdded',
    component: CartAdded,
    meta: {
      dyPageType: 'CART',
      gtagPageType: 'CartAdded',
      announcer: {
        message: 'Cart added page',
      },
      webstoreAssets: {
        css: [nutshell['css/cart-added.css']],
      },
    },
    props: ({ query: { cartLineId, quantityAdded } }) => ({ cartLineId, quantityAdded }),
  },
  {
    path: '/cart/customer-cart',
    name: 'CustomerCart',
    component: CustomerCart,
    meta: {
      dyPageType: 'CART',
      announcer: {
        message: 'Customer cart page',
      },
      webstoreAssets: {
        css: [nutshell['css/cart.css']],
      },
    },
    props: ({ query: { key } }) => ({ cartKey: key }),
  },
  {
    path: '/search/instant',
    name: 'Search',
    component: Search,
    meta: {
      dyPageType: 'OTHER',
      gtagPageType: 'Search',
      announcer: {
        message: 'Search page',
      },
      webstoreAssets: {
        css: [nutshell['css/instant-search.css']],
      },
    },
  },
  {
    path: '/order-upload',
    name: 'OrderUpload',
    component: OrderUpload,
    meta: {
      dyPageType: 'OTHER',
      announcer: {
        message: 'Order upload page',
      },
    },
  },
  {
    path: '/checkout/receipt',
    name: 'CheckoutReceipt',
    components: {
      default: OrderReceipt,
      pageFooter: CheckoutFooter,
      pageHeader: SimplifiedHeader,
    },
    meta: {
      dyPageType: 'OTHER',
      gtagPageType: 'Checkout',
      announcer: {
        message: 'Receipt page',
      },
    },
    props: {
      default: (route: RouteLocation) => ({ token: route.query.token }),
    },
  },
  {
    path: '/checkout/:step?/:stepVariant?',
    name: 'Checkout',
    components: {
      default: Checkout,
      pageFooter: CheckoutFooter,
      pageHeader: CheckoutHeader,
    },
    meta: {
      dyPageType: 'OTHER',
      gtagPageType: 'Checkout',
      announcer: {
        message: 'Checkout page',
      },
    },
    props: {
      default: true,
    },
  },
  {
    path: '/custom/box',
    name: 'CustomBox',
    components: {
      default: CustomBox,
    },
    props: {
      default: true,
    },
    meta: {
      dyPageType: 'OTHER',
      announcer: {
        message: 'Build your own box page',
      },
    },
  },
  {
    path: '/custom/box/builder',
    name: 'BoxCustomizer',
    components: {
      default: BoxCustomizer,
      pageFooter: { template: '' },
      pageHeader: CustomizerHeader,
    },
    props: {
      default: true,
      pageHeader: { link: '/custom/box', title: 'Build your own box' },
    },
    meta: {
      dyPageType: 'PRODUCT',
      gtagPageType: 'PDP',
      announcer: {
        message: 'Build you own box builder',
      },
    },
  },
  {
    path: '/custom/mixes/builder',
    name: 'MixCustomizer',
    components: {
      default: MixCustomizer,
      pageFooter: { template: '' },
      pageHeader: CustomizerHeader,
    },
    props: {
      default: true,
      pageHeader: { link: '/custom/mixes', title: 'Custom Trail Mix' },
    },
    meta: {
      dyPageType: 'PRODUCT',
      gtagPageType: 'PDP',
      announcer: {
        message: 'Build you own mix builder',
      },
    },
  },
  {
    path: '/auto-delivery',
    name: 'AutoDeliveryHelp',
    component: AutoDeliveryHelp,
    meta: {
      dyPageType: 'OTHER',
      announcer: {
        message: 'Auto delivery help page',
      },
    },
  },
  {
    path: '/gift-drop/build/:step?',
    name: 'DigitalGiftBuild',
    components: {
      default: DigitalGiftBuilder,
      pageFooter: CheckoutFooter,
      pageHeader: SimplifiedHeader,
    },
    props: {
      default: true,
    },
    meta: {
      dyPageType: 'OTHER',
      announcer: {
        message: 'Digital gift build page',
      },
    },
  },
  {
    path: '/gift-drop/redeem/:step?',
    name: 'DigitalGiftRedeem',
    components: {
      default: DigitalGiftRedeemer,
      pageFooter: CheckoutFooter,
      pageHeader: SimplifiedHeader,
    },
    props: {
      default: true,
    },
    meta: {
      dyPageType: 'OTHER',
      announcer: {
        message: 'Digital gift redeem page',
      },
    },
  },
  {
    path: '/sign/in',
    name: 'SignIn',
    components: {
      default: SignIn,
      pageFooter: CheckoutFooter,
      pageHeader: SimplifiedHeader,
    },
    meta: { dyPageType: 'OTHER' },
  },
  {
    path: '/business/register',
    name: 'BusinessRegistration',
    components: {
      default: B2BRegistrationPage,
      pageFooter: CheckoutFooter,
      pageHeader: SimplifiedHeader,
    },
    props: {
      default: true,
    },
    meta: {
      dyPageType: 'OTHER',
      announcer: {
        message: 'B2B registration page',
      },
    },
  },
  {
    path: '/builder-preview',
    name: 'BuilderPreview',
    components: {
      default: BuilderPreview,
      pageFooter: { template: '' },
      pageHeader: { template: '' },
    },
    meta: {
      dyPageType: 'OTHER',
      announcer: {
        message: 'Builder preview page',
      },
    },
  },
];
