/* eslint-disable import/prefer-default-export */
import { ProposedDelivery } from '@nuts/auto-delivery-sdk';

import { BuiltProposedDelivery } from '@/utils/autoDelivery';

/**
 * Default delivery interval in days
 */
export const DEFAULT_DELIVERY_INTERVAL_DAYS = 6 * 7; // 6 weeks

/**
 * Get the minimum interval from subscription list, defaults to {@link DEFAULT_DELIVERY_INTERVAL_DAYS} if not defined
 * @param [format='weeks'] - Format to return the interval in (`days` or `weeks`). Default to `weeks`
 */
export function getProposedDeliveryInterval(
  delivery: BuiltProposedDelivery | ProposedDelivery,
  format: 'days' | 'weeks' = 'weeks',
): number {
  const divisor = format === 'days' ? 1 : 7;
  const intervals = delivery.subscriptions.map((s) => s.interval || DEFAULT_DELIVERY_INTERVAL_DAYS);
  return intervals.length
    ? Math.min(...intervals) / divisor
    : DEFAULT_DELIVERY_INTERVAL_DAYS / divisor;
}
