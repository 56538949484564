<script lang="ts">
import { computed, ref } from 'vue';

import DyProductRecommendationsRow from '@/components/recommendations/DyProductRecommendationsRow.vue';
import { SpacingDirection, spacingInputRegistration, SpacingLevel } from '@/composables/useSpacing';
import { BuilderComponent, TWColorsBackground } from '@/utils/cms';

interface RecommendationsFilter {
  selector: string;
  title: string;
}

interface Props {
  dataPromoName: string;
  enableAddToCart?: boolean;
  filters: RecommendationsFilter[];
  header: string;
  location?: string;
  recosBackgroundColor?: string;
  spacingDirection?: SpacingDirection;
  spacingLevel?: SpacingLevel;
}

// eslint-disable-next-line import/prefer-default-export
export const RecommendationsWithFiltersRegistration: BuilderComponent = {
  name: 'Recommendations With Filters',
  inputs: [
    {
      name: 'dataPromoName',
      type: 'string',
      helperText: 'Use for analytics',
    },
    {
      name: 'header',
      type: 'string',
    },
    {
      name: 'filters',
      type: 'list',
      defaultValue: [
        {
          selector: '',
          title: '',
        },
      ],
      subFields: [
        {
          name: 'selector',
          type: 'string',
          defaultValue: '[TEST] Default Recommendations',
        },
        {
          name: 'title',
          type: 'string',
          defaultValue: 'Title',
        },
      ],
    },
    {
      name: 'recosBackgroundColor',
      type: 'string',
      enum: Object.keys(TWColorsBackground),
      defaultValue: 'cream',
    },
    {
      name: 'enableAddToCart',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'location',
      friendlyName: 'Analytics: Page Type',
      type: 'string',
      helperText: 'Helps filter clicks/impressions in reporting; e.g. "Gift Guide"',
    },
    ...spacingInputRegistration,
  ],
};
</script>

<script setup lang="ts">
const props = withDefaults(defineProps<Props>(), {
  enableAddToCart: true,
  recosBackgroundColor: 'cream',
});

const selectedFilter = ref(props.filters[0]);
const handleSelect = (filter: RecommendationsFilter) => {
  selectedFilter.value = filter;
};
const isSelected = (filter: RecommendationsFilter) => selectedFilter.value.title === filter.title;

const bgColor = computed(() => TWColorsBackground[props.recosBackgroundColor]);
</script>

<template>
  <div data-promo="1" :data-promo-name="dataPromoName">
    <div class="mb-6 font-semibold text-center custom-header">{{ header }}</div>
    <div
      class="flex flex-row items-center gap-3 px-2 mb-6 overflow-auto text-center flex-nowrap md:flex-wrap md:justify-center"
    >
      <div
        v-for="filter in filters"
        :key="filter.title"
        @click="handleSelect(filter)"
        class="px-4 py-2 text-sm border border-solid cursor-pointer md:text-base rounded-4xl whitespace-nowrap"
        :class="isSelected(filter) ? 'bg-black text-white' : 'border-neutral-300 text-black'"
        data-promo="1"
        :data-promo-name="dataPromoName"
        :data-promo-creative="filter.title"
      >
        {{ filter.title }}
      </div>
    </div>
    <div :class="bgColor" class="py-px md:mx-8 md:rounded-2xl md:pl-6 md:pr-8">
      <template v-for="(filter, i) in filters" :key="filter.title">
        <DyProductRecommendationsRow
          v-show="isSelected(filter)"
          :analyticsList="`${dataPromoName}: ${filter.title}`"
          :selector="filter.selector"
          :title="filter.title"
          :trackingEventLocation="location"
          :spacingDirection="spacingDirection"
          :spacingLevel="spacingLevel"
          :enableAddToCart="enableAddToCart"
          flushRightOnMobile
          :prefetch="i === 0"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-header {
  font-size: 28px;

  @media screen and (min-width: $tailwind-md-min) {
    font-size: 40px;
  }
}
</style>
