<script setup lang="ts">
// eslint-disable-next-line simple-import-sort/imports
import { computed, ref } from 'vue';
import { Field } from 'vee-validate';

import FloatingLabel from '@/components/base/form/FloatingLabel.vue';
import OutlinedSelect, { SelectOption } from '@/components/base/form/OutlinedSelect.vue';

interface Props {
  layout?: 'label' | 'placeholder';
  name: string;
  options: SelectOption[];
  placeholder: string;
}

const props = defineProps<Props>();

const inputValue = ref();

const showLabel = computed(() => props.layout === 'label');
const showPlaceholder = computed(() => props.layout === 'placeholder');
</script>

<script lang="ts">
// eslint-disable-next-line import/first, import/no-duplicates
import { BuilderComponent } from '@/utils/cms';

// eslint-disable-next-line import/prefer-default-export
export const OutlinedSelectWrapperRegistration: BuilderComponent = {
  name: 'Select Input',
  inputs: [
    {
      name: 'name',
      type: 'string',
      required: true,
      defaultValue: 'dropdown-name',
    },
    {
      name: 'placeholder',
      type: 'string',
      required: true,
      defaultValue: 'Dropdown placeholder',
      helperText: 'Used for dropdown placeholder and label',
    },
    {
      name: 'options',
      type: 'list',
      required: true,
      helperText: 'Options for the dropdown menu',
      subFields: [
        {
          name: 'label',
          type: 'string',
          defaultValue: 'option-label-example',
        },
        {
          name: 'value',
          type: 'string',
          defaultValue: 'option-value-example',
        },
      ],
    },
    {
      name: 'layout',
      type: 'string',
      defaultValue: 'placeholder',
      enum: ['label', 'placeholder'],
    },
  ],
};
</script>

<template>
  <Field v-model="inputValue" :name="name" v-slot="{ field }">
    <div class="relative mt-5">
      <OutlinedSelect
        v-bind="field"
        :options="options"
        :id="name"
        :placeholder="placeholder"
        :showPlaceholder="showPlaceholder"
        aria-label="placeholder"
      />
      <FloatingLabel v-if="showLabel" :for="name" class="left-0">
        {{ placeholder }}
      </FloatingLabel>
    </div>
  </Field>
</template>
