/* eslint-disable import/prefer-default-export */
import { Price, ProductDiscountValue } from '@commercetools/platform-sdk';
import { money } from '@nuts/auto-delivery-sdk/dist/utils/format';

import { PriceFragment } from '@/graphql/fragments/price';
import { Money } from '@/utils/money';
import { VariantPrice } from '@/utils/productCard';

export const getProductDiscountDisplayValue = (
  discountValue: ProductDiscountValue,
  quantity = 1,
): string | undefined => {
  if (discountValue.type === 'absolute') {
    return `${money(Money.multiply(discountValue.money[0], quantity))}`;
  }
  if (discountValue.type === 'relative') {
    return `${discountValue.permyriad / 100}%`;
  }
  return undefined;
};

export const getProductDiscountDisplayValueFromPrice = (
  price: Price | VariantPrice | PriceFragment,
  quantity = 1,
) => {
  const discount = price.discounted?.discount;
  if (!discount) return undefined;

  const discountValue = 'value' in discount ? discount.value : discount.obj?.value;
  if (!discountValue) return undefined;

  return getProductDiscountDisplayValue(discountValue, quantity);
};
