<script setup lang="ts">
import { SearchResponse } from 'instantsearch.js';
import { useRouter } from 'vue-router';

import { AlgoliaCategory } from '@/api/algolia';
import RouteLink from '@/components/base/RouteLink.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';

defineProps<{ index?: SearchResponse<AlgoliaCategory> }>();
const emit = defineEmits(['navigating']);

const { navigateTo } = useRouteChange(useRouter());
const redirect = (path: string) => {
  emit('navigating');
  navigateTo(path);
};
</script>

<template>
  <div class="suggestions">
    <div
      v-for="hit in index?.hits ?? []"
      :key="hit.objectID"
      class="suggestion"
      data-test="suggestion-see-all"
      @click="redirect(hit.path)"
    >
      <span class="ml-2 algolia-search-item">
        <h4>
          <span class="text-gray-500">See all: &nbsp;</span>
          <RouteLink :to="hit.path">{{ hit.name }}</RouteLink>
        </h4>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.algolia-search-item {
  display: block;
  padding: 7px 0;
  cursor: pointer;
}
.suggestion {
  clear: both;
  cursor: pointer;
  &:hover {
    background-color: $coconut-creamapprox;
  }
}
:deep(a) {
  text-decoration: underline;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: $line-height-large;
}
</style>
