import { MenuLinkClicked, menuLinkClicked, MenuViewed, menuViewed } from '@/rudder-typer';

export type NavigationSource =
  | 'Mobile Navigation'
  | 'Desktop Mega Menu'
  | 'Desktop Secondary Navigation';

export interface NavigationMenuImpression extends MenuViewed {
  readonly source: NavigationSource;
}

export interface NavigationMenuEngagement extends MenuLinkClicked {
  readonly source: NavigationSource;
}

export function sendNavigationMenuImpression(payload: NavigationMenuImpression) {
  menuViewed(payload);
}

export function sendNavigationMenuEngagement(payload: NavigationMenuEngagement) {
  menuLinkClicked(payload);
}
