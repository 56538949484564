import { Cart, CartDiscount, DiscountCode, LineItem, Order } from '@commercetools/platform-sdk';
import { cents, from, fromCents } from '@nuts/auto-delivery-sdk/dist/utils/money';
import uniq from 'lodash/uniq';

import { isDefined } from '@/utils/isDefined';
import { Money } from '@/utils/money';

export interface CartDiscountWithSiteMessages extends CartDiscount {
  custom?: CartDiscount['custom'] & {
    fields: {
      readonly belowThresholdAtZeroMessage?: string;
      readonly belowThresholdMessage?: string;
      readonly flashMessage?: string;
      readonly miniCartMessage?: string;
      readonly notInCartMessage?: string;
    };
  };
}

export const ALL_CARTS_WITHOUT_RESTRICTION_PREDICATE = '1 = 1';
export function getAmountToActivateDiscount(
  cartPredicate: CartDiscount['cartPredicate'],
  cartTotal: Money,
) {
  const nothingRemaining = from(0);
  if (cartPredicate === ALL_CARTS_WITHOUT_RESTRICTION_PREDICATE) {
    return { amountRemaining: nothingRemaining, threshold: from(0) };
  }
  if (!/totalPrice >/.test(cartPredicate)) return undefined;

  const thresholdMatcher = /totalPrice >=? "(?<thresholdString>\d+\.\d{2}) USD"/;
  const match = thresholdMatcher.exec(cartPredicate);
  if (!match?.groups) return undefined;

  const threshold = from(Number(match.groups.thresholdString));
  const neededForThreshold = Money.subtract(threshold, cartTotal);
  const centsNeeded = cents(neededForThreshold);
  let amountRemaining;
  if (centsNeeded === 0 && cartPredicate.includes('totalPrice > ')) {
    amountRemaining = fromCents(1);
  } else {
    amountRemaining = Money.max(neededForThreshold, nothingRemaining);
  }
  return { amountRemaining, threshold };
}

export function getAppliedCartDiscountIds(lineItems: LineItem[]): string[] {
  return uniq(
    lineItems.flatMap((lineItem) =>
      lineItem.discountedPricePerQuantity.flatMap(({ discountedPrice }) =>
        discountedPrice.includedDiscounts.map((portion) => portion.discount.id),
      ),
    ),
  );
}

export function getCartDiscountCodes(cart?: Cart | Order, matchesCart?: boolean): DiscountCode[] {
  const refs = cart?.discountCodes?.filter((d) => {
    if (matchesCart) return d.state === 'MatchesCart';
    if (matchesCart === false) return d.state !== 'MatchesCart';
    return true;
  });
  return refs?.map((d) => d.discountCode.obj).filter(isDefined) ?? [];
}

export function getExpandedCartDiscounts(
  discountCode?: DiscountCode,
): CartDiscountWithSiteMessages[] {
  return discountCode?.cartDiscounts.map((d) => d.obj).filter(isDefined) ?? [];
}
export function getCartDiscountsForMessages(cart?: Cart | Order): CartDiscountWithSiteMessages[] {
  const [matchesCart] = getCartDiscountCodes(cart, true);
  const [doesNotMatchCart] = getCartDiscountCodes(cart, false);

  const discountCode: DiscountCode | undefined = matchesCart ?? doesNotMatchCart;

  return getExpandedCartDiscounts(discountCode);
}

export const SHIPPING_CART_DISCOUNT_KEY = 'shippingCartDiscount';
export function getShippingCartDiscount(cart?: Cart | Order) {
  const cartDiscounts = getCartDiscountCodes(cart).flatMap(getExpandedCartDiscounts);
  return cartDiscounts.find(
    (discount) =>
      discount.custom?.type.obj?.key === SHIPPING_CART_DISCOUNT_KEY &&
      discount.target?.type === 'shipping',
  );
}
