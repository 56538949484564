<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import { CmsRegisteredComponent, TWColorsBackground, TWColorsText } from '@/utils/cms';

const HeroSection = defineComponent({
  name: 'HeroSection',
  props: {
    backgroundColor: { required: false, type: String, default: 'background (secondary)' },
    bgDesktopImg: { required: false, type: String },
    bgMobileImg: { required: false, type: String },
    color: { required: true, type: String, default: 'black' },
    desktopAlignment: {
      required: false,
      type: String as PropType<'right' | 'center' | 'left'>,
      default: 'left',
    },
    mobileAlignment: {
      required: false,
      type: String as PropType<'top' | 'center' | 'bottom'>,
      default: 'bottom',
    },
    headline: { required: true, type: String, default: 'Headline' },
    tagline: { required: false, type: String },
    text: { required: true, type: String, default: 'Text' },
    wave: { required: false, type: Boolean },
  },
  setup(props) {
    return {
      desktopImage: computed(() => `url(${props.bgDesktopImg})`),
      mobileImage: computed(() => `url(${props.bgMobileImg})`),
      textColor: computed(() => TWColorsText[props.color]),
      bgColor: computed(() => TWColorsBackground[props.backgroundColor]),
    };
  },
});
export const HeroSectionRegistration: CmsRegisteredComponent = {
  component: HeroSection,
  name: 'Hero Section',
  canHaveChildren: true,
  inputs: [
    {
      name: 'headline',
      type: 'string',
    },
    {
      name: 'tagline',
      type: 'string',
    },
    {
      name: 'text',
      type: 'string',
    },
    {
      name: 'bgDesktopImg',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
    },
    {
      name: 'bgMobileImg',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
    },
    {
      name: 'wave',
      type: 'boolean',
    },
    {
      name: 'backgroundColor',
      type: 'string',
      enum: Object.keys(TWColorsBackground),
      defaultValue: 'background (secondary)',
    },
    {
      name: 'color',
      type: 'string',
      enum: Object.keys(TWColorsText),
      defaultValue: 'black',
    },
    {
      name: 'desktopAlignment',
      type: 'string',
      enum: ['center', 'left', 'right'],
      defaultValue: 'left',
    },
    {
      name: 'mobileAlignment',
      type: 'string',
      enum: ['center', 'top', 'bottom'],
      defaultValue: 'bottom',
    },
  ],
};
export default HeroSection;
</script>

<template>
  <div
    class="relative flex flex-col items-center grow w-full hero-container background bg-cover bg-no-repeat bg-center"
    :class="[
      bgColor,
      {
        'justify-start': mobileAlignment === 'top',
        'justify-center': mobileAlignment === 'center',
        'justify-end': mobileAlignment === 'bottom',
      },
    ]"
  >
    <div
      class="flex flex-col hero-banner box-border px-4 w-full md:my-16 md:mx-16 md:flex-row"
      :class="{
        'md:w-1/2 lg:justify-start': desktopAlignment === 'left',
        'md:w-3/5 lg:justify-center': desktopAlignment === 'center',
        'md:w-1/2 lg:justify-end': desktopAlignment === 'right',
      }"
    >
      <div
        class="flex flex-col text-center px-0 py-8 hero-foreground box-border md:pr-10 items-center md:items-start md:text-left"
        :class="{
          'md:w-1/2 lg:items-start': desktopAlignment == 'left',
          'md:w-3/5 lg:items-center': desktopAlignment === 'center',
          'md:w-1/2 lg:items-end': desktopAlignment === 'right',
        }"
      >
        <div
          v-if="tagline"
          class="mb-1 text-xs font-semibold tagline-hero font-sofia-pro w-full"
          :class="[textColor, { 'text-center': desktopAlignment === 'center' }]"
        >
          {{ tagline }}
        </div>
        <div
          class="mb-4 font-semibold headline-hero font-sofia-pro w-full"
          :class="[textColor, { 'text-center': desktopAlignment === 'center' }]"
        >
          {{ headline }}
        </div>
        <div
          class="mb-6 text-lg caption-hero w-full"
          :class="[textColor, { 'text-center': desktopAlignment === 'center' }]"
        >
          {{ text }}
        </div>
        <div
          :class="{
            'lg:self-start w-full': desktopAlignment !== 'center',
            'lg:self-center w-full': desktopAlignment === 'center',
          }"
        >
          <slot />
        </div>
      </div>
    </div>
    <img
      v-if="wave"
      alt="herosection  image"
      src="https://nuts.com/images/pdp/2022/wave.4c15e807.png"
      class="absolute bottom-0 w-full img-footer"
    />
  </div>
</template>

<style lang="scss" scoped>
.hero-banner {
  max-width: 1040px;
  width: 100%;
}
.background {
  background-image: v-bind(mobileImage);
}
.headline-hero {
  font-size: 32px;
  line-height: 36px;
}
.hero-container {
  min-height: 700px;
}
.img-footer {
  height: 60px;
}
@media (min-width: 768px) {
  .background {
    background-image: v-bind(desktopImage);
  }
  .img-footer {
    height: 84px;
  }
}
@media (min-width: 1024px) {
  .hero-container {
    min-height: 400px;
  }
  .headline-hero {
    font-size: 48px;
    line-height: 52px;
  }
}
</style>
