<script setup lang="ts">
import { useScrollLock } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { onMounted, watch } from 'vue';

import { useOverlay } from '@/stores/overlay';

const { hide } = useOverlay();
const { scrollUnlocked, visible } = storeToRefs(useOverlay());

onMounted(() => {
  const isLocked = useScrollLock(document.querySelector('body'));

  watch(visible, (b) => {
    if (!scrollUnlocked.value) isLocked.value = b;
  });
});
</script>

<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 bg-black/40"
    :class="{ hidden: !visible }"
    @click="hide"
  />
</template>
