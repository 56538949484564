import sortBy from 'lodash/sortBy';

import {
  BaseCustomizer,
  decodeCustomizerSelections,
  VolumetricSelection,
} from '@/lib/customizers/common';
import { VolumetricIngredient } from '@/lib/customizers/ingredients';
import { isDefined } from '@/utils/isDefined';
import { roundPrecision } from '@/utils/math';
import { Money } from '@/utils/money';

export const INGREDIENT_LIMITS = { min: 1, max: 10 };
const MAX_CATEGORY_DEPTH = 1;
export const MIX_PRICE_MULTIPLIER = 1.2;
export const MIX_PRODUCT_KEY = '9905';
export type MixWeight = 5 | 25;
export const MIX_VARIANTS: Record<MixWeight, Readonly<{ en: string; sku: string }>> = {
  5: {
    en: '5 Pound Bag',
    sku: '05',
  },
  25: {
    en: '25 Pound Case',
    sku: '25',
  },
};
export const PARTS_LIMIT = 9;

interface Options {
  readonly name?: string;
  readonly quantity?: number;
  readonly weight?: MixWeight;
}

/** Enforce consistency with legacy sequence of selections
 *
 * PHP relies entirely on the order supplied by the client, which has historically been derived by
 * a `for...in` loop over an object keyed by the product keys. This has resulted in a numerical
 * sequence that does not care which order products were added. It should be noted that the legacy
 * JS is using string numbers for the keys during assignment, but JS runtime appears to evaluate
 * them as numbers.
 *
 * For example, if the key `3050` is added after `3000` and `3100`, it will still be the middle
 * value during the `for...in` sequence. The `Number()` coercion is used to ensure that the sort
 * matches.
 *
 * This is critical to ensure pricing follows a consistent formula and applies rounding correction
 * to the same product at the very end.
 */
export function sortSelectionsByProductKey(selections: VolumetricSelection[]) {
  return sortBy(selections, (s) => Number(s.productKey));
}

export class MixCustomizer extends BaseCustomizer<VolumetricSelection> {
  static readonly maxCategoryDepth = MAX_CATEGORY_DEPTH;

  name?: string;

  weight: MixWeight;

  readonly type = 'Mix';

  constructor(selections?: MixCustomizer['selections'], options?: Options) {
    super(INGREDIENT_LIMITS, selections, options?.quantity);

    this.name = options?.name;
    this.weight = options?.weight ?? 5;
  }

  calculatePrice() {
    const selections = sortSelectionsByProductKey(this.selections.filter(isDefined));
    const factor = this.weight / selections.reduce((acc, s) => acc + s.parts * s.density, 0);
    let totalWeight = 0;

    const price = Money.sumBy(selections, (s) => {
      let weight = roundPrecision(s.parts * s.density * factor);
      if (s === selections[selections.length - 1]) {
        // from `customMix.php`: if we are on the last ingredient, force the weight in case of rounding errors
        weight = this.weight - totalWeight;
      }
      totalWeight += weight;
      return Money.multiply(s.volumePrice, weight);
    });

    return this.weight === 25 ? Money.multiply(price, 0.8) : price;
  }

  calculateTotal() {
    return Money.multiply(this.calculatePrice(), this.quantity);
  }

  get canAddToCart() {
    if (!super.canAddToCart) return false;
    return this.selections.every((s) => isDefined(s) && s.parts > 0 && s.parts <= 9);
  }

  encodeCustomizerSelections() {
    return super.encodeCustomizerSelections(true);
  }

  prefillSelections(options: VolumetricIngredient[], code?: string) {
    if (!code) return;
    const partsByProductKey = new Map<string, number>();
    decodeCustomizerSelections(code, true).forEach((segment) => {
      const [productKey, parts] = segment.split(':');
      partsByProductKey.set(productKey, Number(parts));
    });
    const ingredients = options.filter((o) => partsByProductKey.has(o.productKey));
    const selections: MixCustomizer['selections'] = [];
    partsByProductKey.forEach((parts, key) => {
      const ingredient = ingredients.find((o) => o.productKey === key);
      if (ingredient) selections.push(VolumetricIngredient.toSelection(ingredient, parts));
    });
    this.selections = selections;
  }

  setSelection(ingredient: VolumetricIngredient, parts: number) {
    const current = this.selections.findIndex((s) => s?.productKey === ingredient.productKey);
    if (current >= 0 && parts === 0) {
      if (this.selections.length > this.ingredientLimits.min) {
        this.selections.splice(current, 1);
      } else {
        this.selections[current] = undefined;
      }
    } else if (parts > 0) {
      const selection = VolumetricIngredient.toSelection(ingredient, parts);
      const currentOrFirstOpenSlot = current >= 0 ? current : this.selections.findIndex((s) => !s);
      if (currentOrFirstOpenSlot >= 0) {
        this.selections[currentOrFirstOpenSlot] = selection;
      } else if (this.selections.length < this.ingredientLimits.max) {
        this.selections.push(selection);
      }
    }
  }
}
