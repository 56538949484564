<script lang="ts">
import { defineComponent } from 'vue';

import { CmsRegisteredComponent } from '@/utils/cms';

const CategoryCard = defineComponent({
  name: 'CategoryCard',
  props: {
    callToActionText: { required: false, type: String },
    description: { required: false, type: String },
    imgSrc: { required: true, type: String },
    link: { required: true, type: String },
    title: { required: true, type: String },
  },
  setup() {
    return {};
  },
});

export const CategoryCardRegistration: CmsRegisteredComponent = {
  component: CategoryCard,
  name: 'CategoryCard',
  inputs: [
    {
      name: 'title',
      type: 'string',
    },
    {
      name: 'description',
      type: 'string',
    },
    {
      name: 'imgSrc',
      friendlyName: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
    },
    {
      name: 'link',
      type: 'string',
    },
    {
      name: 'callToActionText',
      type: 'string',
    },
  ],
};

export default CategoryCard;
</script>

<template>
  <div class="flex flex-col items-center">
    <a :href="link" :alt="title" class="no-underline hover:no-underline">
      <img :src="imgSrc" alt="Image of Category" class="w-full" loading="lazy" />
      <p class="mt-2 mb-2 text-base font-semibold text-center text-black sm:text-xl font-sofia-pro">
        {{ title }}
      </p>
    </a>
    <p v-if="description" class="mt-1 text-sm text-center sm:text-base font-proxima-nova">
      {{ description }}
    </p>
    <a
      v-if="callToActionText"
      :href="link"
      alt="Call to action link"
      class="grid items-center no-underline justify-items-center hover:no-underline"
    >
      <div class="cursor-pointer">
        <p class="mt-1 text-sm font-bold text-black sm:text-base font-proxima-nova">
          {{ callToActionText }}
        </p>
      </div>
    </a>
  </div>
</template>
