/* eslint-disable import/prefer-default-export */
export const ProductPathsById = `query ProductPathsById ($ids: [String!]!) {
  productProjectionSearch(filters: { model: { value: { path: "id", values: $ids } } }) {
    count,
    results {
      id
      key
      masterVariant {
        id
        attributesRaw(includeNames: ["primaryCategory", "urlName"]) {
          name
          value
        }
      }
      categories {
        id
        custom {
          customFieldsRaw(includeNames: ["urlName"]) {
            name
            value
          }
        }
        ancestors {
          id
          custom {
            customFieldsRaw(includeNames: ["urlName"]) {
              name
              value
            }
          }
        }
      }
    },
    total
  }
}`;
