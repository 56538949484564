<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { NotificationsByType, useNotifications } from '@/stores/notifications';

const { notificationsByType } = storeToRefs(useNotifications());

const messageQueue: (keyof NotificationsByType)[] = ['errors', 'warnings', 'notices'];
</script>

<template>
  <div class="container">
    <transition v-for="messageType in messageQueue" :key="messageType" name="fade">
      <div
        v-if="notificationsByType[messageType].length"
        class="alert flash"
        :data-test="`flash-message-${messageType}`"
        v-html="notificationsByType[messageType].map((m) => m.message).join('<br />')"
      />
    </transition>
  </div>
</template>
