<script setup lang="ts">
import { computed, ref } from 'vue';

import NavItem from '@/components/account/NavItem.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import Caption from '@/components/base/typography/Caption.vue';
import Header6 from '@/components/base/typography/Header6.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import { useReferralElement } from '@/composables/useReferralElement';
import { B2B_INCENTIVE, DTC_INCENTIVE } from '@/lib/referral';

interface Account {
  is_b2b?: boolean;
  is_bulk_upload_available?: boolean;
  is_reseller?: boolean;
}

interface ExtraLink {
  target: string;
  title: string;
  url: string;
}

const props = defineProps<{
  account?: Account;
  active: string;
  extraLinks: Array<ExtraLink>;
}>();

const referralEntrypoint = ref<HTMLElement>();

const pages = computed(() => [
  {
    href: '/account/communications',
    text: 'Email Communications',
    active: props.active === 'communications',
    dataTest: 'email-communications-nav-link',
  },
  {
    href: '/account/address-book',
    text: 'Address Book',
    active: props.active === 'address',
    dataTest: 'address-book-nav-link',
  },
  {
    href: '/account/wallet',
    text: 'Wallet',
    active: props.active === 'wallet',
    dataTest: 'wallet-nav-link',
  },
  {
    href: '/account/order-history',
    text: 'Order History',
    active: props.active === 'orders',
    dataTest: 'order-history-nav-link',
  },
  {
    href: '/account/easy-reorder',
    text: 'Easy Reorder',
    active: props.active === 'easyreorder',
    dataTest: 'easy-reorder-nav-link',
  },
  {
    href: '/account/favorites',
    text: 'Favorites',
    active: props.active === 'favorites',
    dataTest: 'favorites-nav-link',
  },
  {
    href: '/account/auto-delivery',
    text: 'Auto-Delivery',
    active: props.active === 'autodelivery',
    dataTest: 'auto-delivery-nav-link',
  },
]);

const { showReferralElement } = useReferralElement(referralEntrypoint, 'account_page');
</script>

<template>
  <div>
    <nav class="flex flex-col">
      <ul
        class="flex flex-col overflow-hidden border border-solid rounded border-pearl"
        data-test="account-nav"
      >
        <NavItem
          :active="active === 'account'"
          class="border-t-0"
          data-test="your-account-nav-link"
          href="/account"
          text="Your Account"
        />
        <NavItem
          v-if="account?.is_b2b"
          :active="active === 'business'"
          data-test="business-account-nav-link"
          href="/account/business"
          text="Business Account"
        />
        <NavItem
          v-for="{ active, href, text, dataTest } in pages"
          :active
          :dataTest
          :href
          :key="href"
          :text
        />
        <NavItem
          v-if="account?.is_reseller"
          :active="active === 'reseller'"
          data-test="reseller-certifications-nav-link"
          href="/account/reseller-certification"
          text="Reseller Certifications"
        />
        <NavItem
          v-if="account?.is_bulk_upload_available"
          :active="active === 'bulkorder'"
          data-test="order-upload-nav-link"
          href="/order-upload"
          text="Order Upload"
        />
      </ul>
      <RouteLink v-for="link in extraLinks" class="mt-2" :key="link.title" :to="link.url">
        {{ link.title }}
      </RouteLink>

      <div v-if="showReferralElement" class="mt-3" data-test="refer-and-earn-account-nav">
        <div
          v-once
          class="flex flex-col px-5 py-4 border border-solid rounded border-neutral-300 gap-y-2"
        >
          <div class="inline-flex items-center gap-x-2">
            <Header6 headerTag="p">Refer and Earn</Header6>
            <Caption class="font-semibold bg-nuts-amber-400 px-1.5 rounded">NEW</Caption>
          </div>
          <a class="inline-flex text-black-link" href="/refer" ref="referralEntrypoint">
            <SmallBodyText class="text-black">
              Like what you ordered? Refer us to your friend and get {{ DTC_INCENTIVE }} or refer to
              a business and get {{ B2B_INCENTIVE }}.
              <u class="font-semibold underline">Learn More</u>
            </SmallBodyText>
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.bg-old-lace {
  background-color: $old-lace;
}
.border-pearl {
  border-color: $quarter-pearl-lusta;
}
.text-black-link {
  @apply text-black;
}
</style>
