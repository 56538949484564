<script setup lang="ts"></script>

<template>
  <p class="font-semibold uppercase font-proxima-nova text-neutral-500 text-[8px] md:text-tiny">
    <slot />
  </p>
</template>

<style scoped>
p {
  @apply mb-0 leading-3;
}
</style>
