<script lang="ts">
import { onMounted, ref } from 'vue';

import { getRecommendations } from '@/api/customer';
import ProductRecommendations from '@/components/recommendations/ProductRecommendations.vue';
import { WebstoreRecommendation } from '@/lib/personalization/webstore';
import { BuilderComponent } from '@/utils/cms';

interface Props {
  analyticsList?: string;
  enableAddToCart?: boolean;
  limit?: number;
  link?: string;
  title?: string;
  trackingEventLocation?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const ReorderRecommendationsRegistration: BuilderComponent = {
  name: 'ReorderRecommendations',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Buy It Again',
    },
    {
      name: 'limit',
      friendlyName: 'Number of Product Recommendations',
      type: 'number',
      defaultValue: 20,
    },
    {
      name: 'link',
      friendlyName: 'View All link',
      type: 'string',
    },
    {
      name: 'enableAddToCart',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'analyticsList',
      friendlyName: 'Analytics: List Name',
      type: 'string',
      helperText: 'Helps filter clicks/impressions in reporting; e.g. "Easy Reorder"',
      advanced: true,
    },
    {
      name: 'trackingEventLocation',
      friendlyName: 'Analytics: Page Type',
      type: 'string',
      helperText: 'Helps filter clicks/impressions in reporting; e.g. "HP"',
      advanced: true,
    },
  ],
};
</script>

<script setup lang="ts">
const props = withDefaults(defineProps<Props>(), {
  analyticsList: '',
  enableAddToCart: true,
  limit: 20,
  title: 'Buy It Again',
  trackingEventLocation: '',
});

const reorderRecommendations = ref<WebstoreRecommendation[]>();

onMounted(async () => {
  reorderRecommendations.value = await getRecommendations<WebstoreRecommendation>(
    '/api/recommendations/recent-purchases',
    {
      limit: props.limit,
    },
  );
});
</script>

<template>
  <ProductRecommendations
    :analyticsList="analyticsList"
    :enableAddToCart="enableAddToCart"
    :link="link"
    :list="reorderRecommendations"
    spacingLevel="none"
    :title="title"
    :trackingEventLocation="trackingEventLocation"
  />
</template>
