import type { RouteRecordRaw } from 'vue-router';

import { PageTypeMetadata } from '@/router/routes';

interface ConditionalRouteConfig {
  meta: {
    flagCondition: string;
  };
}

export const routesLockedByFeatureFlag: (RouteRecordRaw &
  ConditionalRouteConfig &
  PageTypeMetadata)[] = [];

export default {};
