<script setup lang="ts">
import { computed, onMounted, onServerPrefetch } from 'vue';

import DyBanner from '@/components/dynamic-yield/DyBanner.vue';
import { useDyChooseResults } from '@/composables/dynamic-yield/experiences';
import { ContentChoice } from '@/lib/personalization/dynamicYield';
import { useCriticalData } from '@/stores/criticalData';
import { isDefined } from '@/utils/isDefined';

const props = defineProps<{
  containerId: string;
  /** @deprecated should be derived automatically; legacy value of `CMS` appears to be invalid */
  dyPageType?: string;
  prefetch?: 'true' | 'false';
  selector: string;
}>();

interface CriticalData {
  choicesByName?: Record<string, ContentChoice | undefined>;
}
const critical = useCriticalData<CriticalData>(props.selector ?? 'DyBanner');
const { choicesByName, loadExperiences } = useDyChooseResults<ContentChoice>(
  props.selector,
  undefined,
  {
    preloadedChoices: critical.data.choicesByName,
  },
);
const choice = computed(() => choicesByName[props.selector]);
const isValidVariation = computed(
  () =>
    isDefined(choice.value) &&
    isDefined(choice.value.variations[0]) &&
    Object.keys(choice.value.variations[0].payload.data).length > 0,
);

onMounted(async () => {
  delete critical.data.choicesByName;
  if (!isValidVariation.value) {
    await loadExperiences();
  }
});

onServerPrefetch(async () => {
  if (props.prefetch === 'true' && !isValidVariation.value) {
    await loadExperiences();
    critical.data.choicesByName = choicesByName;
  }
});
</script>

<template>
  <DyBanner :banner="choice" :containerId="containerId" />
</template>
