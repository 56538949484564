<script setup lang="ts">
import {
  breakpointsTailwind,
  useBreakpoints,
  useCurrentElement,
  useIntersectionObserver,
  watchDebounced,
} from '@vueuse/core';
import { onMounted, ref } from 'vue';

import CloseIcon from '@/components/base/assets/CloseIcon.vue';
import PopperWrapper from '@/components/base/layout/PopperWrapper.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import { useLocalStorage } from '@/composables/useLocalStorage';

const DELAY_TO_TAG_AS_SEEN_MS = 5000;

const props = defineProps<{ location: 'account' | 'checkout' }>();

const show = ref(false);

const isMobile = useBreakpoints(breakpointsTailwind).smaller('lg');
const target = useCurrentElement<HTMLElement>();

const tagAsSeen = () => {
  useLocalStorage(`default-address-popover-${props.location}`).set('seen', true);
};

const onClose = () => {
  tagAsSeen();
  show.value = false;
};

onMounted(() => {
  show.value = !useLocalStorage(`default-address-popover-${props.location}`).get<boolean>('seen');

  if (show.value) {
    const { stop } = useIntersectionObserver(
      target,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          watchDebounced(
            () => isIntersecting,
            (onScreen) => {
              if (onScreen) {
                tagAsSeen();
                stop();
              }
            },
            { debounce: DELAY_TO_TAG_AS_SEEN_MS, immediate: true },
          );
        }
      },
      { threshold: 0.5 },
    );
  }
});
</script>

<template>
  <PopperWrapper
    arrow
    :offsetDistance="props.location === 'account' ? (isMobile ? '10' : '0') : undefined"
    offsetSkid="24"
    placement="bottom-start"
    :openDelay="500"
    :show
    zIndex="5"
  >
    <template #content>
      <div class="p-3.5 max-w-xs w-full">
        <div class="flex items-center justify-between">
          <BaseBodyText class="font-semibold text-black">
            Want to set a default address?
          </BaseBodyText>
          <UnstyledButton aria-label="Close popup" class="flex text-neutral-500" @click="onClose">
            <CloseIcon :size="[16, 20]" />
          </UnstyledButton>
        </div>
        <SmallBodyText class="mt-2 text-neutral-600">
          <template v-if="props.location === 'account'">
            You can now set a default address in your Address Book.
          </template>
          <template v-else>
            Simply check the box and save the address.<br />
            You can also update your default address in
            <strong>Account > Address Book</strong>
          </template>
        </SmallBodyText>
      </div>
    </template>
    <slot :dismiss="onClose" />
  </PopperWrapper>
</template>
