<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { AlgoliaVariant } from '@/api/algolia';
import ProductCardWithOptions, {
  Layout,
} from '@/components/product-card/ProductCardWithOptions.vue';
import { ProductCard as ProductCardModel, ProductCardData } from '@/utils/productCard';

interface WebstoreTag {
  facetGroup: string;
  hidden: boolean;
  showOnSearchPage: boolean;
  tagName: string;
}
interface Props {
  departmentHierarchy?: string[];
  item: AlgoliaVariant;
  layout?: Layout;
  page: string;
  tags?: WebstoreTag[];
  trackingEventLocation: string;
}
const props = withDefaults(defineProps<Props>(), {
  layout: 'plp',
  departmentHierarchy: () => [],
  tags: () => [],
});

const route = useRoute();

const breadCrumb = computed(() =>
  props.departmentHierarchy.length > 0
    ? props.departmentHierarchy.join(' > ')
    : props.tags.map((t) => t.tagName).join(' > '),
);

const pageBreadCrumb = computed(() => [props.page, breadCrumb.value].filter((s) => !!s).join(': '));

const productCardData = computed<ProductCardData>(() => ProductCardModel.fromAlgolia(props.item));

const requestUrl = computed(() => route.fullPath);

const indexName = computed<string>(
  () =>
    (Array.isArray(route.query.sortBy) ? route.query.sortBy[0] : route.query.sortBy) ?? 'Products',
);
// eslint-disable-next-line no-underscore-dangle
const resultPosition = computed(() => props.item.__position);
</script>

<script lang="ts">
// eslint-disable-next-line import/first
import { BuilderComponent } from '@/utils/cms';

// eslint-disable-next-line import/prefer-default-export
export const ResultCardRegistration: BuilderComponent = {
  name: 'Result Card',
  inputs: [
    {
      name: 'item',
      type: 'object',
      helperText: 'Algolia search hit',
    },
    {
      name: 'page',
      type: 'string',
    },
    {
      name: 'trackingEventLocation',
      helperText: 'Page where the result card is displayed',
      type: 'string',
      advanced: true,
    },
  ],
};
</script>

<template>
  <ProductCardWithOptions
    :analyticsList="pageBreadCrumb"
    compareSkus
    impressionTrackable
    :indexName="indexName"
    :layout="layout"
    :position="resultPosition"
    :product="productCardData"
    :requestUrl="requestUrl"
    :trackingEventLocation="trackingEventLocation"
  />
</template>
