import dayjs from 'dayjs';

// Nominal type for a string that looks like "2021-04-01"
// https://www.typescriptlang.org/play#example/nominal-typing
export type DateString = string & { __brand: 'DateString' };

export function isDateString(str: string): str is DateString {
  return /^\d{4}-\d{2}-\d{2}$/.test(str);
}

export function DateString(date: Date | dayjs.Dayjs | string): DateString {
  const dateString = typeof date === 'string' ? date : dayjs(date).format('YYYY-MM-DD');
  if (isDateString(dateString)) {
    return dateString;
  }
  throw new Error(`Invalid date string: ${dateString}`);
}

export const addDays = (daysInNumber: number, dayZone: number, orderIn: string): dayjs.Dayjs => {
  let deliveryDate = dayjs().add(daysInNumber, 'day');
  if (dayZone === 1) {
    if (deliveryDate.format('d') === '0') {
      deliveryDate = dayjs(deliveryDate).add(2, 'day');
    } else if (deliveryDate.format('d') === '1') {
      deliveryDate = dayjs(deliveryDate).add(1, 'day');
    }
  } else if (dayZone === 2 && orderIn) {
    if (deliveryDate.format('d') === '0') {
      deliveryDate = dayjs(deliveryDate).add(1, 'day');
    }
  } else if (dayZone === 2 && !orderIn) {
    if (deliveryDate.format('d') === '0') {
      deliveryDate = dayjs(deliveryDate).add(1, 'day');
    } else if (deliveryDate.format('d') === '1') {
      deliveryDate = dayjs(deliveryDate).add(2, 'day');
    }
  }
  return deliveryDate;
};

export const secondsToHoursAndMinutes = (seconds: number, includeSeconds = false): string => {
  if (seconds <= 0) return '0 sec';

  const hrs = Math.floor(seconds / (60 * 60));
  const mins = Math.floor(seconds / 60) - hrs * 60;
  let timeString = '';
  if (hrs) {
    timeString += hrs;
    timeString += hrs > 1 ? ' hrs ' : ' hr ';
  }
  if (mins) {
    timeString += mins;
    timeString += mins > 1 ? ' mins' : ' min';
  }
  if (includeSeconds) {
    const secs = seconds - (hrs * 60 * 60 + mins * 60);
    timeString += secs > 1 ? ` ${secs} secs` : ` ${secs} sec`;
  }
  return timeString;
};

export const calculateDaysBetweenDates = (date1: DateString, date2: DateString) => {
  const d1 = dayjs(date1, 'YYYY-MM-DD');
  const d2 = dayjs(date2, 'YYYY-MM-DD');
  const timeDiff = Math.abs(d2.valueOf() - d1.valueOf());
  return Math.ceil(timeDiff / (1000 * 3600 * 24));
};

export const friendlyFormat = (date: string) => dayjs(date).format('dddd, MMM D');
