export const ProductImagesById = `query ProductImagesById ($ids: [String!]!) {
  productProjectionSearch(filters: { model: { value: { path: "id", values: $ids } } }) {
    count,
    results {
      id, masterVariant { images { label, url } }
    },
    total
  }
}`;

export default {};
