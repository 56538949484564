<script setup lang="ts">
import { from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { useIntersectionObserver } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { reportEngagement } from '@/api/dynamic-yield/engagement';
import AddToCartOrCustomize from '@/components/base/add-to-cart/AddToCartOrCustomize.vue';
import OrderCountBadge from '@/components/base/OrderCountBadge.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import TagBadge from '@/components/base/TagBadge.vue';
import Header6 from '@/components/base/typography/Header6.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import PriceStrikethrough from '@/components/product-card/PriceStrikethrough.vue';
import ProductImage from '@/components/product-card/ProductImage.vue';
import ProductName from '@/components/product-card/ProductName.vue';
import Ratings from '@/components/product-card/Ratings.vue';
import SizeInfoAndSelection from '@/components/product-card/SizeInfoAndSelection.vue';
import { useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';
import { BaseChoice, RecommendationsSlot } from '@/lib/personalization/dynamicYield';
import { useAutoDelivery } from '@/stores/autoDelivery';
import analytics, { getListMetadata, gtag, ListMetadata, setListMetadata } from '@/utils/analytics';
import { NutsAddToCartItem, sendProductAddedEvent } from '@/utils/analytics/productAddedEvent';
import {
  formatGTagViewItemListPayload,
  NutsViewItemListItem,
  pushProductListViewed,
} from '@/utils/analytics/productListViewedEvent';
import { sendProductClickedEvent } from '@/utils/analytics/rudderstack';
import { getCartId } from '@/utils/cart';
import { Money } from '@/utils/money';
import { ProductCardData } from '@/utils/productCard';

export type Layout = 'plp' | 'recommendations';

const props = withDefaults(
  defineProps<{
    analyticsList?: string;
    clickTrackable?: boolean;
    compareSkus?: boolean;
    decisionId?: BaseChoice['decisionId'];
    disabledAddToCartButton?: boolean;
    enableAddToCart?: boolean;
    impressionTrackable?: boolean;
    indexName?: string;
    layout?: Layout;
    position: number;
    product: ProductCardData;
    linkToPdp?: boolean;
    requestUrl?: string;
    showADMessage?: boolean;
    showVariants?: boolean;
    slotId?: RecommendationsSlot['slotId'];
    trackingEventLocation?: string;
    variants?: ProductCardData[];
  }>(),
  {
    enableAddToCart: true,
    clickTrackable: true,
    layout: 'recommendations',
    impressionTrackable: true,
    linkToPdp: true,
    showADMessage: true,
    trackingEventLocation: '',
  },
);

const emit = defineEmits(['added']);

const store = useStore();
const { addToCart, cart } = useCart(store);
const { autoDeliveryChannel } = storeToRefs(useAutoDelivery());

const existingListMetadata = ref<ListMetadata>();
const selectedSku = ref(props.product.sku);
const selectedItem = computed(
  () => props.variants?.find((variant) => variant.sku === selectedSku.value) ?? props.product,
);

const displayDiscount = computed<boolean>(() => {
  if (selectedItem.value.totalSavings) return true;
  if (!props.compareSkus || !selectedItem.value.displayDiscountPercent) return false;
  return !!(selectedItem.value.bulk || selectedItem.value.wholesale);
});

const comparisonPrice = computed(() => {
  if (selectedItem.value.totalSavings) {
    return selectedItem.value.totalSavings.comparisonPrice;
  }
  return selectedItem.value.displayComparisonPrice;
});

const discountDisplayValue = computed(() => {
  if (selectedItem.value.totalSavings?.discountDisplayValue) {
    return selectedItem.value.totalSavings.discountDisplayValue;
  }

  return selectedItem.value.displayDiscountPercent;
});

const discountType = computed(() =>
  selectedItem.value.totalSavings?.onSale ? 'product' : undefined,
);

const displayShortVariantName = computed(
  () =>
    (selectedItem.value.promoteShortVariantName && !selectedItem.value.requiresCustomization) ||
    !!(selectedItem.value.unitName && !selectedItem.value.onePoundBulk),
);

const displayStars = computed(() => typeof selectedItem.value.averageRating === 'number');

const showAutoDeliveryMessage = computed(
  () =>
    props.showADMessage &&
    !selectedItem.value.hidePrice &&
    selectedItem.value.autoDeliveryEligible &&
    !selectedItem.value.wholesale &&
    !selectedItem.value.totalSavings,
);

const sortedVariants = (variants: ProductCardData[]) => {
  const collator = new Intl.Collator('en', { numeric: true });
  return variants.sort((a, b) => collator.compare(a.unitName, b.unitName));
};

const sizesAvailable = computed(() => {
  if (props.variants && props.variants.length > 1) {
    return sortedVariants(props.variants).map((variant) => ({
      label: variant.unitName,
      value: variant.sku,
    }));
  }
  return [{ label: props.product.unitName, value: props.product.sku }];
});

const productCard = ref<HTMLElement>();

const microData = computed(() => ({
  id: `0${props.product.sku}`,
  position: props.position,
  name: props.product.name,
  price: props.product.price,
  list: props.analyticsList ?? props.trackingEventLocation,
}));

const itemListName = computed<string>(() => props.analyticsList || props.trackingEventLocation);
const nutsViewItemListItem = computed<NutsViewItemListItem>(() => ({
  cost:
    selectedItem.value.cost === undefined ? undefined : from(parseFloat(selectedItem.value.cost)),
  coupon: selectedItem.value.totalSavings?.description?.en,
  discount: selectedItem.value.totalSavings?.value || from(0),
  indexName: props.indexName,
  name: props.product.name,
  position: props.position,
  price: props.product.piecePrice,
  price_before_discount:
    selectedItem.value.totalSavings?.comparisonPrice ?? selectedItem.value.piecePrice,
  product_id: props.product.productKey,
  quantity: 1,
  reporting_category: props.product.reportingCategory,
  searchQueryID: selectedItem.value.searchQueryId,
  sku: selectedItem.value.sku,
  variant: selectedItem.value.unitName,
  weight: selectedItem.value.weight,
}));

const listMetadata = computed<ListMetadata>(() => ({
  ...existingListMetadata.value,
  list: itemListName.value,
  indexName: props.indexName,
  position: props.position,
  searchQueryID: props.product.searchQueryId,
}));

const reportClick = () => {
  if (!props.clickTrackable || !props.linkToPdp) return;
  if (props.decisionId && props.slotId) {
    reportEngagement(props.decisionId, props.slotId);
  }

  analytics.sendEvent('productClick', props.trackingEventLocation, {
    ecommerce: {
      click: {
        actionField: {
          list: microData.value.list,
        },
        products: [microData.value],
      },
    },
  });

  setListMetadata(listMetadata.value);

  const gtagItem = formatGTagViewItemListPayload(itemListName.value, nutsViewItemListItem.value);
  gtag('event', 'select_item', {
    items: [gtagItem],
  });

  sendProductClickedEvent(props.product, props.position);
};

const impressionLogged = ref(false);
const reportImpression = () => {
  if (!props.impressionTrackable || impressionLogged.value) return;
  impressionLogged.value = true;
  pushProductListViewed(itemListName.value, nutsViewItemListItem.value);
};

function buildProductAddedPayload(): NutsAddToCartItem {
  return {
    cart_id: getCartId(cart.value),
    cost: Money.fromString(selectedItem.value.cost),
    coupon: selectedItem.value.totalSavings?.description?.en,
    discount: selectedItem.value.totalSavings?.value ?? from(0),
    indexName: props.indexName,
    item_list_name: itemListName.value,
    marked_as_gift: false,
    name: selectedItem.value.name,
    position: props.position,
    price: from(selectedItem.value.price),
    price_before_discount: from(selectedItem.value.price),
    product_id: selectedItem.value.productKey,
    quantity: 1,
    reporting_category: selectedItem.value.reportingCategory,
    searchQueryID: selectedItem.value.searchQueryId,
    sku: selectedItem.value.sku,
    variant: selectedItem.value.unitName,
    weight: selectedItem.value.weight ? Number(selectedItem.value.weight) : 0,
  };
}

const handleAddToCart = useCallback(async () => {
  const payload = buildProductAddedPayload();
  sendProductAddedEvent(payload);

  await addToCart(
    {
      cart_sku: {
        list_metadata: JSON.stringify(listMetadata.value),
        quantity: 1,
        sku_external_id: selectedItem.value.sku,
      },
    },
    {
      postAddToCartCallback: () => null,
      skipCartAdded: false,
    },
  );

  emit('added');
});

useIntersectionObserver(productCard, ([{ isIntersecting }]) => {
  if (isIntersecting) reportImpression();
});

onMounted(() => {
  existingListMetadata.value = getListMetadata();
});

watch(
  () => props.product,
  () => {
    selectedSku.value = props.product.sku;
  },
);
</script>

<template>
  <div
    class="relative flex flex-col justify-between w-full overflow-hidden bg-white rounded-xl"
    :class="[
      layout === 'recommendations' ? 'recommendations-slide p-2 md:p-3' : 'plp-slide h-full',
    ]"
    itemprop="item"
    data-test="product-card-recommendation"
    itemscope
    itemtype="http://schema.org/Product"
    :data-index="position"
    :data-list="analyticsList"
    ref="productCard"
  >
    <meta itemprop="brand" content="Nuts.com" />
    <meta itemprop="productID" :content="product.productKey" />
    <meta itemprop="url" :content="`${requestUrl}#${position}`" />
    <slot name="top">
      <OrderCountBadge
        v-if="selectedItem.orderCount"
        :orderCount="selectedItem.orderCount"
        class="absolute top-2 right-2"
      />
      <TagBadge
        v-else
        :tags="selectedItem.totalSavings ? ['badge-onsale'] : selectedItem.keywords ?? []"
        class="absolute top-0 right-0"
      />
    </slot>
    <div :class="layout === 'recommendations' ? 'contents' : 'flex flex-col h-full'">
      <component
        :is="linkToPdp ? RouteLink : 'div'"
        :to="product.path"
        class="mb-2.5"
        @click="reportClick"
      >
        <ProductImage
          :imageUrl="product.imageUrl"
          :productName="product.name"
          :size="showVariants ? 'default' : 'large'"
        />
      </component>
      <component
        :is="linkToPdp ? RouteLink : 'div'"
        :to="product.path"
        class="flex-initial overflow-hidden no-underline hover:no-underline"
        :class="{ 'order-3': layout === 'plp' }"
        @click="reportClick"
      >
        <div>
          <ProductName
            :class="{ 'hover:underline': linkToPdp }"
            hasSetHeight
            :productName="product.name"
          />
          <span v-if="!selectedItem.inStock" class="sr-only">This product is sold out.</span>
          <meta itemprop="description" :content="product.shortDescription" />
          <slot name="subhead">
            <div v-if="displayStars">
              <Ratings
                :averageRating="product.averageRating"
                :totalRatings="product.totalReviews"
              />
              <div
                v-if="product.totalReviews && product.totalReviews > 0"
                itemprop="aggregateRating"
                itemscope
                itemtype="http://schema.org/AggregateRating"
              >
                <meta itemprop="ratingValue" :content="product.averageRating?.toString()" />
                <meta itemprop="bestRating" content="5" />
                <meta itemprop="reviewCount" :content="product.totalReviews?.toString()" />
              </div>
            </div>
          </slot>
        </div>
        <div>
          <template v-if="!selectedItem.hidePrice">
            <PriceStrikethrough
              class="mt-2"
              :comparisonPrice
              :discountDisplayValue
              :discountType
              :displayDiscount="!!displayDiscount"
              :onePoundBulk="showVariants ? undefined : selectedItem.onePoundBulk"
              :piecePrice="selectedItem.piecePrice"
              :piecePriceRange="selectedItem.piecePriceRange"
            />
            <SmallBodyText v-if="selectedItem.totalSavings?.description" class="mt-2 text-black">
              {{ selectedItem.totalSavings.description.en }}
            </SmallBodyText>
          </template>
          <Header6 v-else class="mt-2">Build your own</Header6>
        </div>
      </component>
      <div v-if="layout === 'plp'" class="order-4">
        <div
          v-if="showAutoDeliveryMessage"
          class="flex items-start mt-2"
          data-test="auto-delivery-callout"
        >
          <img
            src="@/assets/auto-delivery/arrows.svg"
            alt="auto delivery icon"
            class="mr-2 md:mt-0.5"
          />
          <SmallBodyText class="text-black">
            {{ `Save ${autoDeliveryChannel.discount}% with Auto-Delivery` }}
          </SmallBodyText>
        </div>
      </div>
      <div class="flex mb-2" :class="{ 'order-5 items-end': layout === 'plp' }">
        <SizeInfoAndSelection
          v-model:selectedSku="selectedSku"
          :displayShortVariantName="displayShortVariantName"
          :hasSiblings="selectedItem.hasSiblings"
          :requiresCustomization="selectedItem.requiresCustomization"
          :shortUnitName="selectedItem.unitName"
          :showVariants="!!showVariants"
          :sizesAvailable="sizesAvailable"
        />
      </div>
      <slot name="button">
        <div
          v-if="enableAddToCart"
          :class="{ 'pt-2 mt-auto': layout === 'recommendations' }"
          data-test="add-to-cart-or-customize"
        >
          <AddToCartOrCustomize
            :class="{ 'order-2 mb-2': layout === 'plp' }"
            :disabled="disabledAddToCartButton"
            fullWidth
            :isLoading="handleAddToCart.isPending"
            :outOfStock="!selectedItem.inStock"
            :path="selectedItem.path"
            :requiresCustomization="selectedItem.requiresCustomization"
            size="small"
            @add-to-cart="handleAddToCart.execute()"
            @customization-started="emit('added')"
          >
            <template #add-to-cart-text>
              <slot name="add-to-cart-text" />
            </template>
          </AddToCartOrCustomize>
        </div>
      </slot>
    </div>
    <meta itemprop="sku" :content="`0${selectedItem.sku}`" />
    <meta itemprop="mpn" :content="`0${selectedItem.sku}`" />
    <div v-once class="acadia-product-card" />
  </div>
</template>

<style lang="scss" scoped>
.recommendations-slide {
  scroll-snap-align: start;
  min-width: 160px;
  max-width: 208px;
  @include respond-min($screen-md-min) {
    min-width: 173px;
  }
  @include respond-min($screen-lg-min) {
    min-width: 198px;
  }
  border: 1px solid $mercury-approx;
}
.plp-slide {
  scroll-snap-align: start;
  min-width: 150px;
  @include respond-min($screen-md-min) {
    min-width: 183px;
  }
  @include respond-min($screen-lg-min) {
    min-width: 227px;
  }
}
</style>
