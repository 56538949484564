<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    direction: string;
    disabled?: boolean;
    showOnDisabled?: boolean;
  }>(),
  {
    disabled: false,
    showOnDisabled: false,
  },
);

const emit = defineEmits<{
  (e: 'action', event: MouseEvent): void;
}>();

const clickHandler = (event: MouseEvent) => {
  emit('action', event);
};

const caretUrl = computed(() => {
  if (props.direction === 'left') {
    return nutshell['img/caret-left.svg'];
  }
  return nutshell['img/caret-right.svg'];
});
</script>

<template>
  <button
    aria-label="button"
    class="max-w-full max-h-full p-0 bg-white border border-solid rounded-full shadow-none cursor-pointer border-neutral-300 h-14 w-14"
    :class="{ hidden: disabled && !showOnDisabled, 'opacity-50': disabled && showOnDisabled }"
    @click="clickHandler"
    :disabled="disabled || false"
  >
    <img
      :alt="`arrow-${direction}`"
      aria-hidden="true"
      class="flex-shrink-0 object-contain h-4 m-auto"
      :src="caretUrl"
    />
  </button>
</template>
