<script setup lang="ts">
import { useVModel } from '@vueuse/core';

const emit = defineEmits<{ (e: 'update:modelValue', val: boolean): void }>();
const props = defineProps<{
  disabled?: boolean;
  id?: string;
  modelValue?: boolean;
}>();

const checked = useVModel(props, 'modelValue', emit);
</script>

<script lang="ts">
// eslint-disable-next-line import/first
import { BuilderComponent } from '@/utils/cms';

// eslint-disable-next-line import/prefer-default-export
export const CheckboxRegistration: BuilderComponent = {
  name: 'Checkbox',
  canHaveChildren: true,
  inputs: [
    {
      name: 'disabled',
      type: 'boolean',
      defaultValue: false,
    },
  ],
};
</script>

<template>
  <label
    class="flex items-center my-0 leading-none align-middle"
    data-test="checkbox-label"
    :class="{ 'cursor-pointer': !disabled }"
  >
    <input
      v-model="checked"
      class="inline-block w-4 h-4 my-0 align-middle base-checkbox accent-bg-nuts-lime-800"
      :class="{ 'mr-2': $slots.default, 'cursor-pointer': !disabled }"
      :disabled="disabled"
      :id="id ?? 'checkbox-input'"
      type="checkbox"
    />
    <slot />
  </label>
</template>

<style lang="scss" scoped>
.base-checkbox {
  accent-color: #4a8500;
  margin-left: 0px;
}
</style>
