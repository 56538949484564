<script setup lang="ts">
import escapeRegExp from 'lodash/escapeRegExp';
import { computed } from 'vue';

const props = defineProps<{
  entry: string;
  query: string;
}>();

const regex = computed(() => new RegExp(`(${escapeRegExp(props.query)})`, 'gi'));
const parts = computed(() => props.entry.split(regex.value).filter((part) => !!part));
</script>

<template>
  <span v-if="!query.trim()">{{ entry }}</span>
  <template v-else>
    <template v-for="part in parts" :key="part">
      <b v-if="regex.test(part)">{{ part }}</b>
      <template v-else>{{ part }}</template>
    </template>
  </template>
</template>
