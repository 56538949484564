import axios from 'axios';

import { NutsAddress } from '@/utils/address';

export interface KustomerCorporateInquiryFormat {
  customerName: string;
  companyName?: string;
  customerEmail: string;
  customerPhoneNumber: string;
  numberOfGifts: string;
  budget: string;
  deliveryDate: string;
  additionalDetails: string;
  customGreetingCard?: boolean;
  customSticker: boolean;
}

const kustomerInquiryHook =
  'https://api.kustomerapp.com/v1/hooks/form/5ca4e5dff3448c0015f36a9a/037fbdcf7a6dbcb1c5166f926d3920622301c26ffa154182a357a0b83cee4a74';

export async function kustomerCorporateInquiry(
  inquiryInfo: KustomerCorporateInquiryFormat,
): Promise<NutsAddress | null> {
  await axios.post(kustomerInquiryHook, inquiryInfo);

  return null;
}
