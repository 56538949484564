<script lang="ts">
import { computed, defineComponent } from 'vue';

import { CmsRegisteredComponent, TWColorsBackground, TWColorsText } from '@/utils/cms';

const BannerSection = defineComponent({
  name: 'BannerSection',
  props: {
    backgroundColor: { required: false, type: String, default: 'background (secondary)' },
    foregroundColor: { required: false, type: String, default: 'white' },
    headline: { required: true, type: String, default: 'Headline' },
    headlineColor: { required: true, type: String, default: 'black' },
    bgDesktopImg: { required: false, type: String },
    bgMobileImg: { required: false, type: String },
    link: { required: true, type: String, default: '/' },
    text: { required: true, type: String, default: 'text' },
    textColor: { required: true, type: String, default: 'black' },
  },
  setup(props) {
    return {
      color: computed(() => TWColorsText[props.textColor]),
      hColor: computed(() => TWColorsText[props.headlineColor]),
      bgColor: computed(() => TWColorsBackground[props.backgroundColor]),
      fgColor: computed(() => TWColorsBackground[props.foregroundColor]),
      desktopImage: computed(() => `url(${props.bgDesktopImg})`),
      mobileImage: computed(() => `url(${props.bgMobileImg})`),
    };
  },
});

export const BannerSectionRegistration: CmsRegisteredComponent = {
  component: BannerSection,
  name: 'Banner Section',
  inputs: [
    {
      name: 'backgroundColor',
      type: 'string',
      enum: Object.keys(TWColorsBackground),
      defaultValue: 'background (secondary)',
    },
    {
      name: 'foregroundColor',
      type: 'string',
      enum: Object.keys(TWColorsBackground),
      defaultValue: 'background (secondary)',
    },
    {
      name: 'headline',
      type: 'string',
      defaultValue: 'Headline',
    },
    {
      name: 'headlineColor',
      type: 'string',
      enum: Object.keys(TWColorsText),
      defaultValue: 'black',
    },
    {
      name: 'link',
      type: 'string',
      defaultValue: '/',
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png', 'jpg'],
    },
    {
      name: 'text',
      type: 'string',
      defaultValue: 'text',
    },
    {
      name: 'textColor',
      type: 'string',
      enum: Object.keys(TWColorsText),
      defaultValue: 'black',
    },
  ],
};

export default BannerSection;
</script>

<template>
  <RouterLink
    :to="link"
    class="flex w-full link-container pl-0 grow background bg-cover bg-no-repeat bg-center hover:no-underline color-inherit"
  >
    <div class="flex banner-container flex-col-reverse w-full lg:flex-row" :class="bgColor">
      <div
        class="flex banner-foreground w-full justify-start py-6 lg:justify-center items-center lg:items-start h-auto px-6 lg:px-12 box-border flex-col text-center lg:text-left lg:my-0"
        :class="fgColor"
      >
        <div class="banner-headline font-sofia-pro font-semibold mb-4 leading-8" :class="hColor">
          {{ headline }}
        </div>
        <div class="banner-caption font-semibold mb-6 text-base" :class="color">
          {{ text }}
        </div>
        <slot />
      </div>
      <div class="flex background-mobile lg:hidden" />
    </div>
  </RouterLink>
</template>

<style lang="scss" scoped>
.link-container {
  max-height: 508px;
  max-width: 1040px;
  min-height: 400px;
}
.banner-headline {
  font-size: 28px;
}
.banner-caption {
  line-height: 22px;
}
.background-mobile {
  background-image: v-bind(mobileImage);
  width: 100%;
  min-height: 216px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (min-width: 1024px) {
  .banner-foreground {
    width: 41%;
  }
  .background {
    background-image: v-bind(desktopImage);
  }
  .link-container {
    max-width: 1040px;
  }
  .banner-headline {
    font-size: 40px;
    line-height: 44px;
  }
  .banner-caption {
    font-size: 18px;
    line-height: 24px;
  }
}
</style>
