/* eslint-disable import/prefer-default-export */
import { MegaMenu } from '@/utils/navMenu';

export const staticMegaMenu: MegaMenu = {
  menuGroups: [
    {
      columns: [
        {
          linkGroups: [
            {
              group: {
                links: [
                  {
                    emphasized: true,
                    link: {
                      text: 'Best Sellers',
                      url: '/nuts-seeds/best-sellers/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      url: '/nuts-seeds/raw-nuts-seeds/',
                      text: 'Raw',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Organic',
                      url: '/nuts/organic/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Roasted',
                      url: '/nuts/roasted/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Salted',
                      url: '/nuts/roasted-salted/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: '50% Less Salt',
                      url: '/nuts-seeds/less-salt/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Unsalted',
                      url: '/nuts/roasted-unsalted/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'In Shell Nuts',
                      url: '/nuts/in-shell/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Mixed Nuts',
                      url: '/nuts/mixednuts/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Nuts',
                headerUrl: '/nuts/',
                links: [
                  {
                    link: {
                      text: 'Almonds',
                      url: '/nuts/almonds/',
                    },
                  },
                  {
                    link: {
                      text: 'Cashews',
                      url: '/nuts/cashews/',
                    },
                  },
                  {
                    link: {
                      text: 'Hazelnuts (Filberts)',
                      url: '/nuts/hazelnuts/',
                    },
                  },
                  {
                    link: {
                      text: 'Macadamia Nuts',
                      url: '/nuts/macadamianuts/',
                    },
                  },
                  {
                    link: {
                      text: 'Peanuts',
                      url: '/nuts/peanuts/',
                    },
                  },
                  {
                    link: {
                      text: 'Pecans',
                      url: '/nuts/pecans/',
                    },
                  },
                  {
                    link: {
                      text: 'Pistachios',
                      url: '/nuts/pistachios/',
                    },
                  },
                  {
                    link: {
                      text: 'Walnuts',
                      url: '/nuts/walnuts/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Seeds',
                headerUrl: '/cookingbaking/seeds/',
                links: [
                  {
                    link: {
                      text: 'Chia Seeds',
                      url: '/snacks/chiaseeds/',
                    },
                  },
                  {
                    link: {
                      text: 'Flax Seeds',
                      url: '/cookingbaking/seeds/flax/',
                    },
                  },
                  {
                    link: {
                      text: 'Hemp Seeds',
                      url: '/snacks/hemp-seeds/',
                    },
                  },
                  {
                    link: {
                      text: 'Poppy Seeds',
                      url: '/cookingbaking/seeds/poppy/',
                    },
                  },
                  {
                    link: {
                      text: 'Pumpkin (Pepitas)',
                      url: '/snacks/pumpkinseeds/',
                    },
                  },
                  {
                    link: {
                      text: 'Sesame Seeds',
                      url: '/cookingbaking/seeds/sesame/',
                    },
                  },
                  {
                    link: {
                      text: 'Squash Seeds',
                      url: '/nuts-seeds/squash/',
                    },
                  },
                  {
                    link: {
                      text: 'Sunflower Seeds',
                      url: '/nuts/sunflower-seeds/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Shop By Specialty',
                links: [
                  {
                    link: {
                      text: 'Cacao',
                      url: '/nuts/cacao/',
                    },
                  },
                  {
                    link: {
                      text: 'Candied & Flavored',
                      url: '/nuts-seeds/candied-flavored/',
                    },
                  },
                  {
                    link: {
                      text: 'Chocolate Covered',
                      url: '/nuts/chocolate-covered/',
                    },
                  },
                  {
                    link: {
                      text: 'Flour and Meal',
                      url: '/nuts-seeds/flour-meal/',
                    },
                  },
                  {
                    link: {
                      text: 'Grab & Go Snack Packs',
                      url: '/snacks/barspacks/',
                    },
                  },
                  {
                    link: {
                      text: 'Nut and Seed Butter',
                      url: '/cookingbaking/spreads/nut-butters/',
                    },
                  },
                  {
                    link: {
                      text: 'Nut & Plant Based Milks',
                      url: '/nuts/nut-milks/',
                    },
                  },
                  {
                    link: {
                      text: 'Sliced and Chopped Nuts',
                      url: '/chocolatessweets/toppings/nuts/chopped/',
                    },
                  },
                ],
              },
            },
          ],
        },
      ],
      header: {
        text: 'Nuts & Seeds',
        url: '/nuts/',
      },
      headerHtml: '<span style="white-space: nowrap;">Nuts &</span> Seeds',
      promoZone: {
        bottom: {
          cssClass: 'nuts-promo-bottom',
          image: 'https://nuts.com/images/dy/2023/Nuts_mega.d8747921.png',
          text: 'Shop Our Best Sellers',
          url: '/nuts/nuts-best-sellers/',
        },
        top: {
          cssClass: 'nuts-promo-top',
          image: 'https://nuts.com/images/dy/2023/FlavoredNuts_MegaMenu1.0980969c.png',
          text: 'Shop Our Flavored Nuts',
          url: '/nuts/flavored-nuts/',
        },
      },
    },
    {
      columns: [
        {
          linkGroups: [
            {
              group: {
                links: [
                  {
                    emphasized: true,
                    link: {
                      text: 'Best Sellers',
                      url: '/driedfruit/best-sellers/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Chocolate Covered',
                      url: '/driedfruit/chocolate-covered/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Organic',
                      url: '/driedfruit/organic/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'No Sugar Added',
                      url: '/driedfruit/no-sugar-added/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Natural Dried Fruit',
                      url: '/driedfruit/natural/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Mixed Fruit',
                      url: '/driedfruit/mixed-fruit/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Freeze Dried',
                      url: '/driedfruit/freeze-dried/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Fruit Powders',
                      url: '/cookingbaking/powders/fruit-powders/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Tropical',
                      url: '/driedfruit/tropical/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Dried Fruit',
                headerUrl: '/driedfruit/',
                links: [
                  {
                    link: {
                      text: 'Apricots',
                      url: '/driedfruit/apricots/',
                    },
                  },
                  {
                    link: {
                      text: 'Cherries',
                      url: '/driedfruit/cherries/',
                    },
                  },
                  {
                    link: {
                      text: 'Coconut',
                      url: '/driedfruit/coconut/',
                    },
                  },
                  {
                    link: {
                      text: 'Dates',
                      url: '/driedfruit/dates/',
                    },
                  },
                  {
                    link: {
                      text: 'Figs',
                      url: '/driedfruit/figs/',
                    },
                  },
                  {
                    link: {
                      text: 'Mango',
                      url: '/driedfruit/mango/',
                    },
                  },
                  {
                    link: {
                      text: 'Pineapple',
                      url: '/driedfruit/pineapple/',
                    },
                  },
                  {
                    link: {
                      text: 'Raisins',
                      url: '/driedfruit/raisins/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Berries',
                headerUrl: '/driedfruit/berries/',
                links: [
                  {
                    link: {
                      text: 'Blueberries',
                      url: '/driedfruit/blueberries/',
                    },
                  },
                  {
                    link: {
                      text: 'Cranberries',
                      url: '/driedfruit/cranberries/',
                    },
                  },
                  {
                    link: {
                      text: 'Goji Berries',
                      url: '/driedfruit/goji-berries/',
                    },
                  },
                  {
                    link: {
                      text: 'Mulberries',
                      url: '/driedfruit/mulberries/',
                    },
                  },
                  {
                    link: {
                      text: 'Raspberries',
                      url: '/driedfruit/red-raspberries/',
                    },
                  },
                  {
                    link: {
                      text: 'Strawberries',
                      url: '/driedfruit/strawberries/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Fruity Snacks',
                links: [
                  {
                    link: {
                      text: 'Flavored Dried Fruit',
                      url: '/driedfruit/flavored/',
                    },
                  },
                  {
                    link: {
                      text: 'Fruit Chips',
                      url: '/driedfruit/fruit-chips/',
                    },
                  },
                  {
                    link: {
                      text: 'Fruit Leather',
                      url: '/snacks/fruit-leather/premium/',
                    },
                  },
                  {
                    link: {
                      text: 'Fruit Snacks',
                      url: '/driedfruit/fruit-snacks/',
                    },
                  },
                ],
              },
            },
            {
              group: {
                headerText: 'Shop by Use',
                links: [
                  {
                    link: {
                      text: 'Dried Fruit for Baking',
                      url: '/driedfruit/baking-dried-fruit/',
                    },
                  },
                  {
                    link: {
                      text: 'Kid-friendly Favorites',
                      url: '/driedfruit/kid-friendly/',
                    },
                  },
                ],
              },
            },
          ],
        },
      ],
      header: {
        text: 'Dried Fruit',
        url: '/driedfruit/',
      },
      promoZone: {
        bottom: {
          cssClass: 'dried-promo-bottom',
          image: 'https://nuts.com/images/dy/2022/BYOB_MegaMenu.e1f38e78.png',
          text: 'Try Build Your Own Box',
          url: '/custom/box',
        },
        top: {
          cssClass: 'dried-promo-top',
          image: 'https://nuts.com/images/dy/2023/DriedFruit_Mega.3f2ac389.png',
          text: 'Shop Our Organic Best Sellers',
          url: '/driedfruit/organic/?refinementList%5BProduct.facets.value%5D%5B0%5D=organic&refinementList%5BProduct.searchableTags%5D%5B0%5D=dried%20fruit',
        },
      },
    },
    {
      columns: [
        {
          linkGroups: [
            {
              group: {
                links: [
                  {
                    emphasized: true,
                    link: {
                      text: 'Best Sellers',
                      url: '/powders/best-sellers/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Beet Powder',
                      url: '/cookingbaking/powders/beet-powder/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Chlorella',
                      url: '/cookingbaking/powders/chlorella/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Maca',
                      url: '/cookingbaking/powders/organic-maca-powder/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Spirulina',
                      url: '/cookingbaking/powders/spirulina/',
                    },
                  },
                ],
              },
            },
            {
              group: {
                headerText: 'New Products',
                headerUrl: '',
                links: [
                  {
                    link: {
                      text: 'Activated Charcoal',
                      url: '/cookingbaking/powders/coconut-powder/charcoal.html',
                    },
                  },
                  {
                    link: {
                      text: 'Vitamins',
                      url: '/cookingbaking/powders/vitamins/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Shop by Type',
                links: [
                  {
                    link: {
                      text: 'Adaptogenic Powders',
                      url: '/cookingbaking/powders/adaptogenic-powders/',
                    },
                  },
                  {
                    link: {
                      text: 'Bubble Tea Powders',
                      url: '/cookingbaking/powders/bubble-tea/',
                    },
                  },
                  {
                    link: {
                      text: 'Fruit Powders',
                      url: '/cookingbaking/powders/fruit-powders/',
                    },
                  },
                  {
                    link: {
                      text: 'Herb & Root Powders',
                      url: '/cookingbaking/powders/herb-root-powders/',
                    },
                  },
                  {
                    link: {
                      text: 'Milk & Cheese Powders',
                      url: '/powders/milk-cheese-powders/',
                    },
                  },
                  {
                    link: {
                      text: 'Natural Sweeteners',
                      url: '/cookingbaking/powders/natural-sweeteners/',
                    },
                  },
                  {
                    link: {
                      text: 'Plant Powders',
                      url: '/cookingbaking/powders/plant-powders/',
                    },
                  },
                  {
                    link: {
                      text: 'Protein Powders',
                      url: '/cookingbaking/powders/protein-powders/',
                    },
                  },
                  {
                    link: {
                      text: 'Vegetable Powders',
                      url: '/cookingbaking/powders/vegetable-powders/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Organic Powders',
                headerUrl: '/cookingbaking/organic-powders/',
                links: [
                  {
                    link: {
                      text: 'Adaptogenic Powders',
                      url: '/cookingbaking/organic-powders/adaptogenic-powders/',
                    },
                  },
                  {
                    link: {
                      text: 'Fruit Powders',
                      url: '/cookingbaking/organic-powders/fruit-powders/',
                    },
                  },
                  {
                    link: {
                      text: 'Herb & Root Powders',
                      url: '/cookingbaking/organic-powders/herb-root-powders/',
                    },
                  },
                  {
                    link: {
                      text: 'Plant Powders',
                      url: '/cookingbaking/organic-powders/plant-powders/',
                    },
                  },
                  {
                    link: {
                      text: 'Protein Powders',
                      url: '/cookingbaking/organic-powders/protein-powders/',
                    },
                  },
                  {
                    link: {
                      text: 'Vegetable Powders',
                      url: '/cookingbaking/organic-powders/vegetable-powders/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Shop by Use',
                links: [
                  {
                    link: {
                      text: 'Beauty',
                      url: '/powders/beauty/',
                    },
                  },
                  {
                    link: {
                      text: 'Immunity Boosting',
                      url: '/powders/immunity-boosting/',
                    },
                  },
                  {
                    link: {
                      text: 'Mood Boosting',
                      url: '/powders/mood-boosting/',
                    },
                  },
                  {
                    link: {
                      text: 'Pantry Essentials',
                      url: '/powders/pantry-essentials/',
                    },
                  },
                  {
                    link: {
                      text: 'Performance Boosting',
                      url: '/powders/performance-boosting/',
                    },
                  },
                  {
                    link: {
                      text: 'Smoothie Supplies',
                      url: '/cookingbaking/powders/smoothies/',
                    },
                  },
                  {
                    link: {
                      text: 'Stress Reducing',
                      url: '/powders/stress-reducing/',
                    },
                  },
                ],
              },
            },
          ],
        },
      ],
      header: {
        text: 'Superfood Powders',
        url: '/cookingbaking/powders/',
      },
      promoZone: {
        bottom: {
          cssClass: 'powders-promo-bottom',
          image: 'https://nuts.com/images/dy/2023/Superfood_imm.65a710bc.png',
          text: 'Shop Our Immunity Boosting powders',
          url: '/powders/immunity-boosting/?refinementList%5BProduct.facets.use%20case%5D%5B0%5D=immunity%20boosting&refinementList%5BProduct.searchableTags%5D%5B0%5D=powders',
        },
        top: {
          cssClass: 'powders-promo-top',
          image: 'https://nuts.com/images/dy/2022/Frame_719.452862f1.png',
          text: 'Shop Best Selling Powders',
          url: '/powders/best-sellers',
        },
      },
    },
    {
      columns: [
        {
          linkGroups: [
            {
              group: {
                links: [
                  {
                    emphasized: true,
                    link: {
                      text: 'Best Sellers',
                      url: '/chocolatessweets/best-sellers/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Organic',
                      url: '/chocolatessweets/organic/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Sugar Free',
                      url: '/chocolatessweets/sugar-free-candy/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Vegan',
                      url: '/chocolatessweets/vegan-chocolates-and-sweets/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Candy by Color',
                      url: '/colors/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Candy by Flavor',
                      url: '/chocolatessweets/by-flavor/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Wrapped Candy',
                      url: '/chocolatessweets/wrapped/',
                    },
                  },
                  {
                    emphasized: true,
                    link: {
                      text: 'Premium Chocolates',
                      url: '/chocolatessweets/premium-chocolates/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Chocolate Covered',
                headerUrl: '/search/instant?query=chocolate+covered',
                links: [
                  {
                    link: {
                      text: 'Dried Fruit',
                      url: '/chocolatessweets/dried-fruit/',
                    },
                  },
                  {
                    link: {
                      text: 'Espresso Beans',
                      url: '/chocolatessweets/premium-chocolates/espresso-beans/',
                    },
                  },
                  {
                    link: {
                      text: 'Malted Milk Balls',
                      url: '/chocolatessweets/malted-milk-balls/',
                    },
                  },
                  {
                    link: {
                      text: 'Nuts',
                      url: '/nuts/chocolatessweets/',
                    },
                  },
                  {
                    link: {
                      text: 'Pretzels',
                      url: '/chocolatessweets/pretzels/',
                    },
                  },
                  {
                    link: {
                      text: 'Seeds',
                      url: '/chocolatessweets/color-chocolates/sunflower-seeds/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Classic Candies',
                headerUrl: '/chocolatessweets/classic-candies/',
                links: [
                  {
                    link: {
                      text: 'Fruit Slices',
                      url: '/chocolatessweets/jelly-candy/gourmet-fruit-slices/',
                    },
                  },
                  {
                    link: {
                      text: 'Gummies',
                      url: '/chocolatessweets/gummies/',
                    },
                  },
                  {
                    link: {
                      text: 'Hard Candy',
                      url: '/chocolatessweets/hard-candy/',
                    },
                  },
                  {
                    link: {
                      text: 'Jelly Beans',
                      url: '/chocolatessweets/jelly-beans/',
                    },
                  },
                  {
                    link: {
                      text: 'Jordan Almonds',
                      url: '/jordan-almonds/',
                    },
                  },
                  {
                    link: {
                      text: 'Licorice',
                      url: '/chocolatessweets/licorice/',
                    },
                  },
                  {
                    link: {
                      text: 'M&Ms',
                      url: '/chocolatessweets/old-time-candy/m-m/',
                    },
                  },
                  {
                    link: {
                      text: 'Nonpareils',
                      url: '/chocolatessweets/nonpareils/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Shop by Specialty',
                links: [
                  {
                    link: {
                      text: 'Candy Sticks',
                      url: '/chocolatessweets/old-time-candy/candy-sticks/',
                    },
                  },
                  {
                    link: {
                      text: 'Chocolate Bark',
                      url: '/chocolatessweets/chocolate-bark/',
                    },
                  },
                  {
                    link: {
                      text: 'Chocolate Rocks',
                      url: '/chocolatessweets/toppings/candy-chocolates/chocolate-rocks/',
                    },
                  },
                  {
                    link: {
                      text: 'Fudge',
                      url: '/nuts/chocolatessweets/fudge/',
                    },
                  },
                  {
                    link: {
                      text: 'Ice Cream Toppings',
                      url: '/chocolatessweets/toppings/',
                    },
                  },
                  {
                    link: {
                      text: 'Novelty Candy',
                      url: '/chocolatessweets/novelty/',
                    },
                  },
                  {
                    link: {
                      text: 'Old Time Candy',
                      url: '/chocolatessweets/old-time-candy/',
                    },
                  },
                  {
                    link: {
                      text: 'Rock Candy',
                      url: '/chocolatessweets/old-time-candy/rock-candy/',
                    },
                  },
                  {
                    link: {
                      text: 'Salt Water Taffy',
                      url: '/chocolatessweets/old-time-candy/salt-water-taffy/',
                    },
                  },
                ],
              },
            },
          ],
        },
      ],
      header: {
        text: 'Chocolates & Sweets',
        url: '/chocolatessweets/',
      },
      promoZone: {
        bottom: {
          cssClass: 'chocolate-promo-bottom',
          image: 'https://nuts.com/images/dy/2022/ChocolatesSweets_Megamenu1.81a479aa.png',
          text: 'Shop Our Chocolate Gifts',
          url: '/chocolate-gifts/?refinementList%5BProduct.searchableTags%5D%5B0%5D=chocolate%20gifts',
        },
        top: {
          cssClass: 'chocolate-promo-top',
          image: 'https://nuts.com/images/dy/2022/ChocolatesSweets_Megamenu.e17075ef.png',
          text: 'Shop Our Chocolate & Sweet Favorites',
          url: '/chocolatessweets/best-sellers/',
        },
      },
    },
    {
      columns: [
        {
          linkGroups: [
            {
              group: {
                headerText: 'Snacks by Value',
                links: [
                  {
                    link: {
                      text: 'Organic',
                      url: '/snacks/organic-snacks/',
                    },
                  },
                  {
                    link: {
                      text: 'Gluten-Free',
                      url: '/snacks/gluten-free/',
                    },
                  },
                  {
                    link: {
                      text: 'Vegan',
                      url: '/snacks/vegan/',
                    },
                  },
                  {
                    link: {
                      text: 'Sprouted',
                      url: '/cookingbaking/sprouted/snacks/',
                    },
                  },
                  {
                    link: {
                      text: 'Raw',
                      url: '/snacks/raw/',
                    },
                  },
                ],
              },
            },
            {
              group: {
                headerText: 'Snacks by Region',
                links: [
                  {
                    link: {
                      text: 'Asian Snacks',
                      url: '/snacks/asian/',
                    },
                  },
                  {
                    link: {
                      text: 'Indian Snacks',
                      url: '/snacks/indian/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Feel Good Snacks',
                links: [
                  {
                    link: {
                      text: 'Custom Trail Mix',
                      url: '/custom/mixes/',
                    },
                  },
                  {
                    link: {
                      text: 'Trail Mix',
                      url: '/snacks/trail-mix/',
                    },
                  },
                  {
                    link: {
                      text: 'Dried Fruit',
                      url: '/snacks/dried-fruit/',
                    },
                  },
                  {
                    link: {
                      text: 'Energy Squares',
                      url: '/snacks/squares-energy/',
                    },
                  },
                  {
                    link: {
                      text: 'Fruit & Veggie Chips',
                      url: '/snacks/fruit-veggie-chips/',
                    },
                  },
                  {
                    link: {
                      text: 'Granola & Muesli',
                      url: '/snacks/granola/',
                    },
                  },
                  {
                    link: {
                      text: 'Nut Bars',
                      url: '/snacks/nut-bars/',
                    },
                  },
                  {
                    link: {
                      text: 'Nuts & Seeds',
                      url: '/snacks/nuts-seeds/',
                    },
                  },
                  {
                    link: {
                      text: 'Whole Wheat Bars',
                      url: '/snacks/whole-wheat-bars/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Salty Snacks',
                headerUrl: '/snacks/by-flavor/salty/',
                links: [
                  {
                    link: {
                      text: 'Corn Nuts',
                      url: '/snacks/corn-nuts/',
                    },
                  },
                  {
                    link: {
                      text: 'Crackers',
                      url: '/snacks/crackers/',
                    },
                  },
                  {
                    link: {
                      text: 'Half Popped Popcorn',
                      url: '/snacks/half-popped/',
                    },
                  },
                  {
                    link: {
                      text: 'Olives & Cured Meat',
                      url: '/snacks/olives-cured-meat/',
                    },
                  },
                  {
                    link: {
                      text: 'Popcorn',
                      url: '/chocolatessweets/snacks/popcorn/',
                    },
                  },
                  {
                    link: {
                      text: 'Pretzels',
                      url: '/snacks/pretzels/',
                    },
                  },
                  {
                    link: {
                      text: 'Sesame Sticks',
                      url: '/snacks/sesamesticks/',
                    },
                  },
                  {
                    link: {
                      text: 'Soy Beans & Edamame',
                      url: '/snacks/soy-beans-edamame/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Savory Snacks',
                links: [
                  {
                    link: {
                      text: 'Cheesy Snacks',
                      url: '/snacks/cheese/',
                    },
                  },
                  {
                    link: {
                      text: 'Jerky',
                      url: '/snacks/jerky/',
                    },
                  },
                  {
                    link: {
                      text: 'Spicy Snacks',
                      url: '/snacks/hot-spicy/',
                    },
                  },
                ],
              },
            },
            {
              group: {
                headerText: 'Snacks by Activity',
                headerUrl: '',
                links: [
                  {
                    link: {
                      text: 'Lunchbox',
                      url: '/snacks/lunchbox/',
                    },
                  },
                ],
              },
            },
          ],
        },
      ],
      header: {
        text: 'Snacks',
        url: '/snacks/',
      },
      promoZone: {
        bottom: {
          cssClass: 'snacks-promo-bottom',
          image: 'https://nuts.com/images/dy/2023/TMOTM_MegaMenu.d1487115.png',
          text: 'Shop Our Trail Mixes',
          url: '/snacks/trail-mix/',
        },
        top: {
          cssClass: 'snacks-promo-top',
          image: 'https://nuts.com/images/dy/2022/Snacks1_Megamenu.d65b863e.png',
          text: 'Shop Our Snacks Best Sellers',
          url: '/snacks/best-sellers/',
        },
      },
    },
    {
      columns: [
        {
          linkGroups: [
            {
              group: {
                headerText: 'Coffee by Roast',
                links: [
                  {
                    link: {
                      text: 'Light Roast',
                      url: '/coffeesteas/coffees/light-roast/',
                    },
                  },
                  {
                    link: {
                      text: 'Medium Roast',
                      url: '/coffeesteas/medium-roast/',
                    },
                  },
                  {
                    link: {
                      text: 'Dark Roast',
                      url: '/coffeesteas/coffees/dark-roast/',
                    },
                  },
                ],
              },
            },
            {
              group: {
                headerText: 'Shop by Type',
                links: [
                  {
                    link: {
                      text: 'Decaf',
                      url: '/coffeesteas/decaf-coffees/',
                    },
                  },
                  {
                    link: {
                      text: 'Flavored Coffee',
                      url: '/coffeesteas/flavoredcoffees/',
                    },
                  },
                  {
                    link: {
                      text: 'House Blend',
                      url: '/coffeesteas/house-blend/',
                    },
                  },
                  {
                    link: {
                      text: 'Turkish Coffee',
                      url: '/coffeesteas/coffees/light-roast/deluxe-house.html',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Coffees',
                headerUrl: '/coffeesteas/coffees/',
                links: [
                  {
                    link: {
                      text: 'Brazil',
                      url: '/coffeesteas/brazil/',
                    },
                  },
                  {
                    link: {
                      text: 'Colombia',
                      url: '/coffeesteas/colombia/',
                    },
                  },
                  {
                    link: {
                      text: 'Costa Rica',
                      url: '/coffeesteas/costa-rica/',
                    },
                  },
                  {
                    link: {
                      text: 'Ethiopia',
                      url: '/coffeesteas/ethiopia/',
                    },
                  },
                  {
                    link: {
                      text: 'Guatemala',
                      url: '/coffeesteas/guatemala/',
                    },
                  },
                  {
                    link: {
                      text: 'Kenya',
                      url: '/coffeesteas/kenya/',
                    },
                  },
                  {
                    link: {
                      text: 'Panama',
                      url: '/coffeesteas/panama/',
                    },
                  },
                  {
                    link: {
                      text: 'Sumatra',
                      url: '/coffeesteas/sumatra/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Tea',
                links: [
                  {
                    link: {
                      text: 'Black & Chai',
                      url: '/coffeesteas/black-chai/',
                    },
                  },
                  {
                    link: {
                      text: 'Bubble Tea',
                      url: '/coffeesteas/bubble-tea/',
                    },
                  },
                  {
                    link: {
                      text: 'Decaf',
                      url: '/coffeesteas/decaf-tea/',
                    },
                  },
                  {
                    link: {
                      text: 'Green & Matcha',
                      url: '/coffeesteas/greenteas/',
                    },
                  },
                  {
                    link: {
                      text: 'Herbal & Flavored',
                      url: '/coffeesteas/herbal-flavored/',
                    },
                  },
                  {
                    link: {
                      text: 'Iced Tea',
                      url: '/coffeesteas/iced-teas/',
                    },
                  },
                  {
                    link: {
                      text: 'Oolong',
                      url: '/coffeesteas/oolong-tea/',
                    },
                  },
                  {
                    link: {
                      text: 'Rooibos',
                      url: '/coffeesteas/rooibos/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Shop by Specialty',
                headerUrl: '',
                links: [
                  {
                    link: {
                      text: 'Chicory',
                      url: '/coffeesteas/chicory/',
                    },
                  },
                  {
                    link: {
                      text: 'Coffee Pods',
                      url: '/coffeesteas/coffee-pods/',
                    },
                  },
                  {
                    link: {
                      text: 'Hot Chocolate',
                      url: '/coffeesteas/hot-chocolate/',
                    },
                  },
                  {
                    link: {
                      text: 'Nut & Plant Based Milks',
                      url: '/nuts/nut-milks/',
                    },
                  },
                  {
                    link: {
                      text: 'Sweeteners',
                      url: '/coffeesteas/coffees-teas-beverages-sweeteners/',
                    },
                  },
                ],
              },
            },
          ],
        },
      ],
      header: {
        text: 'Coffee & Tea',
        url: '/coffeesteas/',
      },
      promoZone: {
        bottom: {
          cssClass: 'coffee-promo-bottom',
          image: 'https://nuts.com/images/dy/2022/Superfood_Megameu.65a710bc.png',
          text: 'Shop Our Herbal Teas',
          url: '/coffeesteas/herbal-flavored/',
        },
        top: {
          cssClass: 'coffee-promo-top',
          image: 'https://nuts.com/images/dy/2022/Frame_733.88388f45.png',
          text: 'Shop Best Selling Coffees',
          url: '/coffeesteas/coffees',
        },
      },
    },
    {
      columns: [
        {
          linkGroups: [
            {
              group: {
                headerText: 'Shop by Value',
                links: [
                  {
                    link: {
                      text: 'Organic',
                      url: '/cooking/organic/',
                    },
                  },
                  {
                    link: {
                      text: 'Gluten-Free',
                      url: '/cooking/gluten-free/',
                    },
                  },
                  {
                    link: {
                      text: 'Sprouted',
                      url: '/cookingbaking/sprouted/',
                    },
                  },
                ],
              },
            },
            {
              group: {
                headerText: 'Cooking Staples',
                links: [
                  {
                    link: {
                      text: 'Herbs, Spices & Salts',
                      url: '/cookingbaking/herbsspices/',
                    },
                  },
                  {
                    link: {
                      text: 'Leaveners & Thickeners',
                      url: '/cookingbaking/leavenerthickener/',
                    },
                  },
                  {
                    link: {
                      text: 'Oils & Vinegars',
                      url: '/cookingbaking/oil-and-vinegar/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Pantry Essentials',
                links: [
                  {
                    link: {
                      text: 'Beans, Peas & Lentils',
                      url: '/cookingbaking/beans/',
                    },
                  },
                  {
                    link: {
                      text: 'Freeze Dried Vegetables',
                      url: '/snacks/simply-veggies/',
                    },
                  },
                  {
                    link: {
                      text: 'Mushrooms',
                      url: '/cookingbaking/mushrooms/',
                    },
                  },
                  {
                    link: {
                      text: 'Flours',
                      url: '/cookingbaking/flours/',
                    },
                  },
                ],
              },
            },
            {
              group: {
                headerText: 'Grains',
                headerUrl: '/cookingbaking/grains/',
                links: [
                  {
                    link: {
                      text: 'Farro',
                      url: '/cookingbaking/grains/farro/',
                    },
                  },
                  {
                    link: {
                      text: 'Quinoa',
                      url: '/cookingbaking/grains/quinoa/',
                    },
                  },
                  {
                    link: {
                      text: 'Rice',
                      url: '/cookingbaking/rice/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Pantry Essentials',
                links: [
                  {
                    link: {
                      text: 'Artisanal Pastas',
                      url: '/cookingbaking/pasta/artisanal/',
                    },
                  },
                  {
                    link: {
                      text: 'Bread Crumbs & Stuffing',
                      url: '/cookingbaking/bread-crumbs-stuffing/',
                    },
                  },
                  {
                    link: {
                      text: 'Condiments',
                      url: '/cookingbaking/condiments/',
                    },
                  },
                  {
                    link: {
                      text: 'Jams & Jellies',
                      url: '/cookingbaking/spreads/',
                    },
                  },
                  {
                    link: {
                      text: 'Noodles',
                      url: '/cooking/noodles/',
                    },
                  },
                  {
                    link: {
                      text: 'Nut & Seed Butters',
                      url: '/nuts/nut-butters-oils/',
                    },
                  },
                  {
                    link: {
                      text: 'Nut & Plant Based Milks',
                      url: '/nuts/nut-milks/',
                    },
                  },
                  {
                    link: {
                      text: 'Sauces',
                      url: '/cooking/sauces/',
                    },
                  },
                  {
                    link: {
                      text: 'Soup Bases',
                      url: '/cooking/soup-base/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Shop by Lifestyle',
                links: [
                  {
                    link: {
                      text: 'Keto',
                      url: '/cooking/keto/',
                    },
                  },
                  {
                    link: {
                      text: 'Kid-friendly',
                      url: '/cooking/kid-friendly/',
                    },
                  },
                  {
                    link: {
                      text: 'Paleo',
                      url: '/cooking/paleo/',
                    },
                  },
                  {
                    link: {
                      text: 'Vegan',
                      url: '/cooking/vegan/',
                    },
                  },
                  {
                    link: {
                      text: 'Whole 30',
                      url: '/cooking/whole-thirty/',
                    },
                  },
                ],
              },
            },
          ],
        },
      ],
      header: {
        text: 'Cooking',
        url: '/cookingbaking/',
      },
      promoZone: {
        bottom: {
          cssClass: 'cooking-promo-bottom',
          image: 'https://nuts.com/images/dy/2022/Frame_727.d7be9d9f.png',
          text: 'Stock Your Pantry',
          url: '/cms/pantry-staples',
        },
        top: {
          cssClass: 'cooking-promo-top',
          image: 'https://nuts.com/images/dy/2022/Frame_722.6ccc9282.png',
          text: 'Shop Organic Herbs & Spices',
          url: '/cookingbaking/herbsspices/organicherbsspices/',
        },
      },
    },
    {
      columns: [
        {
          linkGroups: [
            {
              group: {
                headerText: 'Shop by Value',
                links: [
                  {
                    link: {
                      text: 'Organic',
                      url: '/baking/organic/',
                    },
                  },
                  {
                    link: {
                      text: 'Gluten-Free',
                      url: '/baking/gluten-free-baking/',
                    },
                  },
                  {
                    link: {
                      text: 'Vegan',
                      url: '/baking/vegan/',
                    },
                  },
                ],
              },
            },
            {
              group: {
                headerText: 'Flours',
                headerUrl: '/cookingbaking/flours/',
                links: [
                  {
                    link: {
                      text: 'Gluten-Free Flours',
                      url: '/cookingbaking/gluten-free-flour/',
                    },
                  },
                  {
                    link: {
                      text: 'Grain Flours',
                      url: '/baking/grain-flour/',
                    },
                  },
                  {
                    link: {
                      text: 'Nut Flours',
                      url: '/baking/nut-flour/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Nuts & Dried Fruits',
                links: [
                  {
                    link: {
                      text: 'Coconut',
                      url: '/baking/coconut/',
                    },
                  },
                  {
                    link: {
                      text: 'Dried Fruit for Baking',
                      url: '/baking/dried-fruit/',
                    },
                  },
                  {
                    link: {
                      text: 'Glazed Fruit',
                      url: '/driedfruit/glazedfruit/',
                    },
                  },
                  {
                    link: {
                      text: 'Nuts for Baking',
                      url: '/chocolatessweets/toppings/nuts/chopped/',
                    },
                  },
                ],
              },
            },
            {
              group: {
                headerText: 'Baking Chocolate',
                headerUrl: '/cookingbaking/cocoa-powders-baking-chocolate/',
                links: [
                  {
                    link: {
                      text: 'Chips & Wafers',
                      url: '/baking/chips-wafers/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Toppings',
                headerUrl: '/cookingbaking/decorations-toppings/',
                links: [
                  {
                    link: {
                      text: 'Candy by Color',
                      url: '/colors/',
                    },
                  },
                  {
                    link: {
                      text: 'Nonpareils',
                      url: '/chocolatessweets/nonpareils/',
                    },
                  },
                  {
                    link: {
                      text: 'Pearls',
                      url: '/cookingbaking/decorations-toppings/pearls/',
                    },
                  },
                  {
                    link: {
                      text: 'Sanding Sugar',
                      url: '/cookingbaking/decorations-toppings/sanding-sugar/',
                    },
                  },
                  {
                    link: {
                      text: 'Sprinkles',
                      url: '/cookingbaking/decorations-toppings/sprinkles/',
                    },
                  },
                  {
                    link: {
                      text: 'Cacao',
                      url: '/nuts/cacao/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Baking Essentials',
                links: [
                  {
                    link: {
                      text: 'Baking Mixes',
                      url: '/cookingbaking/mixes/',
                    },
                  },
                  {
                    link: {
                      text: 'Baking Spices',
                      url: '/baking/baking-spices/',
                    },
                  },
                  {
                    link: {
                      text: 'Cocoa Powder',
                      url: '/cookingbaking/cocoa-powders-baking-chocolate/',
                    },
                  },
                  {
                    link: {
                      text: 'Extracts & Flavorings',
                      url: '/cookingbaking/extracts-flavorings/',
                    },
                  },
                  {
                    link: {
                      text: 'Fillings & Pastes',
                      url: '/cookingbaking/fillings/',
                    },
                  },
                  {
                    link: {
                      text: 'Leaveners & Thickeners',
                      url: '/cookingbaking/leavenerthickener/',
                    },
                  },
                  {
                    link: {
                      text: 'Nut Butters',
                      url: '/cookingbaking/spreads/nut-butters/',
                    },
                  },
                  {
                    link: {
                      text: 'Nut & Plant Based Milks',
                      url: '/nuts/nut-milks/',
                    },
                  },
                  {
                    link: {
                      text: 'Sweeteners',
                      url: '/coffeesteas/coffees-teas-beverages-sweeteners/',
                    },
                  },
                ],
              },
            },
          ],
        },
      ],
      header: {
        text: 'Baking',
        url: '/baking/',
      },
      promoZone: {
        bottom: {
          image: 'https://nuts.com/images/dy/2022/Frame_725.397a80de.png',
          cssClass: 'baking-promo-bottom',
          text: 'Shop Cacao Baking Ingredients',
          url: '/baking/baking-cacao/',
        },
        top: {
          cssClass: 'baking-promo-top',
          image: 'https://nuts.com/images/dy/2022/Frame_728.c7a8a93d.png',
          text: 'Shop Baking Mixes',
          url: '/baking/baking-mixes/',
        },
      },
    },
    {
      columns: [
        {
          linkGroups: [
            {
              group: {
                headerText: 'Gifts',
                headerUrl: '/gifts/',
                links: [
                  {
                    link: {
                      text: 'Gift Guide',
                      url: '/gift-guide',
                    },
                  },
                  {
                    link: {
                      text: 'Anniversaries',
                      url: '/gifts/tag/anniversary/',
                    },
                  },
                  {
                    link: {
                      text: 'Birthdays',
                      url: '/gifts/birthday-gifts/?refinementList%5BProduct.searchableTags%5D%5B0%5D=gifts',
                    },
                  },
                  {
                    link: {
                      text: 'Congrats',
                      url: '/gifts/congratulations/',
                    },
                  },
                  {
                    link: {
                      text: 'Corporate Gifting',
                      url: '/corporate-gifts',
                    },
                  },
                  {
                    link: {
                      text: 'Get Well',
                      url: '/gifts/get-well/',
                    },
                  },
                  {
                    link: {
                      text: 'Sympathy',
                      url: '/gifts/sympathy/',
                    },
                  },
                  {
                    link: {
                      text: 'Thank You',
                      url: '/gifts/thank-you/',
                    },
                  },
                  {
                    link: {
                      text: 'All Gifts',
                      url: '/gifts/all/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'More Ways to Shop',
                links: [
                  {
                    link: {
                      text: 'Gifts under $40',
                      url: '/gifts/under-40/',
                    },
                  },
                  {
                    link: {
                      text: 'Gifts under $70',
                      url: '/gifts/30-50/',
                    },
                  },
                  {
                    link: {
                      text: 'Premium Gifts',
                      url: '/gifts/premium/',
                    },
                  },
                  {
                    link: {
                      text: 'Gifts that Give Back',
                      url: '/gifts/tag/charity/',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Gifts by Type',
                links: [
                  {
                    link: {
                      text: 'Best Sellers',
                      url: '/gifts/best-sellers/',
                    },
                  },
                  {
                    link: {
                      text: 'Baskets & Boxes',
                      url: '/gifts/baskets/',
                    },
                  },
                  {
                    link: {
                      text: 'Custom Mixes',
                      url: '/custom/mixes/',
                    },
                  },
                  {
                    link: {
                      text: 'Custom Trays',
                      url: '/custom/trays/',
                    },
                  },
                  {
                    link: {
                      text: 'Tins',
                      url: '/gifts/gifttins/',
                    },
                  },
                  {
                    link: {
                      text: 'Trays',
                      url: '/gifts/trays/',
                    },
                  },
                  {
                    link: {
                      text: 'Gift Certificates',
                      url: '/gifts/gift-certificate.html',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          linkGroups: [
            {
              group: {
                headerText: 'Gifts by Flavor',
                links: [
                  {
                    link: {
                      text: 'Chocolate',
                      url: '/gifts/chocolate-gifts/',
                    },
                  },
                  {
                    link: {
                      text: 'Fruity',
                      url: '/gifts/fruity-gifts/',
                    },
                  },
                  {
                    link: {
                      text: 'Savory',
                      url: '/gifts/savory-gifts/',
                    },
                  },
                  {
                    link: {
                      text: 'Salty',
                      url: '/gifts/salty-gifts/',
                    },
                  },
                ],
              },
            },
          ],
        },
      ],
      header: {
        text: 'Gifts',
        url: '/gifts/',
      },
      promoZone: {
        bottom: {
          cssClass: 'gifts-promo-bottom',
          image: 'https://nuts.com/images/dy/2022/BYOB_MegaMenu.e1f38e78.png',
          text: 'Try Build Your Own Box',
          url: '/custom/box',
        },
        top: {
          cssClass: 'gifts-promo-top',
          image: 'https://nuts.com/images/dy/2022/Frame_726.c9b98d12.png',
          text: 'Gifting Made Easy - Let Them Choose Their Favorites',
          url: '/gift-drop',
        },
      },
    },
  ],
};
