<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { ChooseParams } from '@/api/dynamic-yield/experiences';
import Skeleton from '@/components/base/Skeleton.vue';
import Header5 from '@/components/base/typography/Header5.vue';
import ProductRecommendations from '@/components/recommendations/ProductRecommendations.vue';
import VerticalProductRecommendations from '@/components/recommendations/VerticalProductRecommendations.vue';
import { useRecommendationsFromConfig } from '@/composables/dynamic-yield/recommendationsFromConfig';
import { useCallback } from '@/composables/useCallback';
import {
  decisionId,
  isExpandedRecommendationsReference,
  productSlotsFromExpandedReference,
  recommendations,
  title,
} from '@/utils/dynamic-yield/recommendationsRow';

const props = defineProps<{
  dyPageContext?: ChooseParams['pageContext'];
  dySelector: `[CONFIG] ${string}`;
}>();
defineEmits(['handle-close']);

const route = useRoute();
const { loadFromConfig, pageContext, recommendationRows } = useRecommendationsFromConfig(
  props.dySelector,
  route,
);
const dyRows = computed(() => recommendationRows.value.filter(isExpandedRecommendationsReference));

const loadRecommendationsRows = useCallback(async () => {
  if (props.dyPageContext) {
    pageContext.type = props.dyPageContext.type;
    pageContext.data = props.dyPageContext.data;
  }
  await loadFromConfig();
});

const addedSkus = ref(new Set<string>());

onMounted(() => {
  loadRecommendationsRows.execute();
});
</script>

<template>
  <div class="relative hidden px-4 py-4 mt-2 md:block md:visible" data-test="frequently-bought">
    <Header5>{{ title(dyRows[0]) || 'Frequently bought together' }}</Header5>
  </div>
  <div class="hidden pl-4 sm:p-0 md:block md:visible">
    <div v-if="loadRecommendationsRows.isPending">
      <div
        v-for="item in 12"
        :key="`recommendation-${item}`"
        class="z-10 px-4 py-2 border-b border-solid shrink-0 border-neutral-300"
      >
        <Skeleton class="h-24" />
      </div>
    </div>
    <div v-for="row in dyRows" :key="row.dySelector">
      <VerticalProductRecommendations
        v-model:addedSkus="addedSkus"
        :analyticsList="row.analyticsList"
        :decisionId="decisionId(row)"
        :enableAddToCart="row.enableAddToCart"
        :list="productSlotsFromExpandedReference(row)"
        :title="title(row)"
        trackingEventLocation="CART"
        @click="$emit('handle-close')"
      >
        <template v-if="$slots.button" #button="slotProps">
          <slot v-bind="slotProps" name="button" />
        </template>
      </VerticalProductRecommendations>
    </div>
  </div>
  <div class="px-4 overflow-auto md:hidden">
    <div v-if="loadRecommendationsRows.isPending">
      <div class="flex justify-between mt-2 shrink-0">
        <Skeleton v-for="item in 2" :key="`recommendation-${item}`" class="w-1/2 h-32 px-2 mx-1" />
      </div>
    </div>
    <div v-if="dyRows && dyRows.length">
      <div v-for="(row, i) in dyRows" :key="'dySelector' in row ? row.dySelector : i">
        <ProductRecommendations
          v-model:addedSkus="addedSkus"
          :analyticsList="row.analyticsList"
          :decisionId="decisionId(row)"
          :enableAddToCart="row.enableAddToCart"
          :link="row.link"
          :list="recommendations(row)"
          :spacingDirection="i === dyRows.length - 1 ? 'vertical' : 'top'"
          spacingLevel="xs"
          :title="title(row)"
          trackingEventLocation="CART"
        >
          <template v-if="$slots.button" #button="slotProps">
            <slot v-bind="slotProps" name="button" />
          </template>
        </ProductRecommendations>
      </div>
    </div>
  </div>
</template>
