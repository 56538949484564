<script setup lang="ts">
import { computed } from 'vue';

import Caption from '@/components/base/typography/Caption.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';

export type Size = 'xs' | 'sm';

const props = withDefaults(
  defineProps<{ discountDisplayValue?: string; discountType?: 'product'; size?: Size }>(),
  {
    size: 'xs',
  },
);

const componentType = computed(() => (props.size === 'sm' ? SmallBodyText : Caption));
</script>

<template>
  <component
    :is="componentType"
    class="font-bold px-0.5 py-0.25 text-black"
    :class="discountType === 'product' ? 'bg-nuts-red-200 text-nuts-red-800' : 'bg-nuts-amber-400'"
    data-test="discount-badge"
  >
    {{
      discountType === 'product' ? `${discountDisplayValue} off` : `SAVE ${discountDisplayValue}`
    }}
  </component>
</template>
