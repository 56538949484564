<script setup lang="ts">
import { computed } from 'vue';

import Accordion from '@/components/base/layout/Accordion.vue';
import Header5 from '@/components/base/typography/Header5.vue';

const props = defineProps<{ defaultExpanded?: boolean; title: string }>();

const id = computed(() => props.title.toLowerCase().replace(/\s/g, '-'));
</script>

<template>
  <Accordion
    :defaultExpanded="defaultExpanded"
    :id="`expandable-menu-${id}`"
    data-test="expandable-menu"
    triggerClasses="px-4 py-2 hover:bg-nuts-stone-100"
  >
    <template #title>
      <Header5>{{ title }}</Header5>
    </template>
    <template #content>
      <slot />
    </template>

    <template #icon="{ isExpanded }">
      <img
        v-show="!isExpanded"
        alt=""
        data-promo="1"
        data-promo-creative="Mobile Menu Nav Links"
        data-promo-name="Expand"
        src="@/assets/add-sm.svg"
        class="w-6 h-6"
      />
      <img
        v-show="isExpanded"
        alt=""
        data-promo="1"
        data-promo-creative="Mobile Menu Nav Links"
        data-promo-name="Collapse"
        data-test="collapsed-menu"
        src="@/assets/remove-sm.svg"
        class="w-6 h-6"
      />
    </template>
  </Accordion>
</template>
