/* eslint-disable camelcase */
import { LocalizedString, Money, ProductDiscountValue } from '@commercetools/platform-sdk';
import { cents, from } from '@nuts/auto-delivery-sdk/dist/utils/money';

import { AlgoliaVariant } from '@/api/algolia';
import money from '@/filters/money';
import { BYOB_PRODUCT_KEY } from '@/lib/customizers/boxes';
import { RecommendationsSlot } from '@/lib/personalization/dynamicYield';
import { WebstoreRecommendation } from '@/lib/personalization/webstore';
import { getProductDiscountDisplayValueFromPrice } from '@/lib/product/discounts/productDiscounts';
import { adaptLegacyBoolean } from '@/utils/cms';
import { computeSavings, LineSavingsSummary as SavingsSummary } from '@/utils/money';

interface BaseProductCardData {
  allVariantsOutOfStock: boolean;
  autoDeliveryEligible: boolean;
  averageRating?: number;
  bulk?: boolean;
  cost: string;
  displayComparisonPrice?: string;
  displayDiscountPercent?: string;
  displayPrice: string;
  hasSiblings?: boolean;
  imageUrl: string;
  inStock: boolean;
  keywords: string[];
  merchandisingCategory?: string;
  name: string;
  path: string;
  orderCount?: number;
  /** @deprecated prefer `piecePrice` */
  price: number;
  productKey: string;
  promoteShortVariantName?: boolean;
  reportingCategory?: string;
  requiresCustomization: boolean;
  sku: string;
  searchQueryId?: string;
  shortDescription?: string;
  slotId?: string;
  totalRatings: number;
  totalReviews: number;
  unitName: string;
  weight?: string;
  wholesale?: boolean;
}

export interface MoneyRange {
  from: Money;
  to: Money;
}

/**
 * VariantPrice parallels the CT {@link Price} interface except with
 * certain fields omitted and `obj` always populated. Don't add anything here
 * which isn't present in CT's {@link Price}.
 *
 * This is produced by webstore's
 * `Nuts\Commercetools\Helpers\ProductVariantSimplifier`.
 */
export interface VariantPrice {
  value: Money;
  channel?: {
    obj: {
      key: string;
      name?: LocalizedString;
    };
  };
  discounted?: {
    value: Money;
    discount: {
      obj?: {
        name: LocalizedString;
        description?: LocalizedString;
        value: ProductDiscountValue;
      };
    };
  };
  tiers?: {
    minimumQuantity: number;
    value: Money;
  }[];
}

export interface ProductCardData extends BaseProductCardData {
  hidePrice: boolean;
  listPrice: VariantPrice;
  onePoundBulk: boolean;
  piecePrice: Money;
  piecePriceRange?: MoneyRange;
  prices: VariantPrice[];
  totalSavings?: Omit<SavingsSummary, 'breakdown'>;
}

export const displayPrice = (productCard: ProductCardData) => {
  if (productCard.piecePriceRange) {
    return `${money(productCard.piecePriceRange.from)} - ${money(productCard.piecePriceRange.to)}`;
  }
  const price = money(productCard.piecePrice);
  if (productCard.onePoundBulk) return `${price}/lb`;
  return price;
};

const isBuildYourOwnBox = (variant: BaseProductCardData) => variant.productKey === BYOB_PRODUCT_KEY; // ported from `ProductAdapter.php`
const isGiftCertificate = (variant: BaseProductCardData) => variant.productKey === '9990'; // ported from `Item.php`
const computePiecePrice = (price: VariantPrice, priceRange?: MoneyRange) => {
  if (priceRange) return priceRange.from;
  return price.discounted?.value ?? price.value;
};
const computePiecePriceRange = (variant: BaseProductCardData) => {
  // ported from `ProductAdapter.php`
  if (isGiftCertificate(variant)) return { from: from(25), to: from(200) };
  return undefined;
};
const isOnePoundBulk = (variant: BaseProductCardData) =>
  !!variant.bulk && Number(variant.weight) === 1;
const computeTotalSavings = (
  listPrice: VariantPrice,
  otherVariant?: ProductCardData,
): Omit<SavingsSummary, 'breakdown'> | undefined => {
  if (otherVariant) {
    // TODO: phase out `displayComparisonPrice` and `displayDiscountPercent`; may need a breakdown
    return undefined;
  }
  if (!listPrice.discounted) return undefined;
  return {
    ...computeSavings(listPrice.value, listPrice.discounted.value),
    comparisonPrice: listPrice.value,
    description: listPrice.discounted.discount.obj?.description,
    discountDisplayValue: getProductDiscountDisplayValueFromPrice(listPrice),
    onSale: true,
  };
};

function paddedSku(sku: string) {
  return sku.startsWith('0');
}
export function normalizeSku(sku: string): string;
export function normalizeSku(slot: RecommendationsSlot): string;
export function normalizeSku(skuOrSlot: RecommendationsSlot | string): string {
  const sku = typeof skuOrSlot === 'string' ? skuOrSlot : skuOrSlot.sku;
  return paddedSku(sku) ? sku.slice(1) : sku;
}

export const ProductCard = {
  fromAlgolia(algoliaProduct: AlgoliaVariant): ProductCardData {
    const {
      autoDeliveryEligible,
      bulk,
      comparisonPrice,
      cost,
      discountPercent,
      formattedUnitPrice,
      hasSiblings,
      outOfStock,
      path,
      Product_allVariantsOutOfStock: allVariantsOutOfStock,
      Product: {
        key,
        name,
        listingImageUrl,
        merchandisingCategory,
        reportingCategory,
        requiresCustomization,
        reviews: { averageRating, totalRatings, totalReviews },
        shortDescription,
        tags,
      },
      promoteShortVariantName,
      sku,
      singlePiecePrice,
      shortVariantName,
      weight,
      wholesale,
    } = algoliaProduct;

    const productData: BaseProductCardData = {
      allVariantsOutOfStock,
      autoDeliveryEligible,
      averageRating,
      bulk,
      cost,
      displayComparisonPrice:
        comparisonPrice && comparisonPrice > 0 ? money(comparisonPrice) : undefined,
      displayDiscountPercent:
        discountPercent && discountPercent > 0 ? `${discountPercent}%` : undefined,
      displayPrice: formattedUnitPrice,
      hasSiblings,
      imageUrl: listingImageUrl,
      inStock: !outOfStock,
      keywords: tags,
      merchandisingCategory,
      name,
      path,
      price: singlePiecePrice,
      productKey: key,
      promoteShortVariantName,
      reportingCategory,
      requiresCustomization,
      // eslint-disable-next-line no-underscore-dangle
      searchQueryId: algoliaProduct.__queryID,
      shortDescription,
      sku,
      totalRatings,
      totalReviews,
      unitName: shortVariantName,
      weight,
      wholesale,
    };

    const listPrice = algoliaProduct.prices.find((p) => !p.channel)!;
    const piecePriceRange = computePiecePriceRange(productData);
    const piecePrice = computePiecePrice(listPrice, piecePriceRange);

    return {
      ...productData,
      hidePrice: cents(piecePrice) === 0 || isBuildYourOwnBox(productData),
      listPrice,
      onePoundBulk: isOnePoundBulk(productData),
      piecePrice,
      piecePriceRange,
      prices: algoliaProduct.prices,
      totalSavings: computeTotalSavings(listPrice),
    };
  },

  fromDY(recommendation: RecommendationsSlot): ProductCardData {
    const product = recommendation.productData;

    const productData: BaseProductCardData = {
      allVariantsOutOfStock: !!adaptLegacyBoolean(product.all_variants_out_of_stock),
      autoDeliveryEligible: !!adaptLegacyBoolean(product.auto_delivery_eligible),
      averageRating: parseFloat(product.average_rating),
      bulk: adaptLegacyBoolean(product.bulk),
      cost: product.cost,
      displayComparisonPrice: product.display_comparison_price,
      displayDiscountPercent: product.display_discount_percent,
      displayPrice: product.display_price,
      hasSiblings: adaptLegacyBoolean(product.has_siblings),
      imageUrl: product.image_url,
      inStock: !!adaptLegacyBoolean(product.in_stock),
      keywords: product.keywords,
      merchandisingCategory: product.primary_merchandising_category,
      name: product.name,
      path: product.path_name,
      price: product.price,
      productKey: product.product_key,
      reportingCategory: product.reporting_category,
      requiresCustomization: !!adaptLegacyBoolean(product.requires_customization),
      sku: normalizeSku(recommendation),
      slotId: recommendation.slotId,
      totalRatings: parseFloat(product.total_ratings),
      totalReviews: parseFloat(product.total_reviews),
      unitName: product.short_unit_name,
      weight: product.short_unit_name === '1lb bag' ? '1' : '0', // TODO: get from data feed
      wholesale: adaptLegacyBoolean(product.wholesale),
    };

    let prices: VariantPrice[] | undefined;
    try {
      prices = JSON.parse(product.prices_json);
    } catch (e) {
      // This error will be handled in below if block
    }
    if (!prices?.length) {
      console.error('Failed to parse prices_json; deriving a baseline', product.prices_json);
      prices = [{ value: from(product.price) }];
    }
    const listPrice = prices.find((p) => !p.channel)!;
    const piecePriceRange = computePiecePriceRange(productData);
    const piecePrice = computePiecePrice(listPrice, piecePriceRange);

    return {
      ...productData,
      hidePrice: cents(piecePrice) === 0 || isBuildYourOwnBox(productData),
      listPrice,
      onePoundBulk: isOnePoundBulk(productData),
      piecePrice,
      piecePriceRange,
      prices,
      totalSavings: computeTotalSavings(listPrice),
    };
  },
  fromWebstore(recommendation: WebstoreRecommendation): ProductCardData {
    const productData: BaseProductCardData = {
      allVariantsOutOfStock: false,
      autoDeliveryEligible: false,
      averageRating: parseFloat(recommendation.average_rating),
      bulk: recommendation.bulk,
      cost: recommendation.cost,
      displayPrice: recommendation.display_price,
      hasSiblings: false,
      imageUrl: recommendation.image_url,
      inStock: recommendation.in_stock,
      keywords: recommendation.keywords,
      name: recommendation.name,
      path: recommendation.path_name,
      orderCount: recommendation.order_count,
      price: Number(recommendation.price),
      productKey: recommendation.product_key,
      reportingCategory: recommendation.reporting_category,
      requiresCustomization: recommendation.requires_customization === 'true',
      sku: recommendation.sku,
      totalRatings: parseFloat(recommendation.total_ratings),
      totalReviews: parseFloat(recommendation.total_reviews),
      unitName: recommendation.short_unit_name,
      weight: recommendation.short_unit_name === '1lb bag' ? '1' : '0', // TODO: get from data feed
      wholesale: recommendation.wholesale,
    };

    let prices: VariantPrice[] | undefined;
    try {
      prices = JSON.parse(recommendation.prices_json);
    } catch (e) {
      // This error will be handled in below if block
    }
    if (!prices?.length) {
      console.error('Failed to parse prices_json; deriving a baseline', recommendation.prices_json);
      prices = [{ value: from(productData.price) }];
    }
    const listPrice = prices.find((p) => !p.channel)!;
    const piecePriceRange = computePiecePriceRange(productData);
    const piecePrice = computePiecePrice(listPrice, piecePriceRange);

    return {
      ...productData,
      hidePrice: cents(piecePrice) === 0 || isBuildYourOwnBox(productData),
      listPrice,
      onePoundBulk: isOnePoundBulk(productData),
      piecePrice,
      piecePriceRange,
      prices,
      totalSavings: computeTotalSavings(listPrice),
    };
  },
};
