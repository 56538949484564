<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';

const router = useRouter();

const linksContainerRef = ref<HTMLElement>();

const getDataPromo = (name: string) => ({
  'data-promo': '1',
  'data-promo-creative': 'Header Links',
  'data-promo-name': `Search Suggestions: ${name}`,
});

useRouterLinks(linksContainerRef, router);
</script>

<template>
  <SmallBodyText
    class="font-semibold"
    data-test="static-search-suggestions"
    ref="linksContainerRef"
  >
    Looking for
    <a v-bind="getDataPromo('Almonds')" class="underline" href="/nuts/almonds/">Almonds</a>,
    <a v-bind="getDataPromo('Cashews')" class="underline" href="/nuts/cashews/">Cashews</a>,
    <a v-bind="getDataPromo('Walnuts')" class="underline" href="/nuts/walnuts/">Walnuts</a>
    or
    <a v-bind="getDataPromo('Organic')" class="underline" href="/organic/">Organic</a>?
  </SmallBodyText>
</template>
