<script lang="ts">
import { computed, defineComponent } from 'vue';

import RouteLink from '@/components/base/RouteLink.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import { TWColorsBackground } from '@/utils/cms';

export default defineComponent({
  name: 'BaseFullscreenCenteredBanner',
  props: {
    backgroundColor: { required: false, type: String, default: 'cream' },
    bgDesktopImg: { required: false, type: String },
    bgMobileImg: { required: false, type: String },
    body: { required: true, type: String },
    callToActionLink: { required: true, type: String },
    callToActionText: { required: false, type: String, default: 'Learn More' },
    header: { required: true, type: String },
    promoCreative: { required: false, type: String },
    promoName: { required: false, type: String },
  },
  components: {
    RouteLink,
    ThemedButton,
  },
  setup(props) {
    return {
      bgColor: computed(() => TWColorsBackground[props.backgroundColor]),
      desktopImage: computed(() => `url(${props.bgDesktopImg})`),
      mobileImage: computed(() => `url(${props.bgMobileImg})`),
    };
  },
});
</script>

<template>
  <div
    class="flex flex-col items-center justify-center w-full h-auto px-6 py-10 bg-cover background md:h-96"
    :class="bgColor"
    data-promo="1"
    :data-promo-name="promoName"
  >
    <div class="text-3xl font-bold text-center md:text-4xl">{{ header }}</div>
    <div class="max-w-3xl my-4 text-base font-semibold text-center md:text-lg">{{ body }}</div>
    <RouteLink :to="callToActionLink" class="hover:no-underline">
      <ThemedButton
        theme="gray"
        data-promo="1"
        :data-promo-creative="promoCreative"
        :data-promo-name="promoName"
      >
        {{ callToActionText }}
      </ThemedButton>
    </RouteLink>
  </div>
</template>

<style lang="scss" scoped>
.background {
  background-image: v-bind(mobileImage);
  background-position: center center;
}

@media (min-width: 1024px) {
  .background {
    background-image: v-bind(desktopImage);
    background-position: center bottom;
  }
}
</style>
