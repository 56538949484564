<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import ThemedButton from '@/components/base/ThemedButton.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useCart } from '@/composables/useCart';
import { useProceedToCheckout } from '@/composables/useProceedToCheckout';
import { pluralizeBy } from '@/filters/pluralize';

const emit = defineEmits(['handle-close']);

const router = useRouter();
const store = useStore();

const { lineItems } = useCart(store);
const { navigateTo } = useRouteChange(router);

const { isPending, proceedToCheckout } = useProceedToCheckout();

const handleClose = () => {
  emit('handle-close');
};

const onViewCart = async () => {
  handleClose();
  await navigateTo('/cart');
};

const onProceedToCheckout = async () => {
  await proceedToCheckout({ onSuccess: handleClose });
};
</script>

<template>
  <div class="grid gap-2 md:gap-2.5 md:flex">
    <ThemedButton
      class="w-full h-12 md:hidden"
      data-test="view-cart-and-checkout"
      theme="gray"
      data-promo="1"
      data-promo-creative="Add to cart modal"
      data-promo-name="View Cart and Checkout"
      @click="onViewCart"
    >
      View Cart & Checkout ({{ lineItems.length }} {{ pluralizeBy('item', lineItems) }})
    </ThemedButton>
    <UnstyledButton class="text-center md:hidden" @click="handleClose">
      <BaseBodyText
        class="py-1 text-black underline"
        data-promo="1"
        data-promo-creative="Add to cart modal"
        data-promo-name="Keep Shopping"
      >
        Keep Shopping
      </BaseBodyText>
    </UnstyledButton>
    <ThemedButton
      class="hidden w-auto h-12 grow md:visible md:block"
      data-test="view-cart"
      :disabled="isPending"
      theme="white"
      data-promo="1"
      data-promo-creative="Add to cart modal"
      data-promo-name="View Cart"
      @click="onViewCart"
    >
      View Cart
    </ThemedButton>
    <ThemedButton
      class="hidden w-auto h-12 grow md:visible md:block"
      data-test="proceed-to-checkout"
      :isLoading="isPending"
      theme="gray"
      data-promo="1"
      data-promo-creative="Add to cart modal"
      data-promo-name="Proceed to checkout"
      @click="onProceedToCheckout"
    >
      <span class="mr-1">Proceed to</span>
      Checkout ({{ lineItems.length }} item{{ lineItems.length > 1 ? 's' : '' }})
    </ThemedButton>
  </div>
</template>
