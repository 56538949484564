import { HoneycombWebSDK, WebVitalsInstrumentation } from '@honeycombio/opentelemetry-web';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';

function init() {
  const hasFlag = window.document
    .querySelector('body')
    ?.classList.contains('enableBrowserTelemetry');
  if (!hasFlag) return;

  const sdk = new HoneycombWebSDK({
    endpoint: '/v1/traces',
    serviceName: 'webfront-client',
    instrumentations: [
      getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-xml-http-request': {
          clearTimingResources: true,
          propagateTraceHeaderCorsUrls: [/^https:\/\/.*.execute-api.us-east-1.amazonaws.com\/prod/],
        },
        '@opentelemetry/instrumentation-fetch': {
          propagateTraceHeaderCorsUrls: [/^https:\/\/.*.execute-api.us-east-1.amazonaws.com\/prod/],
        },
      }),
      new WebVitalsInstrumentation(),
    ],
  });
  sdk.start();
}

init();
