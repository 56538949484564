import {
  CustomFields,
  FieldContainer,
  LineItem,
  LocalizedString,
  ProductVariant,
} from '@commercetools/platform-sdk';
import {
  INutsVariantAttributes as NutsVariantAttributes,
  pivotAttributeValues,
} from '@nuts/auto-delivery-sdk/dist/utils/helpers';
import { from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import groupBy from 'lodash/groupBy';

import { INGREDIENT_LIMITS, MIX_PRODUCT_KEY } from '@/lib/customizers/mixes';
import {
  LARGE_CUSTOM_TRAY_PRODUCT_KEY,
  MEDIUM_CUSTOM_TRAY_PRODUCT_KEY,
} from '@/lib/customizers/trays';
import { getProductDiscountDisplayValueFromPrice } from '@/lib/product/discounts/productDiscounts';
import { isGiftLineItem } from '@/utils/cart';
import { DateString } from '@/utils/dateTime';
import { ImageBySize } from '@/utils/image';
import { computeSavings, LineSavingsBreakdownItem, LineSavingsSummary, Money } from '@/utils/money';
import { reportError } from '@/utils/reportError';

/** Technically each variant can define any valid price in CT; it is a business convention to set
 * these to $3.00. Performing a lookup for display purposes would be somewhat convoluted. In particular,
 * the ticket connected to this wants to display the CTA above the fold, and this seems better than
 * eagerly loading the greeting cards and finding the minimum price.
 */
export const GREETING_CARD_PRICE = from(3);

export interface CartLineItemFields {
  readonly autoDeliveryInterval?: number;
  readonly autoDeliveryOfferLocation?: string;
  readonly autoDeliveryOfferType?: string;
  readonly customizationsDescription?: string;
  readonly externalId?: string;
  readonly markedAsGift?: boolean;
  readonly occasionImageUrl?: string;
  readonly occasionName?: string;
  readonly parentExternalId?: string;
  /** stringified @type {DigitalGiftRecipient[]} */
  readonly recipientsJson?: string;
  readonly sendAt?: DateString;
  readonly senderName?: string;
}
export interface GreetingCardCartLineItemFields extends CartLineItemFields {
  readonly greetingCardMessage: string;
}
export interface PackingSlipMessageCustomLineItemFields {
  readonly packingSlipMessage: string;
}

export interface NutsLineItem<T extends CartLineItemFields = CartLineItemFields> extends LineItem {
  readonly children?: NutsLineItem[];
  readonly custom?: CustomFields & {
    readonly fields: FieldContainer & T;
  };
  readonly customizationsDescription?: string;
  readonly piecePrice: Money;
  readonly totalSavings?: LineSavingsSummary;
  readonly productKey: string;
  readonly productPath?: string;
  readonly titleImage?: {
    readonly [size in keyof ImageBySize]: ImageBySize[size];
  };
  readonly totalPriceBeforeCartLevelDiscount: Money;
  readonly variant: ProductVariant & {
    readonly fixedAddress?: string;
    readonly pieceCost?: Money;
    readonly sku: string;
  } & Readonly<NutsVariantAttributes>;
}

export function buildLineSavingsSummary({
  lineItemMode,
  price,
  priceMode,
  quantity,
  variant,
}: LineItem) {
  const pieceComparisonPrice = variant.prices?.find((p) => !p.channel);
  if (isGiftLineItem({ lineItemMode }) || priceMode !== 'Platform' || !pieceComparisonPrice) {
    return undefined;
  }
  const totalComparisonPrice = Money.multiply(pieceComparisonPrice.value, quantity);
  const totalPriceBeforeCartLevelDiscount = Money.multiply(
    price.discounted?.value ?? price.value,
    quantity,
  );
  const breakdown: LineSavingsBreakdownItem[] = [];
  let balance = totalComparisonPrice;

  const recordBreakdownItem = (
    type: LineSavingsBreakdownItem['type'],
    newPiecePrice: Money,
    description?: LocalizedString,
    onSale = false,
  ) => {
    const newTotalPrice = Money.multiply(newPiecePrice, quantity);
    breakdown.push({
      type,
      ...computeSavings(balance, newTotalPrice),
      description,
      onSale,
    });
    balance = newTotalPrice;
  };

  if (price.channel) {
    const channelPrice = variant.prices?.find((p) => p.channel?.id === price.channel!.id);
    // We don't currently expand price.channel except on receipt page, so check
    // variant.prices[*].channel too if needed.
    const channel =
      price.channel.obj ??
      variant.prices?.find((p) => p.channel?.obj?.id === price.channel!.id)?.channel?.obj;
    if (channelPrice) {
      const { discountPercent } = channel?.custom?.fields ?? {};
      recordBreakdownItem('Channel', channelPrice.value, {
        en: discountPercent ? `${discountPercent}% off Auto-Delivery` : 'Auto-Delivery',
      });
    }
  }
  if (price.discounted) {
    recordBreakdownItem(
      'ProductDiscount',
      price.discounted.value,
      price.discounted.discount?.obj?.description ?? { en: 'Sale Discount' },
      true,
    );
  } else if (price.tiers) {
    const tier = price.tiers?.find((t) => Money.equals(price.value, t.value));
    if (tier) {
      recordBreakdownItem('PriceTier', tier.value, { en: 'Bulk Discount' });
    }
  }
  if (!Money.equals(balance, totalPriceBeforeCartLevelDiscount)) {
    reportError(`balance != totalPriceBeforeCartLevelDiscount`);
  }
  return breakdown.length
    ? <LineSavingsSummary>{
        ...computeSavings(totalComparisonPrice, totalPriceBeforeCartLevelDiscount),
        breakdown,
        comparisonPrice: totalComparisonPrice,
        description: breakdown.find((b) => b.description)?.description,
        discountDisplayValue: getProductDiscountDisplayValueFromPrice(price, quantity),
        onSale: breakdown.some((b) => b.onSale),
      }
    : undefined;
}

export const NutsLineItem = {
  fromCt(lineItem: LineItem): NutsLineItem {
    const discountedOrActivePrice = lineItem.price.discounted ?? lineItem.price;
    const piecePrice = isGiftLineItem(lineItem) ? from(0) : discountedOrActivePrice.value;
    if (!lineItem.productKey) {
      if (import.meta.env.DEV) {
        throw new Error('productKey is missing');
      } else {
        reportError('productKey is missing');
      }
    }
    return {
      ...lineItem,
      customizationsDescription: lineItem.custom?.fields.customizationsDescription,
      piecePrice,
      // We are confident that `lineItem.productKey` will be populated despite
      // being optional in CT's declaration. In our project it's present for all
      // (active) Carts, and missing only for Orders placed before Dec 4, 2021,
      // which we currently never access. See {@link LineItem.productKey} for
      // CT's detailed explanation.
      productKey: lineItem.productKey!,
      totalPriceBeforeCartLevelDiscount: Money.multiply(piecePrice, lineItem.quantity),
      totalSavings: buildLineSavingsSummary(lineItem),
      variant: {
        ...lineItem.variant,
        fixedAddress: lineItem.variant.attributes?.find((a) => a.name === 'fixedAddress')?.value,
        pieceCost: lineItem.variant.attributes?.find((a) => a.name === 'pieceCost')?.value,
        sku: lineItem.variant.sku ?? '',
        ...pivotAttributeValues(lineItem.variant.attributes).variant,
      },
    };
  },
};

function withSummedChildren(parent: NutsLineItem, children: NutsLineItem[]): NutsLineItem {
  const all = [parent, ...children];
  const totalPriceBeforeCartLevelDiscount = Money.sumBy(
    all,
    (c) => c.totalPriceBeforeCartLevelDiscount,
  );
  let totalSavings: LineSavingsSummary | undefined;

  const childSavings = all.flatMap((c) => c.totalSavings ?? []);
  if (childSavings.length) {
    const comparisonPrice = Money.sumBy(
      all,
      (c) => c.totalSavings?.comparisonPrice ?? c.totalPriceBeforeCartLevelDiscount,
    );
    let balance = comparisonPrice;
    const breakdown = childSavings
      .flatMap((cs) => cs.breakdown)
      .map((b) => {
        const newPrice = Money.subtract(balance, b.value);
        const { percent } = computeSavings(balance, newPrice);
        balance = newPrice;
        return { ...b, percent };
      });
    if (!Money.equals(balance, totalPriceBeforeCartLevelDiscount)) {
      reportError('balance != totalPriceBeforeCartLevelDiscount (in withSummedChildren)');
    }
    totalSavings = {
      // TODO: understand if it is possible / needed to calculate `discountDisplayValue` for parent + children
      ...computeSavings(comparisonPrice, totalPriceBeforeCartLevelDiscount),
      breakdown,
      comparisonPrice,
      // the first we find will do for now, until we have a clear use case
      description: childSavings.find((cs) => cs.description)?.description,
      onSale: childSavings.some((cs) => cs.onSale),
    };
  }

  const childrenNames = children.map((e) => e.name.en);
  const counts: { [key: string]: number } = childrenNames.reduce<{ [key: string]: number }>(
    (c, e) => {
      const newCounts = { ...c };
      newCounts[e] = newCounts[e] ? newCounts[e] + 1 : 1;
      return newCounts;
    },
    {},
  );
  const customizationsDescription = Object.entries(counts)
    .map((a) => (a[1] > 1 ? `${a[1]}x ${a[0]}` : `${a[0]}`))
    .join(', ');

  return {
    ...parent,
    children,
    customizationsDescription,
    piecePrice: Money.sumBy(all, (c) => c.piecePrice),
    taxedPrice: parent.taxedPrice && {
      totalGross: Money.sumBy(all, (c) => c.taxedPrice?.totalGross),
      totalNet: Money.sumBy(all, (c) => c.taxedPrice?.totalNet),
    },
    totalPrice: Money.sumBy(all, (c) => c.totalPrice),
    totalPriceBeforeCartLevelDiscount,
    totalSavings,
  };
}

export function collapseChildren(lineItems: NutsLineItem[]) {
  const { parents, ...childrenByParentId } = groupBy(
    lineItems,
    (li) => li.custom?.fields.parentExternalId ?? 'parents',
  );

  return (parents ?? []).map((parent) => {
    const children = childrenByParentId[parent.custom?.fields.externalId ?? ''];
    return children ? withSummedChildren(parent, children) : parent;
  });
}

/** Find products explicitly marked as a gift, or belonging to a gifting category */
export function containsGiftPurchase(lineItems: NutsLineItem[]) {
  return lineItems.some((item) => {
    if (item.custom?.fields.markedAsGift) return true;
    return (
      item.variant.attributes?.find((a) => a.name === 'merchandisingCategory')?.value.label ===
      'Gifts'
    );
  });
}

interface ProductNamesByKey {
  [productKey: string]: string;
}
/**
 * Customizers' volumetric ingredients are stored in custom fields.
 * This is a helper function to facilitate their extraction.
 */
export function volumetricIngredients(lineItem: NutsLineItem): ProductNamesByKey | undefined {
  if (
    !lineItem.productKey ||
    !lineItem.custom?.fields ||
    ![LARGE_CUSTOM_TRAY_PRODUCT_KEY, MEDIUM_CUSTOM_TRAY_PRODUCT_KEY, MIX_PRODUCT_KEY].includes(
      lineItem.productKey,
    )
  ) {
    return undefined;
  }
  const customTrayIngredientCount = 5;
  const customMixIngredientCount = INGREDIENT_LIMITS.max;

  const skuToIngredientCount: { [key: string]: number } = {
    [LARGE_CUSTOM_TRAY_PRODUCT_KEY]: customTrayIngredientCount,
    [MEDIUM_CUSTOM_TRAY_PRODUCT_KEY]: customTrayIngredientCount,
    [MIX_PRODUCT_KEY]: customMixIngredientCount,
  };

  const ingredientCountToPrefix: { [key: number]: string } = {
    [customTrayIngredientCount]: 'customTraySection',
    [customMixIngredientCount]: 'customMixIngredient',
  };

  const contents: ProductNamesByKey = {};
  const targetIngredientCount = skuToIngredientCount[lineItem.productKey];
  const targetPrefix = ingredientCountToPrefix[targetIngredientCount];
  // custom mix ingredients have a zero-based index (e.g. customMixIngredient0ProductKey)
  const start = targetIngredientCount === customTrayIngredientCount ? 1 : 0;
  const end =
    targetIngredientCount === customTrayIngredientCount
      ? targetIngredientCount
      : targetIngredientCount - 1;
  for (let i = start; i <= end; i += 1) {
    const key = lineItem.custom?.fields[`${targetPrefix}${i}ProductKey`];
    if (key) {
      contents[key] = lineItem.custom?.fields[`${targetPrefix}${i}ProductName`];
    }
  }
  return contents;
}

export function filterByAutoDeliveryStatus(lineItems: NutsLineItem[], hasInterval?: boolean) {
  return lineItems.filter((lineItem) => {
    const interval = lineItem.custom?.fields.autoDeliveryInterval;
    return hasInterval ? !!interval : !interval;
  });
}

/**
 * @param format optional, default to `weeks`
 */
export function getAutoDeliveryInterval(
  lineItem: NutsLineItem,
  format: 'days' | 'weeks' = 'weeks',
) {
  const divisor = format === 'days' ? 1 : 7;
  const interval = lineItem.custom?.fields.autoDeliveryInterval;
  return interval ? interval / divisor : interval;
}
