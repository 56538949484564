<script lang="ts">
import { useVModel } from '@vueuse/core';
import { defineComponent, watch } from 'vue';

import { useForm } from '@/composables/useForm';

export default defineComponent({
  name: 'EmailTypoSuggestion',
  props: {
    modelValue: { required: true, type: String },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const email = useVModel(props, 'modelValue', emit);

    const { handleEmailTypos } = useForm();
    const { acceptSuggestion, checkForSuggestions, removeSuggestion, suggestedEmail } =
      handleEmailTypos(email);

    watch(email, checkForSuggestions);

    return {
      acceptSuggestion,
      checkForSuggestions,
      removeSuggestion,
      suggestedEmail,
    };
  },
});
</script>

<template>
  <transition name="fade" :duration="200">
    <div
      v-if="suggestedEmail"
      id="emailSuggestion"
      class="absolute z-10 w-full px-4 py-3 text-sm leading-3 text-white cursor-pointer top-12 underline-source bg-black shadow-sharp"
      data-test="mailcheck-suggestion"
      :tabindex="suggestedEmail ? 0 : -1"
      @click="acceptSuggestion()"
      @keydown.tab.exact="removeSuggestion"
      @keyup.enter="acceptSuggestion()"
      :aria-hidden="!suggestedEmail"
    >
      Did you mean <span class="underline-target">{{ suggestedEmail }}</span
      >?
    </div>
  </transition>
</template>
