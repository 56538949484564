<script setup lang="ts" generic="T">
import { useVModel } from '@vueuse/core';

const props = defineProps<{
  appearanceNone?: boolean;
  disabled?: boolean;
  id?: string;
  modelValue?: T;
  value: T;
}>();

const emit = defineEmits(['update:modelValue']);

const selection = useVModel(props, 'modelValue', emit);
</script>

<template>
  <label
    class="flex items-center my-0 cursor-pointer"
    :class="{ 'relative inline-flex': appearanceNone }"
    data-test="radio-label"
  >
    <input
      v-model="selection"
      class="inline-block w-4 h-4 m-0 align-middle cursor-pointer base-radio accent-bg-nuts-lime-800"
      :class="{
        'mr-3': $slots.default,
        'absolute top-0 left-0 m-0 appearance-none h-full w-full': appearanceNone,
      }"
      :disabled="disabled"
      :name="$attrs.name"
      :id="id || `${$attrs.name}-${value}`"
      type="radio"
      :value="value"
    />
    <slot />
  </label>
</template>

<style lang="scss" scoped>
.base-radio {
  accent-color: #4a8500;
}
</style>
