<script setup lang="ts">
const term = defineModel<string>({
  set: (value) => value.toLocaleLowerCase(),
});
</script>

<template>
  <form data-test="keyword-filter" @submit.prevent>
    <label for="keyword" aria-label="Search Products" />
    <input
      v-model="term"
      class="w-full px-5 py-2 border border-solid focus:outline-none font-proxima-nova rounded-3xl"
      type="text"
      id="keyword"
      placeholder="Search Products"
    />
  </form>
</template>
