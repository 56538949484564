<script setup lang="ts">
import { watchDebounced, watchThrottled } from '@vueuse/core';
import { SearchResponse } from 'instantsearch.js';
import { inject, ref } from 'vue';

import { AlgoliaCategory, AlgoliaVariant, init as initAlgoliaClient } from '@/api/algolia';
import DepartmentList from '@/components/layout/header/DepartmentList.vue';
import ProductList from '@/components/layout/header/ProductList.vue';
import { useFocusTrap } from '@/composables/useFocusTrap';
import { sendExperimentViewedEvent } from '@/utils/analytics/experimentViewedEvent';
import { sendProductsSearchedEvent } from '@/utils/analytics/rudderstack';

const props = defineProps<{
  query: string;
}>();

const emit = defineEmits<{
  (e: 'dismiss', setFocus?: boolean): void;
}>();

const isOpen = defineModel<boolean>({ required: true });

const algoliaClient = inject('algoliaClient', () => initAlgoliaClient(), true);

const featuredCategories = ref<SearchResponse<AlgoliaCategory>>();
const productResults = ref<SearchResponse<AlgoliaVariant>>();

const suggestionsModalRef = ref<HTMLElement>();

const handleClose = (dismissed?: boolean) => {
  isOpen.value = false;
  if (dismissed) emit('dismiss');
};

const fetchResults = async (search: string) => {
  const departmentsIndex = algoliaClient.initIndex('Departments');
  const productsIndex = algoliaClient.initIndex('Products');

  const loadCategories = async () => {
    featuredCategories.value = await departmentsIndex.search<AlgoliaCategory>(search, {
      distinct: true,
      hitsPerPage: 5,
      filters: 'idealForSuggestions:true',
      ruleContexts: ['featuredCategories'],
    });
  };

  const loadProducts = async () => {
    productResults.value = await productsIndex.search<AlgoliaVariant>(search, {
      analytics: !!search,
      distinct: true,
      hitsPerPage: 5,
      filters: 'traits:searchable',
      getRankingInfo: true,
    });
    if (productResults.value?.abTestID) {
      sendExperimentViewedEvent(
        {
          experiment_id: `${productResults.value.abTestID}`,
          variation_id: `${productResults.value.abTestVariantID!}`,
        },
        'Algolia',
      );
    }
  };

  await Promise.all([loadCategories(), loadProducts()]);
  isOpen.value = true;
};

const { onNextElement, onPreviousElement } = useFocusTrap(suggestionsModalRef);
watchThrottled(() => props.query, fetchResults, { throttle: 200 });
watchDebounced(
  () => props.query,
  (query) => sendProductsSearchedEvent(query),
  { debounce: 400 },
);
</script>

<template>
  <div
    v-if="query && (featuredCategories?.hits.length || productResults?.hits.length)"
    v-show="isOpen"
    class="absolute z-10 hidden p-3 bg-white border border-solid xl:left-0 lg:-left-10 lg:-right-10 xl:right-0 rounded-xl border-neutral-300 lg:visible lg:block"
    id="search-suggestions"
    data-test="header-search-suggestions"
    ref="suggestionsModalRef"
    @keydown.esc.prevent="handleClose(true)"
    @keydown.exact.tab="onNextElement"
    @keydown.shift.tab="onPreviousElement"
  >
    <DepartmentList
      v-if="featuredCategories?.hits.length"
      :index="featuredCategories"
      @navigating="handleClose"
    />
    <ProductList
      v-if="productResults?.hits.length"
      :index="productResults"
      @navigating="handleClose"
    />
  </div>
</template>
