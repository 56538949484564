<script lang="ts">
import { defineComponent } from 'vue';

import Notification from '@/components/base/Notification.vue';

export default defineComponent({
  name: 'GlobalErrorNotification',
  components: {
    Notification,
  },
});
</script>

<template>
  <Notification theme="error">
    <div>
      We encountered an issue processing your request. Please try again or
      <RouterLink to="/contact" class="underline color-inherit inset-inline-block">
        contact customer service
      </RouterLink>
      if the issue persists.
    </div>
  </Notification>
</template>
