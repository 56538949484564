<script setup lang="ts">
import { onMounted } from 'vue';
import { googleOneTap } from 'vue3-google-login';
import { useStore } from 'vuex';

import { getConfigEntry } from '@/api/config';
import { useAuth } from '@/composables/useAuth';
import { useCart } from '@/composables/useCart';
import { useCustomer } from '@/stores/customer';

onMounted(async () => {
  try {
    const store = useStore();
    const { googleCredentialSignIn } = useAuth(store);
    const { loadCart, loadLineItemExpansions } = useCart(store);

    // We don't need to show one-tap if user is already signed in
    if (useCustomer().customer) return;

    const { clientId } = getConfigEntry('google').oauth;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const isLoggedOut = urlSearchParams.get('logged_out') === '1';
    const response = await googleOneTap({ clientId, autoLogin: !isLoggedOut });
    const signInResponse = await googleCredentialSignIn(response);

    if (signInResponse) {
      await loadCart(true);
      await loadLineItemExpansions();
    }
  } catch (error) {
    console.error('Google One Tap:', error);
  }
});
</script>
