/* eslint-disable import/prefer-default-export */

import {
  Address,
  Cart,
  CartUpdateAction,
  CustomLineItem,
  Order,
} from '@commercetools/platform-sdk';
import { cents, from, fromCents } from '@nuts/auto-delivery-sdk/dist/utils/money';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import sumBy from 'lodash/sumBy';
import { computed, MaybeRefOrGetter, Ref, ref, toValue, unref } from 'vue';
import { Store } from 'vuex';

import { fromNutsJson } from '@/api';
import {
  AlgoliaVariant,
  getIndex,
  getProductsByCategory,
  init as initAlgolia,
  searchCategories,
} from '@/api/algolia';
import {
  calculateShippingOffers as calculateShippingOffersAPI,
  FutureDeliveryDates,
  getShipDates as getShipDatesAPI,
  getStaticPickupShippingOffer,
  ShippingOffer,
  ShippingOfferSet,
} from '@/api/shippingCalculator';
import { useCart } from '@/composables/useCart';
import { useLocalStorage } from '@/composables/useLocalStorage';
import { getFreeShippingThresholdAmount } from '@/lib/shipping';
import { useCustomer } from '@/stores/customer';
import { NutsAddress } from '@/utils/address';
import {
  buildAddCustomShippingMethodAction,
  buildRemovePackingSlipAction,
  buildSetLineItemShippingDetailsActions,
  buildSetShippingAddressActions,
  buildSetShippingOfferOnItemShippingAddressAction,
  buildSetSignedTrustedMatchkeyItemShippingAddressAction,
  buildUpdateCustomShippingMethodActions,
  buildUpdateGreetingCardAction,
  buildUpdatePackingSlipAction,
  findMessage,
  hasOnlyGiftCertificate,
  hasPhysical,
  isFixedAddress,
  isFixedEmailAddress,
  isGiftCertificateLineItem,
  isGiftLineItem,
  isPackingSlipMessageCustomLineItem,
  isPresetDelivery,
  isPresetDeliveryLineItem,
  linesForKey,
  needEmailAddress,
  needPhysicalAddress,
  parseAddressKey,
  Shipment,
  sortByAddressKey,
} from '@/utils/cart';
import { DateString } from '@/utils/dateTime';
import { Money } from '@/utils/money';

export interface CalculateShippingOffersOptions {
  cxMode?: MaybeRefOrGetter<boolean>;
  deliveryDateCalendar?: MaybeRefOrGetter<boolean>;
  /** Default: `true` */
  lasershipAllowed?: MaybeRefOrGetter<boolean>;
  /** Default: `true` */
  ontracAllowed?: MaybeRefOrGetter<boolean>;
  /** Default: `true` */
  regionalCarriersAllowed?: MaybeRefOrGetter<boolean>;
  /** Default: `standardShipments` */
  requestShipments?: MaybeRefOrGetter<Shipment[]>;
  throwApiError?: MaybeRefOrGetter<boolean>;
}

export interface GiftOptions {
  includeGiftMessage: boolean;
  message: string;
  sender?: string;
}

export interface ExtendedShippingOfferSet extends ShippingOfferSet {
  addHeatResistantPackaging: boolean;
  giftOptions: GiftOptions;
  multishipSerial?: number;
  selectedShippingOffer?: ShippingOffer;
}

export function useDelivery(store: Store<any>, alternativeCart?: Ref<Cart | Order | undefined>) {
  const {
    cart,
    cartDiscountCodes,
    customLineItems,
    lineItems,
    setCart,
    unassignedLineItems,
    updateCart,
  } = useCart(store, alternativeCart);

  const futureDeliveryDates = ref<FutureDeliveryDates>();
  const requestedShipDate = ref<DateString>();
  const shipDates = ref<(DateString | undefined)[]>([]);
  const shippingOfferSets = ref<ExtendedShippingOfferSet[]>([]);

  const freeShippingThreshold = computed(() =>
    getFreeShippingThresholdAmount(useCustomer().customer, cart.value),
  );

  const nextMultishipSerial = computed(() => {
    const { itemShippingAddresses = [] } = cart.value ?? {};
    const shippingAddresses = itemShippingAddresses.filter(
      (a) => !isPresetDelivery(linesForKey(a.key!, lineItems.value)),
    );
    return shippingAddresses.length + 1;
  });

  const shipments = computed<Shipment[]>(() => {
    const unsortedShipments =
      cart.value?.itemShippingAddresses?.map((ctAddress): Shipment => {
        const key = ctAddress.key!; // CT ensures that itemShippingAddresses have keys
        const shipmentLineItems = linesForKey(key, lineItems.value);
        return {
          key,
          address: NutsAddress.fromCt(ctAddress),
          hasPhysical: hasPhysical(shipmentLineItems),
          isFixedAddress: isFixedAddress(shipmentLineItems),
          isFixedEmailAddress: isFixedEmailAddress(shipmentLineItems),
          lineItems: shipmentLineItems,
          needEmailAddress: needEmailAddress(shipmentLineItems),
          needPhysicalAddress: needPhysicalAddress(shipmentLineItems),
          packingSlipMessage: findMessage(undefined, linesForKey(key, customLineItems.value)),
          customFields: ctAddress.custom?.fields,
        };
      }) ?? [];
    if (unsortedShipments.length === 1 && !unassignedLineItems.value.length) {
      return unsortedShipments.slice(0, 1);
    }
    return sortByAddressKey(unsortedShipments);
  });

  const specialDeliveryShipments = computed(() =>
    shipments.value.filter((s) => isPresetDelivery(s.lineItems)),
  );
  const standardShipments = computed<Shipment[]>(() =>
    shipments.value
      .filter((s) => !isPresetDelivery(s.lineItems))
      .map((shipment, i, filteredShipments) => ({
        ...shipment,
        multishipSerial: filteredShipments.length > 1 ? i + 1 : undefined,
      })),
  );
  const requestedDeliveryDate = ref<DateString | undefined>(
    standardShipments.value[0]?.customFields?.requestedDeliveryOn,
  );

  const hasMultipleShipments = computed(() => standardShipments.value.length > 1);

  const isPickup = computed(() => {
    if (hasMultipleShipments.value) return false;
    const [shipment] = standardShipments.value;
    const address = cart.value?.itemShippingAddresses?.find((a) => a.key === shipment?.key);
    return (
      address?.custom?.fields.shipmentPickupCarrierCode === '00' &&
      address?.custom?.fields.shipmentPickupCarrier === 'None'
    );
  });

  const calculateShippingOffers = async (options?: CalculateShippingOffersOptions) => {
    const cxMode = toValue(options?.cxMode ?? false);
    const deliveryDateCalendar = toValue(options?.deliveryDateCalendar ?? false);
    const lasershipAllowed = toValue(options?.lasershipAllowed ?? true);
    const ontracAllowed = toValue(options?.ontracAllowed ?? true);
    const regionalCarriersAllowed = toValue(options?.regionalCarriersAllowed ?? true);
    const requestShipments = toValue(options?.requestShipments ?? standardShipments);
    const throwApiError = toValue(options?.throwApiError);

    const request = {
      shipments: requestShipments
        .filter((shipment) => !hasOnlyGiftCertificate(shipment.lineItems))
        .map((shipment) => {
          const physicalParentLineItems = shipment.lineItems.filter(
            (li) => !isGiftCertificateLineItem(li),
          );
          const shipmentValue = Money.sumBy(physicalParentLineItems, (li) => li.totalPrice);
          const flattenedChildLineItems = physicalParentLineItems.flatMap(
            (parent) => parent.children ?? [],
          );
          const physicalLineItems = [...physicalParentLineItems, ...flattenedChildLineItems];
          return {
            address: shipment?.address,
            key: shipment.key,
            lines: physicalLineItems.map((lineItem) => ({
              id: lineItem.id,
              meltable: lineItem?.custom?.fields?.meltable,
              quantity: lineItem.quantity,
              sku: lineItem.variant.sku,
              weight: lineItem?.custom?.fields?.customTrayNetWeight,
            })),
            shipmentValue,
          };
        }),
      customerId: useCustomer().customer?.id ?? undefined,
      discountCodes: cartDiscountCodes.value,
      requestedShipDate: requestedShipDate.value,
      requestedDeliveryDate: requestedDeliveryDate.value,
      allowBlueStreak: regionalCarriersAllowed,
      allowCdl: regionalCarriersAllowed,
      allowGrandHusky: regionalCarriersAllowed,
      allowLasership: lasershipAllowed,
      allowOntrac: ontracAllowed,
      allowTforce: regionalCarriersAllowed,
      allowUds: regionalCarriersAllowed,
      deliveryDateCalendar,
      filter: cxMode ? 'customerService' : undefined,
    };
    const response = await fromNutsJson(
      calculateShippingOffersAPI(request, undefined, throwApiError),
      { throwError: true },
    );

    await updateCart(() =>
      response.offerSets.flatMap((offerSet) => {
        const existingAddress = cart.value?.itemShippingAddresses?.find(
          (a) => a.key === offerSet.key,
        );

        return [
          {
            action: 'updateItemShippingAddress',
            address: {
              ...NutsAddress.toCt(offerSet.address),
              id: existingAddress?.id,
              key: offerSet.key,
            },
          },
          existingAddress?.custom?.fields.signedTrustedMatchkey && {
            action: 'setItemShippingAddressCustomType',
            addressKey: offerSet.key,
            type: {
              key: 'shippingAddress',
              typeId: 'type',
            },
            fields: {
              signedTrustedMatchkey: existingAddress.custom.fields.signedTrustedMatchkey,
            },
          },
          cart.value?.shippingMode === 'Single' &&
            offerSet.key === standardShipments.value[0].key && {
              action: 'setShippingAddress',
              address: {
                ...NutsAddress.toCt(offerSet.address),
                id: existingAddress?.id,
                key: offerSet.key,
              },
            },
        ];
      }),
    );

    return response;
  };

  const getShipDates = async (countries: string[]): Promise<(DateString | undefined)[]> => {
    const { shipDates: responseShipDates } = await fromNutsJson(getShipDatesAPI({ countries }));
    const shipDateOptions = responseShipDates.map((shipDate) => {
      const isCurrentDate = shipDate === dayjs().format('YYYY-MM-DD');
      return isCurrentDate ? undefined : shipDate;
    });
    const [firstOption] = shipDateOptions;
    shipDates.value = !firstOption ? shipDateOptions : [undefined, ...shipDateOptions];
    return shipDateOptions;
  };

  const removeShipment = async (addressKey: string) =>
    updateCart(() => {
      const shipment = shipments.value.find((s) => s.key === addressKey);
      if (!shipment) return undefined;

      const customLineItemsToRenumber = customLineItems.value.filter(
        (l) => !l.shippingDetails?.targets.some((t) => t.addressKey === addressKey),
      );
      const lineItemShippingDetailsActions = buildSetLineItemShippingDetailsActions(
        lineItems.value,
        addressKey,
        {},
      );
      const shipmentsToRenumber = shipments.value.slice(
        shipments.value.findIndex((s) => s.key === addressKey) + 1,
      );

      const decrementKey = (siblingShipment: Shipment) => parseAddressKey(siblingShipment.key) - 1;

      shipmentsToRenumber.forEach((siblingShipment) => {
        const lineItemIds = siblingShipment.lineItems.flatMap((l) =>
          [l.id].concat(l.children?.map((c) => c.id) ?? []),
        );
        const newKey = `shipment-${decrementKey(siblingShipment)}`;
        lineItemShippingDetailsActions
          .filter((a) => lineItemIds.includes(a.lineItemId))
          .forEach((action) => {
            const { shippingDetails } = action;
            shippingDetails.targets = shippingDetails.targets.map((target) => {
              if (target.addressKey !== siblingShipment.key) return target;
              return {
                ...target,
                addressKey: newKey,
              };
            });
          });
      });

      const shipmentToRemove = shipmentsToRenumber.slice(-1)[0]?.key ?? addressKey;

      return [
        ...lineItemShippingDetailsActions,
        ...customLineItems.value
          .filter((l) => l.shippingDetails?.targets.some((t) => t.addressKey === addressKey))
          .map<CartUpdateAction>((customLineItem) => ({
            action: 'removeCustomLineItem',
            customLineItemId: customLineItem.id,
          })),
        ...customLineItemsToRenumber.map<CartUpdateAction>((lineItem) => {
          const targets =
            lineItem.shippingDetails?.targets.map((target) => {
              const siblingShipment = shipmentsToRenumber.find((s) => s.key === target.addressKey);
              if (!siblingShipment) return target;
              if (target.addressKey !== siblingShipment.key) return target;
              const newKey = `shipment-${decrementKey(siblingShipment)}`;
              return {
                ...target,
                addressKey: newKey,
              };
            }) ?? [];
          return {
            action: 'setCustomLineItemShippingDetails',
            customLineItemId: lineItem.id,
            shippingDetails: {
              targets,
            },
          };
        }),
        ...shipmentsToRenumber.map<CartUpdateAction>((siblingShipment) => ({
          action: 'updateItemShippingAddress',
          address: NutsAddress.toCt({
            ...siblingShipment.address,
            key: `shipment-${decrementKey(siblingShipment)}`,
          }),
        })),
        { action: 'removeItemShippingAddress', addressKey: shipmentToRemove },
        cart.value?.shippingMode === 'Multiple'
          ? { action: 'removeShippingMethod', shippingKey: shipmentToRemove }
          : null,
      ];
    });

  const clearEmptyShipments = async (): Promise<void | undefined> => {
    const [emptyShipment] = shipments.value.filter((s) => !s.lineItems.length);
    if (!emptyShipment) return undefined;
    await removeShipment(emptyShipment.key);
    return clearEmptyShipments();
  };

  const setShippingAddress = async (address: NutsAddress) =>
    updateCart(() => {
      const existingShipments = {
        standardShipments: standardShipments.value,
        allShipments: shipments.value,
      };
      return buildSetShippingAddressActions(
        address,
        existingShipments,
        lineItems.value.filter((l) => !isPresetDeliveryLineItem(l)),
        cart.value?.shippingMode,
      );
    });

  const setShippingAddressCustomFieldsAfterPaymentAuthorized = async (
    address: NutsAddress,
    key: string,
    offer: ShippingOffer,
  ) => {
    if (key) {
      const actions = [buildSetShippingOfferOnItemShippingAddressAction(offer, key, address)];

      if (cart.value?.shippingMode === 'Multiple') {
        const { shippingAddress } = cart.value.shipping.find((s) => s.shippingKey === key)!;
        const price = offer?.price ?? from(0);
        actions.push(...buildUpdateCustomShippingMethodActions(key, shippingAddress, price));
      }

      await updateCart(() => actions);
    }
  };

  const updateShipment = async (
    key: string,
    address: NutsAddress,
    lineQuantities: { [lineItemId: string]: number },
  ) =>
    updateCart(() => [
      ...(cart.value?.shippingMode === 'Multiple'
        ? buildUpdateCustomShippingMethodActions(
            key,
            NutsAddress.toCt({ key, ...address }),
            from(0),
          )
        : []),
      { action: 'updateItemShippingAddress', address: NutsAddress.toCt({ key, ...address }) },
      ...buildSetLineItemShippingDetailsActions(lineItems.value, key, lineQuantities),
    ]);

  const setShippingChargeAdjustment = async (money: Money, itemShippingAddress?: Address) => {
    const addressKey = itemShippingAddress?.key;
    if (!addressKey) return;

    await updateCart(() => [
      ...buildUpdateCustomShippingMethodActions(addressKey, itemShippingAddress, money),
      {
        action: 'setItemShippingAddressCustomField',
        addressKey,
        name: 'price',
        value: money,
      },
    ]);
  };

  const applyShippingOffers = async (
    selections: {
      key: string;
      offer?: ShippingOffer;
      addHeatResistantPacking: boolean;
      giftOptions?: {
        message: string;
        /** @deprecated Still used by Order Uploader */
        sku?: string;
        /** @deprecated Still used by Order Uploader */
        quantity?: number;
      };
    }[],
    previousTotalPrice?: number,
  ) => {
    let safeToAssignStagedUpdates = true;
    const stagedCart = ref(cloneDeep(unref(cart)));
    const tempUpdater = useCart(store, stagedCart);
    try {
      await tempUpdater.updateCart(() => {
        const actions: CartUpdateAction[] = [];
        let totalShippingPrice = 0;
        let uniqueSlugNumber = Date.now();
        selections.forEach(({ key, offer, addHeatResistantPacking, giftOptions }) => {
          const shipment = shipments.value.find((s) => s.key === key);
          const shippingMethodKey = cart.value?.shippingMode === 'Multiple' ? key : undefined;
          if (!shipment) {
            throw new Error(`unknown shipment: ${key}`);
          }
          if (offer) {
            actions.push(
              buildSetShippingOfferOnItemShippingAddressAction(offer, key, shipment.address),
            );
          }
          const existingHrpLineItems = customLineItems.value.filter(
            (l) =>
              l.slug.endsWith('-heat-resistant-packaging') &&
              l.shippingDetails?.targets.some((t) => t.addressKey === key),
          );

          existingHrpLineItems.forEach((lineItem) => {
            actions.push({
              action: 'removeCustomLineItem',
              customLineItemId: lineItem.id,
            });
          });

          if (
            offer?.containsMeltables &&
            (offer.heatResistantIncluded || addHeatResistantPacking)
          ) {
            actions.push({
              action: 'addCustomLineItem',
              name: { en: 'Heat-Resistant Packaging' },
              quantity: 1,
              money: from(offer.heatResistantIncluded ? 0 : 2.95),
              slug: `shipment-${uniqueSlugNumber}-heat-resistant-packaging`,
              shippingDetails: {
                targets: [{ addressKey: key, shippingMethodKey, quantity: 1 }],
              },
            });
          }

          if (!giftOptions && shipment.packingSlipMessage) {
            actions.push(
              buildRemovePackingSlipAction(
                <CustomLineItem>(
                  linesForKey(key, customLineItems.value).find(isPackingSlipMessageCustomLineItem)
                ),
              ),
            );
          }
          if (giftOptions) {
            // TODO: Remove deprecated greeting card flow once Order Uploader manages the custom field itself
            const { message, sku, quantity } = giftOptions;
            const giftActions =
              sku && quantity
                ? buildUpdateGreetingCardAction(undefined, sku, message, quantity, {
                    targets: [{ addressKey: key, shippingMethodKey, quantity }],
                  })
                : [
                    ...buildUpdatePackingSlipAction(
                      linesForKey(key, customLineItems.value).find(
                        isPackingSlipMessageCustomLineItem,
                      ),
                      message,
                      `shipment-${uniqueSlugNumber}-message`,
                      {
                        targets: [{ addressKey: key, shippingMethodKey, quantity: 1 }],
                      },
                    ),
                  ];
            actions.push(...giftActions);
          }

          if (cart.value?.shippingMode === 'Multiple') {
            const { shippingAddress } = cart.value.shipping.find((s) => s.shippingKey === key)!;
            const price = offer?.price ?? from(0);
            actions.push(...buildUpdateCustomShippingMethodActions(key, shippingAddress, price));
          }

          totalShippingPrice += cents(offer?.price ?? from(0));
          uniqueSlugNumber += 1;
        });
        if (cart.value?.shippingMode === 'Single') {
          actions.push({
            action: 'setCustomShippingMethod',
            shippingMethodName: 'Nuts.com Shipping',
            shippingRate: {
              price: fromCents((previousTotalPrice ?? 0) + totalShippingPrice),
            },
          });
        }
        return actions;
      });

      // a change in total price (from shipping method or greeting cards) can alter gift line items,
      // losing address allocation. if we update the session cart immediately, the app can break
      const unassignedGiftLineItems = tempUpdater.unassignedLineItems.value.filter(isGiftLineItem);
      if (unassignedGiftLineItems.length) {
        if (hasMultipleShipments.value) {
          safeToAssignStagedUpdates = false;
          const error: Error & { status?: string } = new Error(
            'Change in gift line items detected; shipment re-allocation required!',
          );
          error.status = 'newUnassignedGiftLineItem';
          throw error;
        }
        await tempUpdater.updateCart(() =>
          buildSetLineItemShippingDetailsActions(
            unassignedGiftLineItems,
            standardShipments.value[0].key,
            unassignedGiftLineItems.reduce(
              (acc, lineItem) => ({
                ...acc,
                [lineItem.id]: lineItem.quantity,
              }),
              {},
            ),
          ),
        );
      }
    } finally {
      if (safeToAssignStagedUpdates) {
        setCart(cloneDeep(unref(tempUpdater.cart) as Exclude<typeof cart.value, Order>));
      }
    }
  };

  const saveDeliveryOptions = async () => {
    await applyShippingOffers(
      shippingOfferSets.value.map((shipment) => {
        type SelectionContext = Parameters<typeof applyShippingOffers>[0][number];
        let giftOptions: SelectionContext['giftOptions'];

        if (shipment.giftOptions.includeGiftMessage || shipment.giftOptions.message) {
          const message = shipment.giftOptions.sender
            ? `${shipment.giftOptions.message}\n - ${shipment.giftOptions.sender}`
            : shipment.giftOptions.message;

          giftOptions = { message };
        }

        if (typeof window !== 'undefined') {
          const localStorage = useLocalStorage('checkout');
          localStorage.set('originalShippingValue', shipment.selectedShippingOffer?.price);
        }

        return {
          key: shipment.key,
          offer: shipment.selectedShippingOffer,
          addHeatResistantPacking: shipment.addHeatResistantPackaging,
          giftOptions,
        };
      }),
    );
  };

  const setShippingOfferSets = (
    offerSets: ShippingOfferSet[],
    /** Default: `standardShipments` */
    requestShipments: Shipment[] = standardShipments.value,
  ) => {
    shippingOfferSets.value = requestShipments.map((shipment) => {
      const offerSet = offerSets.find((offer) => offer.key === shipment.key);
      const offer = offerSet?.shippingOffers[0];
      const messageAndSender = shipment.packingSlipMessage?.split('\n - ') ?? [];
      const transientShippingOffer = shippingOfferSets.value.find(
        (shippingOffer) => shippingOffer.key === shipment.key,
      );
      const giftOptions: GiftOptions = {
        includeGiftMessage:
          transientShippingOffer?.giftOptions.includeGiftMessage ??
          (!!shipment.packingSlipMessage || !offerSet),
        message:
          transientShippingOffer?.giftOptions.message ??
          (messageAndSender.length ? messageAndSender[0] : ''),
        sender:
          transientShippingOffer?.giftOptions.sender ??
          (messageAndSender.length ? messageAndSender[1] : ''),
      };

      return {
        addHeatResistantPackaging: offer?.containsMeltables ?? false,
        address: shipment.address,
        giftOptions,
        key: shipment.key,
        multishipSerial: shipment.multishipSerial,
        selectedShippingOffer: offer,
        shippingOffers: offerSet?.shippingOffers ?? [],
      };
    });
  };

  return {
    applyShippingOffers,
    calculateShippingOffers,
    clearEmptyShipments,
    freeShippingThreshold,
    futureDeliveryDates,
    getShipDates,
    hasMultipleShipments,
    isPickup,
    nextMultishipSerial,
    removeShipment,
    requestedDeliveryDate,
    requestedShipDate,
    saveDeliveryOptions,
    setShippingAddress,
    setShippingAddressCustomFieldsAfterPaymentAuthorized,
    setShippingChargeAdjustment,
    setShippingOfferSets,
    shipDates,
    shipments,
    shippingOfferSets,
    specialDeliveryShipments,
    standardShipments,
    updateShipment,

    addShipment: async (address: NutsAddress, lineQuantities: { [lineItemId: string]: number }) =>
      updateCart(() => {
        let shipmentNumber = 1;
        const exists = () => shipments.value.find((s) => s.key === `shipment-${shipmentNumber}`);
        while (exists()) {
          shipmentNumber += 1;
        }
        const key = `shipment-${shipmentNumber}`;
        const actions: CartUpdateAction[] = [
          ...(cart.value?.shippingMode === 'Multiple'
            ? [buildAddCustomShippingMethodAction(key, NutsAddress.toCt(address), from(0))]
            : []),
          { action: 'addItemShippingAddress', address: NutsAddress.toCt({ ...address, key }) },
          ...buildSetLineItemShippingDetailsActions(lineItems.value, key, lineQuantities),
          ...(address.signedTrustedMatchkey
            ? [
                buildSetSignedTrustedMatchkeyItemShippingAddressAction(
                  key,
                  address.signedTrustedMatchkey,
                ),
              ]
            : []),
        ];

        if (
          cart.value?.shippingMode === 'Single' &&
          (!standardShipments.value.length || key === standardShipments.value[0].key)
        ) {
          actions.push({
            action: 'setShippingAddress',
            address: NutsAddress.toCt(address),
          });
        }

        return actions;
      }),

    async getGreetingCards(): Promise<AlgoliaVariant[]> {
      const algoliaClient = initAlgolia();
      const departmentsIndex = getIndex(algoliaClient, 'Departments');
      const productsIndex = getIndex(algoliaClient, 'Products');

      const [greetingCardCategory] = await searchCategories(departmentsIndex, {
        query: 'Greeting Cards',
        analyticsTags: ['Checkout: Greeting Cards'],
      });
      if (!greetingCardCategory) return [];

      const greetingCards = await getProductsByCategory(productsIndex, {
        categoryKey: `cat-${greetingCardCategory.objectID}`,
        ruleContexts: ['Checkout_AvailableGreetingCards'],
      });
      return greetingCards;
    },

    getPickupShippingOffer: async (address: NutsAddress) => {
      await setShippingAddress(address);
      const [_, pickupDate] = await getShipDates(['US']);
      return getStaticPickupShippingOffer(pickupDate!);
    },

    setRequestedShipDate(date: DateString) {
      requestedShipDate.value = date;
    },

    updateRemainingQuantity: async (lineItemId: string, quantity: number, addressKey?: string) =>
      updateCart(() => {
        const lineItem = lineItems.value.find((li) => li.id === lineItemId);
        if (!lineItem) {
          return [];
        }
        const targets = lineItem.shippingDetails?.targets;
        const otherTargets = targets?.filter((t) => t.addressKey !== addressKey);
        const otherQuantity = sumBy(otherTargets, (t) => t.quantity);
        const lineItemsToUpdate = [lineItem, ...(lineItem.children ?? [])];
        const changeQuantityActions: CartUpdateAction[] = lineItemsToUpdate?.map((li) => ({
          action: 'changeLineItemQuantity',
          lineItemId: li.id,
          quantity: quantity + otherQuantity,
        }));
        const shippingDetailsActions: CartUpdateAction[] =
          quantity + otherQuantity > 0
            ? lineItemsToUpdate?.map((li) => ({
                action: 'setLineItemShippingDetails',
                lineItemId: li.id,
                shippingDetails: {
                  targets: targets ?? [],
                },
              }))
            : [];
        return [...changeQuantityActions, ...shippingDetailsActions];
      }),
  };
}
