import { Order } from '@commercetools/platform-sdk';
import { dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import compact from 'lodash/compact';

import { FbPurchaseEvent, sendConversionEvent } from '@/api/trackingEvents/facebook';
import { PaymentItem } from '@/composables/usePayment';
import { hashEmailAddress, hashPhoneNumber } from '@/utils/analytics';
import { Money } from '@/utils/money';

export async function formatPurchaseForFacebook(
  order: Order,
  paymentItems: PaymentItem[],
): Promise<FbPurchaseEvent> {
  let userData: FbPurchaseEvent['user_data'];
  try {
    userData = { em: await hashEmailAddress(order.customerEmail ?? '') };
    if (order.itemShippingAddresses?.[0]?.phone) {
      userData.ph = await hashPhoneNumber(order.itemShippingAddresses[0].phone);
    }
    if (order.billingAddress?.lastName) {
      const [firstName, lastName] = order.billingAddress.lastName.split(' ');
      userData.fn = await hashEmailAddress(firstName);
      userData.ln = await hashEmailAddress(lastName);
    }
  } catch {
    // browser may not support the `crypto.subtle` APIs
  }
  const discount = paymentItems.find((item) => item.label === 'Discount')?.amount;
  const subtotal = paymentItems.find((item) => item.label === 'Subtotal')?.amount;
  const subtotalMinusCoupons = Money.subtract(subtotal ?? from(0), discount ?? from(0));
  return {
    custom_data: {
      content_type: 'product_group',
      content_ids: compact(order.lineItems.map((li) => li.productKey)),
      value: dollars(subtotalMinusCoupons),
      currency: 'USD',
    },
    user_data: userData,
  };
}

export async function sendPurchaseToFacebook(order: Order, paymentItems: PaymentItem[]) {
  const payload = await formatPurchaseForFacebook(order, paymentItems);
  sendConversionEvent('Purchase', payload);
}
