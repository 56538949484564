<script setup lang="ts">
import { nextTick, ref, watch } from 'vue';

interface Props {
  expanded?: boolean;
  tag?: string;
  transitionDurationMs?: number;
}
const props = withDefaults(defineProps<Props>(), {
  tag: 'div',
  transitionDurationMs: 350,
});

const collapse = ref(!props.expanded);
const transitioning = ref(false);

const element = ref<HTMLElement>();
let timeoutId: ReturnType<typeof setTimeout> | undefined;

function cleanupAnimation() {
  timeoutId = setTimeout(() => {
    collapse.value = !props.expanded;
    transitioning.value = false;
    if (element.value) {
      element.value.style.height = '';
    }
    timeoutId = undefined;
  }, props.transitionDurationMs);
}

function close() {
  if (!element.value) return;
  element.value.style.height = window.getComputedStyle(element.value).height;
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  element.value.offsetHeight; // force repaint
  element.value.style.height = '';
  transitioning.value = true;
  cleanupAnimation();
}

async function open() {
  if (!element.value) return;
  collapse.value = false;
  transitioning.value = true;
  await nextTick();
  element.value.style.height = 'auto';
  const { height } = window.getComputedStyle(element.value);
  element.value.style.height = '';
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  element.value.offsetHeight; // force repaint
  element.value.style.height = height;
  cleanupAnimation();
}

async function transitionHeight() {
  if (timeoutId) clearTimeout(timeoutId);
  if (props.expanded) {
    await open();
  } else {
    close();
  }
}

watch(() => props.expanded, transitionHeight);
</script>

<template>
  <component :is="tag" ref="element" class="animate-height" :class="{ collapse, transitioning }">
    <slot />
  </component>
</template>

<style lang="scss" scoped>
.animate-height {
  position: relative;
  transition-property: height, visibility;
  transition-duration: v-bind('`${transitionDurationMs / 1000}s`');
  transition-timing-function: ease;

  &.transitioning {
    height: 0;
    overflow: hidden;
  }
}

.collapse {
  display: none;
}
</style>
