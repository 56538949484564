<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{ modelValue?: string }>();

const emit = defineEmits(['update:modelValue']);

const keywordValue = computed({
  get: () => props.modelValue?.toLowerCase(),
  set: (val) => emit('update:modelValue', val?.toLowerCase()),
});
</script>

<template>
  <form data-test="keyword-filter" @submit.prevent>
    <label for="keyword" aria-label="Search Products" />
    <input
      v-model="keywordValue"
      class="w-full px-5 py-2 text-base border border-solid focus:outline-none font-proxima-nova rounded-3xl"
      id="keyword"
      placeholder="Search Products"
      type="text"
    />
  </form>
</template>

<style lang="scss" scoped>
input {
  border-color: $quill-gray;
  background-image: url(../../assets/zoom-tool.png);
  background-size: 20px;
  background-position: center right 20px;

  &::placeholder {
    color: #737373;
  }
}
</style>
