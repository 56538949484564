<script lang="ts">
import { computed } from 'vue';

import ThemedButton from '@/components/base/ThemedButton.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import Header4 from '@/components/base/typography/Header4.vue';
import LargeBodyText from '@/components/base/typography/LargeBodyText.vue';
import { useCallback } from '@/composables/useCallback';
import { useLoginModal } from '@/composables/useLoginModal';
import { useCustomer } from '@/stores/customer';
import { BuilderComponent } from '@/utils/cms';

interface Props {
  b2b?: boolean;
  bulletPoints: { text: string }[];
  buttonText: string;
  image: string;
  incentive: string;
  subtitle: string;
  title: string;
}

// eslint-disable-next-line import/prefer-default-export
export const ReferralCardRegistration: BuilderComponent = {
  name: 'Referral Card',
  inputs: [
    {
      name: 'b2b',
      helperText: 'Whether the card is for B2B (true) or DTC (false)',
      type: 'boolean',
      required: true,
    },
    {
      name: 'bulletPoints',
      helperText:
        'List of bullet points to display containing the benefits of the referral program',
      type: 'list',
      subFields: [
        {
          name: 'text',
          type: 'longText',
        },
      ],
      required: true,
    },
    {
      name: 'buttonText',
      type: 'string',
      required: true,
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png', 'jpg', 'svg'],
      required: true,
    },
    {
      name: 'incentive',
      helperText: 'The incentive for the referral program. E.g "Get $20"',
      type: 'string',
      required: true,
    },
    {
      name: 'title',
      type: 'string',
      required: true,
    },
    {
      name: 'subtitle',
      type: 'string',
      required: true,
    },
  ],
};
</script>

<script setup lang="ts">
const props = defineProps<Props>();

const loginModal = useLoginModal();

const id = computed(() => (props.b2b ? 'b2b-cta' : 'dtc-cta'));

const handleClick = () => {
  document.querySelector<HTMLElement>(`#${id.value}`)?.click();
};
const onClick = () => {
  if (useCustomer().customer) {
    handleClick();
  } else {
    loginModal.handleOpen({ callback: useCallback(handleClick) });
  }
};
</script>

<template>
  <div
    class="relative w-full max-w-md px-6 py-8 border-2 border-solid lg:px-8 rounded-4xl"
    :class="b2b ? 'border-nuts-sky-800' : 'border-nuts-amber-400'"
  >
    <LargeBodyText
      class="absolute px-4 py-1 font-semibold -translate-x-1/2 -top-4 left-1/2 font-sofia-pro rounded-4xl whitespace-nowrap"
      :class="b2b ? 'bg-nuts-sky-800 text-white' : 'bg-nuts-amber-400'"
    >
      {{ incentive }}
    </LargeBodyText>
    <div class="flex flex-col items-center">
      <img alt="" class="object-contain w-56 h-28" :src="image" />
      <Header4 class="mt-1" headerTag="p">{{ title }}</Header4>
      <BaseBodyText class="mt-1">{{ subtitle }}</BaseBodyText>
      <div class="mt-3">
        <ThemedButton aria-hidden="true" class="sr-only" :id tabindex="-1" theme="gray">
          {{ buttonText }}
        </ThemedButton>
        <ThemedButton
          :data-test="b2b ? 'refer-a-business-button' : 'refer-a-friend-button'"
          theme="gray"
          @click="onClick"
        >
          {{ buttonText }}
        </ThemedButton>
      </div>
    </div>
    <hr class="h-px my-5 bg-black lg:mx-2" />
    <ul class="flex flex-col gap-2 lg:mx-2">
      <li v-for="bullet in bulletPoints" :key="bullet.text" class="flex items-center gap-2">
        <img alt="" class="w-4 h-4" src="@/assets/star.svg" />
        <BaseBodyText>{{ bullet.text }}</BaseBodyText>
      </li>
    </ul>
  </div>
</template>
