/* eslint-disable import/prefer-default-export */

import { Order } from '@commercetools/platform-sdk';
import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';

import { NutsAddress } from '@/utils/address';
import { isDefined } from '@/utils/isDefined';
import { Money } from '@/utils/money';

type CreateZoneOptions = Parameters<NonNullable<typeof window.extole>['createZone']>[0];

export const Extole = {
  createZone: (options: CreateZoneOptions) => {
    // @ts-expect-error
    // prettier-ignore
    // eslint-disable-next-line
    (function(c,e,k,l,a){c[e]=c[e]||{};for(c[e].q=c[e].q||[];a<l.length;)k(l[a++],c[e])})(window,"extole",function(c,e){e[c]=e[c]||function(){e.q.push([c,arguments])}},["createZone"],0);

    window.extole?.createZone(options);
  },
  purchase: (order: Order, subtotal?: Money) => {
    const fullName = order.billingAddress
      ? NutsAddress.fromCt(order.billingAddress).name
      : undefined;

    const options: CreateZoneOptions = {
      name: 'conversion',
      data: {
        order_id: order.orderNumber,
        partner_user_id: order.customerId,
        email: order.customerEmail,
        cart_value: dollars(subtotal),
        first_name: fullName,
        products: order.lineItems
          .map((l) => l.variant.sku)
          .filter(isDefined)
          .join(','),
        coupon_code: order.discountCodes
          ?.filter((d) => d.state === 'MatchesCart')
          .map((d) => d.discountCode.obj?.code)
          .filter(isDefined)
          .join(','),
      },
    };

    Extole.createZone(options);
  },
};
