<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, PropType, ref } from 'vue';

interface IntersectionObserverOptions {
  root?: HTMLElement;
  rootMargin?: string;
  threshold?: number | number[];
}

export default defineComponent({
  name: 'WindowObserver',
  props: {
    options: { required: false, type: Object as PropType<IntersectionObserverOptions> },
  },
  setup(props, { emit }) {
    const observer = ref();
    const rootEl = ref<HTMLElement>();

    onMounted(() => {
      observer.value = new IntersectionObserver(([entry]) => {
        if (entry && entry.isIntersecting) {
          emit('intersect', entry);
        } else {
          emit('exit-view');
        }
      }, props.options ?? {});

      observer.value.observe(rootEl.value);
    });
    onBeforeUnmount(() => {
      observer.value.disconnect();
    });

    return { rootEl };
  },
});
</script>

<template>
  <div class="observer" ref="rootEl">
    <slot />
  </div>
</template>
