import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export type LoginType = 'apple' | 'facebook' | 'google' | 'magic link' | 'password login';

const SOCIAL_LOGIN_TYPES: LoginType[] = ['apple', 'facebook', 'google'];
const LOGIN_TYPES: LoginType[] = [...SOCIAL_LOGIN_TYPES, 'magic link', 'password login'];

function loginType(signedLoginType?: string): LoginType | undefined {
  if (!signedLoginType) return undefined;

  const extractedLoginType = signedLoginType.split('--')[0].toLowerCase() as LoginType;

  return LOGIN_TYPES.includes(extractedLoginType) ? extractedLoginType : undefined;
}

export interface Permissions {
  administrate?: true;
  auditFraud?: true;
  checkOutAsCustomer?: true;
  configureSite?: true;
  createOrderManualAdjustment?: true;
  editCatalog?: true;
  editPermissions?: true;
  employeeOrFormer?: true;
  grantCreditAccount?: true;
  login?: true;
  offsiteLogin?: true;
  print?: true;
  recordCreditAccountCredit?: true;
  report?: true;
  uploadNutritionFacts?: true;
}

export const useSession = defineStore('session', () => {
  const email = ref<string>();
  /** should only be preloaded during SSR */
  const newDySession = ref(true);
  const permissions = ref<Permissions>({});
  const signedLoginType = ref<string>();
  /** DateTime string (not ISO, lacks 'T' literal); e.g. '2019-08-22 14:01:55' */
  const userCreatedAt = ref<string>();
  const userFirstName = ref<string>();
  const userHasBusinessAccount = ref<boolean>();

  const isSignedLoginTypeSocialLogin = computed<boolean>(() => {
    const login = loginType(signedLoginType.value);
    if (!login) return false;

    return SOCIAL_LOGIN_TYPES.includes(login);
  });

  return {
    email,
    isSignedLoginTypeSocialLogin,
    newDySession,
    permissions,
    signedLoginType,
    userCreatedAt,
    userFirstName,
    userHasBusinessAccount,
  };
});
