<script lang="ts">
import { computed, HTMLAttributes, onMounted, onServerPrefetch } from 'vue';
import { useRoute } from 'vue-router';

import ProductRecommendations from '@/components/recommendations/ProductRecommendations.vue';
import { useDyChooseResults } from '@/composables/dynamic-yield/experiences';
import { Theme } from '@/composables/useButtonStyles';
import { SpacingDirection, spacingInputRegistration, SpacingLevel } from '@/composables/useSpacing';
import { RecommendationsChoice } from '@/lib/personalization/dynamicYield';
import { useCriticalData } from '@/stores/criticalData';
import { adaptLegacyBoolean, BuilderComponent, TWColorsText } from '@/utils/cms';
import {
  productSlotsFromChoice,
  title as recommendationsTitle,
} from '@/utils/dynamic-yield/recommendationsRow';

interface Props {
  analyticsList?: string;
  /** CMS hack -- Builder is likely to have fixed this via version [0.12.2](https://github.com/BuilderIO/builder/blob/main/packages/sdks/output/vue/CHANGELOG.md#0122)
   * but we're not there yet. This is at least a temporary requirement to assist with general
   * container styling and hydration. In practice we primarily care about a `class` property.
   */
  attributes?: HTMLAttributes;
  buttonTheme?: Theme;
  controlsColor?: string;
  enableAddToCart?: boolean | 'true' | 'false';
  flushRightOnMobile?: boolean;
  layout?: 'grid' | 'row';
  link?: string;
  prefetch?: boolean | 'true' | 'false';
  selector?: string;
  spacingDirection?: SpacingDirection;
  spacingLevel?: SpacingLevel;
  title?: string;
  subtitle?: string;
  trackingEventLocation?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const DyProductRecommendationsRowRegistration: BuilderComponent = {
  name: 'DY Product Recommendations',
  inputs: [
    {
      name: 'controlsColor',
      friendlyName: '(Optional) Controls Color',
      type: 'string',
      enum: Object.keys(TWColorsText),
      defaultValue: 'black',
    },
    {
      name: 'selector',
      friendlyName: 'Experience Selector',
      type: 'string',
      defaultValue: '[TEST] Default Recommendations',
    },
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Recommendations',
    },
    {
      name: 'subtitle',
      type: 'string',
      defaultValue: '(Optional) Recommendations Subtitle',
    },
    ...spacingInputRegistration,
    {
      name: 'link',
      friendlyName: 'View All link',
      type: 'string',
    },
    {
      name: 'enableAddToCart',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'prefetch',
      type: 'boolean',
      helperText:
        'Load prominent recommendations at the top of the page; otherwise loads after page is rendered',
    },
    {
      advanced: true,
      defaultValue: true,
      friendlyName: 'Mobile: flush with right',
      helperText: 'Scroll entries to be flush with the right side of mobile devices',
      name: 'flushRightOnMobile',
      type: 'boolean',
    },
    {
      name: 'analyticsList',
      friendlyName: 'Analytics: List Name',
      type: 'string',
      helperText:
        'Helps filter clicks/impressions in reporting; e.g. "GLP Seasonal Recommendations"',
      advanced: true,
    },
    {
      name: 'trackingEventLocation',
      friendlyName: 'Analytics: Page Type',
      type: 'string',
      helperText: 'Helps filter clicks/impressions in reporting; e.g. "PDP"',
      advanced: true,
    },
    {
      name: 'layout',
      friendlyName: 'Row Layout',
      type: 'string',
      defaultValue: 'row',
      enum: ['row', 'grid'],
      helperText: 'Product recommendations row can be a grid or single row',
    },
    {
      name: 'buttonTheme',
      friendlyName: '(Optional) Button Theme',
      type: 'string',
      defaultValue: 'green',
      enum: ['gray', 'green', 'red', 'transparent', 'white', 'yellow'],
    },
  ],
  noWrap: true,
};
</script>

<script setup lang="ts">
const props = withDefaults(defineProps<Props>(), {
  buttonTheme: 'green',
  flushRightOnMobile: true,
  layout: 'row',
});

interface CriticalData {
  choicesByName?: Record<string, RecommendationsChoice | undefined>;
}
const critical = useCriticalData<CriticalData>(props.selector ?? 'DyProductRecommendationsRow');
const { choicesByName, loadExperiences } = useDyChooseResults<RecommendationsChoice>(
  props.selector ?? '',
  useRoute(),
  { preloadedChoices: critical.data.choicesByName },
);
const choice = computed(() => choicesByName[props.selector ?? '']);
const list = computed(() => productSlotsFromChoice(choice.value));

const loadRecommendations = async () => {
  await loadExperiences({ skipLoadedExperiences: true });
};

const enableAddToCart = computed(() => adaptLegacyBoolean(props.enableAddToCart));

onMounted(async () => {
  await loadRecommendations();
  delete critical.data.choicesByName;
});
if (adaptLegacyBoolean(props.prefetch))
  onServerPrefetch(async () => {
    await loadRecommendations();
    critical.data.choicesByName = choicesByName;
  });
</script>

<template>
  <ProductRecommendations
    v-bind="attributes"
    :analyticsList="analyticsList"
    :buttonTheme="buttonTheme"
    clickTrackable
    :controlsColor="controlsColor"
    :decisionId="choice?.decisionId"
    :enableAddToCart="enableAddToCart"
    :flushRightOnMobile="flushRightOnMobile"
    impressionTrackable
    :layout="layout"
    :list="list"
    :link="link"
    :spacingDirection="spacingDirection"
    :spacingLevel="spacingLevel"
    :subtitle="subtitle"
    :title="recommendationsTitle(choice) || title"
    :trackingEventLocation="trackingEventLocation"
  />
</template>
