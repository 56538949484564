<script setup lang="ts">
import { money } from '@nuts/auto-delivery-sdk/dist/utils/format';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import LineItemImage from '@/components/cart/added-modal/LineItemImage.vue';
import LineItemPrice from '@/components/cart/added-modal/LineItemPrice.vue';
import QuantitySelector from '@/components/cart/QuantitySelector.vue';
import { useCart } from '@/composables/useCart';
import { getAutoDeliveryInterval, NutsLineItem } from '@/lib/cart/lineItem';
import { getMaxPiecesPerOrder, isGreetingCardLineItem } from '@/utils/cart';

const props = defineProps<{
  lineItemWithQuantitySubset: NutsLineItem;
  loadExpansionsPending: boolean;
  showProductName?: boolean;
}>();

const emit = defineEmits(['handle-close']);

const maximumPiecesPerOrder = computed<number | undefined>(() =>
  getMaxPiecesPerOrder(props.lineItemWithQuantitySubset),
);

const store = useStore();
const { preCheckoutLineItems, updateLineItem } = useCart(store);

const autoDeliveryIntervalWeeks = computed(() =>
  getAutoDeliveryInterval(props.lineItemWithQuantitySubset),
);

const quantityInCart = computed(() => {
  const lineItem = preCheckoutLineItems.value.find(
    (li) => li.id === props.lineItemWithQuantitySubset.id,
  );
  return lineItem?.quantity ?? props.lineItemWithQuantitySubset.quantity;
});

const currentQuantity = ref(props.lineItemWithQuantitySubset.quantity);

const quantity = computed({
  get: () => currentQuantity.value,
  set: (val) => {
    const difference = quantityInCart.value - currentQuantity.value;
    updateLineItem(
      props.lineItemWithQuantitySubset.id,
      {
        quantity: val + difference,
      },
      val,
    );
    currentQuantity.value = val;
  },
});
</script>

<template>
  <div
    class="inline-flex items-start w-full px-4 mt-4 md:items-center gap-x-3"
    data-test="inline-product"
  >
    <LineItemImage
      :isPending="loadExpansionsPending"
      :linksToPdp="!isGreetingCardLineItem(lineItemWithQuantitySubset)"
      :name="lineItemWithQuantitySubset.name"
      :productPath="lineItemWithQuantitySubset.productPath"
      :titleImage="lineItemWithQuantitySubset.titleImage?.small"
      @handle-close="emit('handle-close')"
    />
    <div class="md:hidden">
      <BaseBodyText class="font-semibold text-black">
        {{ lineItemWithQuantitySubset.name.en }}
      </BaseBodyText>
      <SmallBodyText class="mt-0.5 text-neutral-600">
        <template v-if="autoDeliveryIntervalWeeks">
          Auto-Delivery -
          <b class="font-semibold">{{ autoDeliveryIntervalWeeks }} Week Frequency</b>
        </template>
        <template v-else>
          {{ lineItemWithQuantitySubset.variant.variantName?.en }} -
          {{ money(lineItemWithQuantitySubset.piecePrice) }} each
        </template>
      </SmallBodyText>
      <div class="flex items-center mt-2">
        <QuantitySelector
          v-model="quantity"
          class="max-w-[6rem] min-w-[6rem]"
          hasBorderRadius
          :id="lineItemWithQuantitySubset.id"
          :maximumPiecesPerOrder
          data-promo="1"
          data-promo-creative="Add to cart modal"
          data-promo-name="Quantity selected"
          data-test="quantity-selector"
        />
        <LineItemPrice
          class="ml-3"
          :prices="lineItemWithQuantitySubset.variant.prices"
          :quantity="currentQuantity"
          :totalPriceBeforeCartLevelDiscount="
            lineItemWithQuantitySubset.totalPriceBeforeCartLevelDiscount
          "
          :totalSavings="lineItemWithQuantitySubset.totalSavings"
        />
      </div>
    </div>
    <BaseBodyText
      v-if="showProductName && !autoDeliveryIntervalWeeks"
      class="hidden w-56 font-semibold text-black md:visible md:block"
    >
      {{ lineItemWithQuantitySubset.name.en }}
    </BaseBodyText>
    <div
      class="hidden md:visible md:block"
      :class="autoDeliveryIntervalWeeks ? 'w-[5rem]' : 'w-[6rem]'"
    >
      <QuantitySelector
        v-model="quantity"
        hasBorderRadius
        :id="lineItemWithQuantitySubset.id"
        :maximumPiecesPerOrder
        data-test="quantity-selector"
      />
    </div>
    <div
      v-if="autoDeliveryIntervalWeeks"
      class="flex-col gap-y-0.5 hidden md:flex md:visible"
      data-test="inline-auto-delivery-frequency"
    >
      <BaseBodyText class="font-semibold">
        Auto-Delivery - {{ autoDeliveryIntervalWeeks }} Week Frequency
      </BaseBodyText>
      <SmallBodyText class="text-neutral-600">
        Frequency can be adjusted in the cart
      </SmallBodyText>
    </div>
    <LineItemPrice
      class="hidden ml-auto md:visible md:flex"
      :class="{ 'md:flex-col': autoDeliveryIntervalWeeks }"
      :prices="lineItemWithQuantitySubset.variant.prices"
      :quantity="currentQuantity"
      :totalPriceBeforeCartLevelDiscount="
        lineItemWithQuantitySubset.totalPriceBeforeCartLevelDiscount
      "
      :totalSavings="lineItemWithQuantitySubset.totalSavings"
    />
  </div>
</template>
