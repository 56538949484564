import { Image } from '@commercetools/platform-sdk';

import imageUtil from '@/utils/image';

type PartialImage = Pick<Image, 'url'>;

function proxyImageUrl(url: string) {
  if (url.includes('.commercetools.com/')) {
    return url.replace('://', '://nuts.com/images/ct/');
  }
  if (url.includes('.rackcdn.com/')) {
    return url.replace('://', '://nuts.com/images/rackcdn/');
  }
  return url;
}

const proxiedImageUtil = {
  getVariants(images: PartialImage[] = []) {
    if (!images) return images;
    return imageUtil.getVariants(
      images.map((image) => ({
        ...image,
        url: proxyImageUrl(image.url),
      })),
    );
  },
};

export default proxiedImageUtil;
