<script setup lang="ts">
import { computed } from 'vue';

const activeFilters = defineModel<string[]>({
  required: true,
});

const filters = computed(() =>
  [
    { displayName: 'Gluten-free', key: 'gluten-free' },
    { displayName: 'Kosher', key: 'kosher' },
    { displayName: 'Less Salt', key: 'less salt' },
    { displayName: 'Organic', key: 'organic' },
    { displayName: 'Raw', key: 'raw' },
    { displayName: 'Sugar-free', key: 'sugar-free' },
    { displayName: 'Vegan', key: 'vegan' },
  ].map((filter) => ({
    ...filter,
    active: activeFilters.value.includes(filter.key),
  })),
);

const toggleFilter = (key: string) => {
  if (activeFilters.value.includes(key)) {
    activeFilters.value = activeFilters.value.filter((activeFilter) => activeFilter !== key);
  } else {
    activeFilters.value.push(key);
  }
};
</script>

<template>
  <div class="mt-2 mb-3 ml-2 lg:ml-0" data-test="tag-filter-control-panel">
    <h3 class="mt-2 mb-1 text-lg lg:mb-2 lg:text-sm lg:font-normal font-proxima-nova">Filter By</h3>
    <div class="flex flex-wrap mb-5">
      <label
        v-for="filter in filters"
        :key="filter.key"
        class="tag-filter-control flex flex-wrap text-sm font-normal bg-white rounded-full border border-solid mr-1.5 mb-1.5 py-2 px-2.5 cursor-pointer relative"
        :class="filter.active ? 'active border-black' : 'border-nuts-neutral-400'"
        :data-test="filter.key"
        @click.prevent="toggleFilter(filter.key)"
      >
        {{ filter.displayName }}
        <input type="checkbox" :id="`tag-filter-${filter.key}`" class="sr-only" />
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
h3 {
  color: $dark-grey;
  font-family: $font-family-proxima;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33;
  margin-bottom: 5px;

  @include respond-max($screen-sm-max) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
</style>
