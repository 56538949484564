<script setup lang="ts">
import { useRouter } from 'vue-router';

import ThemedButton from '@/components/base/ThemedButton.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { Size, Theme } from '@/composables/useButtonStyles';

const props = defineProps<{
  disabled?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  outOfStock?: boolean;
  path?: string;
  requiresCustomization?: boolean;
  size?: Size;
  theme?: Theme;
}>();

const emit = defineEmits<{
  'add-to-cart': [];
  'customization-started': [];
}>();

const router = useRouter();
const { navigateTo } = useRouteChange(router);

async function handleCustomizationClick() {
  emit('customization-started');
  if (props.path) await navigateTo(props.path);
}
</script>

<template>
  <ThemedButton v-if="outOfStock" disabled :fullWidth :size> Sold Out </ThemedButton>
  <ThemedButton
    v-else-if="requiresCustomization"
    :fullWidth
    :size
    :theme
    @click="handleCustomizationClick"
  >
    Customize
  </ThemedButton>
  <ThemedButton
    v-else
    data-test="add-to-cart-button"
    :disabled
    :fullWidth
    :isLoading
    :size
    :theme
    @click="$emit('add-to-cart')"
  >
    <slot name="add-to-cart-text">Add to Cart</slot>
  </ThemedButton>
</template>
