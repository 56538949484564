import { Cart } from '@commercetools/platform-sdk';

export interface CustomProductResponse {
  cart: Cart;
}

// eslint-disable-next-line no-shadow
export enum CustomProductType {
  Tray = 'Tray',
  Box = 'Box',
}

export interface DigitalGifting {
  giftCertificateCode: string | undefined;
  digitalGiftSku: string | undefined;
  giftedProduct: {
    sku: string | undefined;
    name: string | undefined;
    tier: 'classic' | 'premium';
  };
}

export interface Ingredient {
  readonly assetPath: string;
  readonly filters: string[];
  readonly id: string;
  readonly sku: string;
  readonly name: string;
  readonly path: string;
  readonly popularity: number;
  readonly price: number;
  readonly priceRange: '$' | '$$' | '$$$';
}

export interface TrayIngredient {
  id: string;
  name: string;
  premium: boolean;
  assetPath: string;
  filters: string[];
  popularity: number;
  priceLi: number;
  priceLo: number;
  priceMi: number;
  priceMo: number;
  priceRange: string;
}
