import { NutsProduct, NutsProductVariant } from '@/utils/product';

export const GIFT_VARIANTS = [
  { variation: 236, amount: 10 },
  { variation: 5, amount: 25 },
  { variation: 155, amount: 35 },
  { variation: 156, amount: 40 },
  { variation: 6, amount: 50 },
  { variation: 7, amount: 75 },
  { variation: 8, amount: 100 },
  { variation: 9, amount: 200 },
] as const;

export function isGift(item: NutsProduct) {
  return item.ancestors.some((ancestor) => ancestor.name.includes('Gift'));
}

export function isGiftCertificate(item: NutsProductVariant | NutsProductVariant[] | NutsProduct) {
  if ('variantGroups' in item) {
    return item.variantGroups[0]?.customProduct === 'Gift Certificate';
  }
  if (Array.isArray(item)) {
    return item[0]?.customProduct === 'Gift Certificate';
  }
  return item?.customProduct === 'Gift Certificate';
}
