import { defineStore } from 'pinia';
import { ref } from 'vue';

export interface GeoIp {
  /** two letter country code */
  country?: string;
  latitude?: number;
  longitude?: number;
  postalCode?: string;
  /** two letter state/prov code */
  state?: string;
}

export interface InitialRequestContext {
  ip: string;
  referer?: string;
  /** The full URL string that the user requested, including protocol and host */
  url: string;
  userAgent?: string;
}

export const useInitialRequest = defineStore('initialRequest', () => {
  const geoIp = ref<GeoIp>({
    country: undefined,
    latitude: undefined,
    longitude: undefined,
    postalCode: undefined,
    state: undefined,
  });
  const ip = ref('');
  const referer = ref<string>();
  const url = ref('https://nuts.com/');
  const userAgent = ref<string>();

  return {
    geoIp,
    ip,
    referer,
    url,
    userAgent,

    init: (initialRequest: InitialRequestContext & { geoIp?: GeoIp }) => {
      if (initialRequest.geoIp) geoIp.value = initialRequest.geoIp;
      ip.value = initialRequest.ip;
      referer.value = initialRequest.referer;
      url.value = initialRequest.url;
      userAgent.value = initialRequest.userAgent;
    },
  };
});
