import 'tailwindcss/tailwind.css';

import VueAnnouncer from '@vue-a11y/announcer';
import { createHead } from '@vueuse/head';
import createDebug from 'debug';
import { createPinia, Pinia } from 'pinia';
import { createSSRApp } from 'vue';

import App from '@/app/App.vue';
import { fromFlagSpec, useFeatureFlags } from '@/composables/useFeatureFlags';
import headMixin from '@/mixins/head';
import { routesLockedByFeatureFlag } from '@/router/conditionalRoutes';
import { createRouter, RouteSpec } from '@/router/createRouter';
import createStore from '@/store/createStore';
import { CustomerState, useCustomer } from '@/stores/customer';
import {
  FeatureFlags,
  FlagsByLayer,
  layers,
  useFeatureFlags as useFeatureFlagsStore,
} from '@/stores/featureFlags';
import { GeoIp, InitialRequestContext, useInitialRequest } from '@/stores/initialRequest';
import { NotificationsByType, useNotifications } from '@/stores/notifications';
import { Permissions, useSession } from '@/stores/session';

const debug = createDebug('nuts:createApp');

export interface PreloadPiniaStates {
  customer?: Partial<CustomerState>;
  featureFlags?: { flagsByLayer: FlagsByLayer };
  initialRequest: InitialRequestContext & { geoIp?: GeoIp };
  notifications?: Partial<NotificationsByType>;
  session?: {
    email?: string;
    permissions: Permissions;
    signedLoginType?: string;
    /** DateTime string (not ISO, lacks 'T' literal); e.g. '2019-08-22 14:01:55' */
    userCreatedAt?: string;
    userFirstName?: string;
    userHasBusinessAccount?: boolean;
  };
}

export interface SpaAppContext {
  initialRoute?: RouteSpec;
  initialState?: Parameters<ReturnType<typeof createStore>['replaceState']>[0];
  piniaState?: Pinia['state']['value'];
  preloadPiniaState?: PreloadPiniaStates;
  preloadStore?: Parameters<typeof createStore>[1];
  props?: {
    backendEnv: string | null;
    fallBackLogoPath?: string;
    isAdmin: boolean;
    logoPath?: string;
  };
  sharedState?: Parameters<typeof createStore>[0];
}

export function defineAndPreloadPinia(preloadPiniaState?: PreloadPiniaStates) {
  const pinia = createPinia();
  if (preloadPiniaState) {
    debug('preloading pinia state: %o', preloadPiniaState);
    if (preloadPiniaState.customer) {
      useCustomer(pinia).$patch(preloadPiniaState.customer);
    }
    if (preloadPiniaState.featureFlags) {
      const { flagsByLayer } = preloadPiniaState.featureFlags;
      useFeatureFlagsStore(pinia).$patch({
        flags: layers.reduceRight<FeatureFlags>(
          (acc, layer) => ({ ...acc, ...flagsByLayer[layer] }),
          {},
        ),
        flagsByLayer,
      });
    }
    if (preloadPiniaState.initialRequest) {
      useInitialRequest(pinia).init(preloadPiniaState.initialRequest);
    }
    if (preloadPiniaState.notifications) {
      useNotifications(pinia).addNotifications(preloadPiniaState.notifications);
    }
    if (preloadPiniaState.session) {
      useSession(pinia).$patch(preloadPiniaState.session);
    }
  }
  return pinia;
}

// Expose a factory function that creates a fresh set of store, router,
// app instances on each call (which is called for each SSR request)
export default (context: SpaAppContext = {}) => {
  debug('creating SPA app');
  const {
    initialRoute,
    initialState,
    piniaState,
    preloadPiniaState,
    preloadStore = {},
    props = {},
    sharedState,
  } = context;

  const store = createStore(sharedState, preloadStore);
  if (initialState) {
    store.replaceState(initialState);
  }

  const trustInitialRoute = !routesLockedByFeatureFlag.some((r) => r.path === initialRoute?.path);
  const router = createRouter((trustInitialRoute && initialRoute) || undefined);

  const head = createHead();

  const pinia = defineAndPreloadPinia(preloadPiniaState);
  if (piniaState) {
    pinia.state.value = piniaState;
  }

  // create the app instance.
  // here we inject the router, store and ssr context to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  // @ts-ignore -- (JS unit tests are failing on this line; intend to port conditionalRoutes logic and remove the tests anyway)
  const app = createSSRApp(App, props)
    .mixin(headMixin)
    .use(VueAnnouncer, { router })
    .use(head)
    .use(pinia)
    .use(store);

  const { flags } = useFeatureFlags();
  routesLockedByFeatureFlag
    .filter((r) => {
      const routeFlags = fromFlagSpec(r.meta.flagCondition);
      return Object.entries(routeFlags).every(([key, value]) => (value ? flags[key] : !flags[key]));
    })
    .forEach((r) => router.addRoute(r));

  app.use(router);

  app.config.performance = app.config.performance || (flags.vuePerformanceTiming ?? false);

  // expose the app, the router and the store.
  // note we are not mounting the app here, since bootstrapping will be
  // different depending on whether we are in a browser or on the server.
  return {
    app,
    head,
    pinia,
    router,
    store,
  };
};
