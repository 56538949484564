<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { SearchResponse } from 'instantsearch.js';
import { computed } from 'vue';

import { AlgoliaSuggestedSearch } from '@/api/algolia';
import ArrowIcon from '@/components/base/assets/ArrowIcon.vue';
import ClockTimeArrowIcon from '@/components/base/assets/ClockTimeArrowIcon.vue';
import SearchIcon from '@/components/base/assets/SearchIcon.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import HighlightSearch from '@/components/layout/header/search/HighlightSearch.vue';
import SearchItem from '@/components/layout/header/search/SearchItem.vue';

const props = defineProps<{
  query: string;
  recentSearches: string[];
  suggestions: SearchResponse<AlgoliaSuggestedSearch>['hits'];
}>();

const emit = defineEmits<{
  click: [string];
  'update:recentSearches': [string[]];
}>();

const recentSearches = useVModel(props, 'recentSearches', emit);
const matchingRecentSearches = computed(() =>
  recentSearches.value.filter((search) =>
    search.toLocaleLowerCase().includes(props.query.toLocaleLowerCase()),
  ),
);

const formatSuggestionHitHighlight = (
  suggestion: SearchResponse<AlgoliaSuggestedSearch>['hits'][number],
) => {
  const { _highlightResult } = suggestion;
  if (!_highlightResult?.query) return suggestion.query;
  return _highlightResult.query.value.replaceAll('<span>', '<b>').replaceAll('</span>', '</b>');
};

const removeRecentSearch = (entry: string) => {
  recentSearches.value = recentSearches.value.filter((search) => search !== entry);
};
</script>

<template>
  <div>
    <SmallBodyText class="px-4 text-neutral-500">Search Suggestions</SmallBodyText>
    <ul class="mt-1">
      <SearchItem
        v-for="entry in matchingRecentSearches"
        :key="entry"
        removable
        @click="emit('click', entry)"
        @remove="removeRecentSearch(entry)"
      >
        <template #left><ClockTimeArrowIcon :size="20" /></template>
        <HighlightSearch :entry="entry" :query="query" />
      </SearchItem>
      <SearchItem v-if="suggestions.length === 0" @click="emit('click', query)">
        <template #left><SearchIcon :size="20" /></template>
        {{ query }}
        <template #right>
          <ArrowIcon direction="top-left" class="text-nuts-neutral-400 m-0.5" :size="20" />
        </template>
      </SearchItem>
      <SearchItem
        v-for="entry in suggestions"
        :key="entry.objectID"
        @click="emit('click', entry.query)"
      >
        <template #left><SearchIcon :size="20" /></template>
        <span v-html="formatSuggestionHitHighlight(entry)" />
        <template #right>
          <ArrowIcon direction="top-left" class="text-nuts-neutral-400 m-0.5" :size="20" />
        </template>
      </SearchItem>
    </ul>
  </div>
</template>
