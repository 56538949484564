<script lang="ts">
import { defineComponent } from 'vue';

import { CmsRegisteredComponent } from '@/utils/cms';

const LargeBodyText = defineComponent({
  name: 'LargeBodyText',
  props: {
    underline: { required: false, type: Boolean, default: false },
  },
});

export const LargeBodyTextRegistration: CmsRegisteredComponent = {
  component: LargeBodyText,
  name: 'Large Body Text',
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Large Body text here' } },
    },
  ],
  inputs: [
    {
      name: 'underline',
      type: 'boolean',
      defaultValue: false,
    },
  ],
  defaultStyles: {
    marginTop: '0px',
  },
};

export default LargeBodyText;
</script>

<template>
  <p
    class="text-base font-semibold large-text lg:text-lg lg:leading-6"
    :class="{ underline: underline }"
  >
    <slot />
  </p>
</template>

<style scoped lang="scss">
.large-text {
  @apply mb-0;
}
</style>
