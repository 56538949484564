import { NutsJson, webstore } from '@/api';

export interface GiftedTrayIngredient {
  readonly id: string;
  readonly name: string;
  readonly popularity: number;
  readonly assetPath: string;
  readonly priceMi: number;
  readonly priceMo: number;
  readonly priceLi: number;
  readonly priceLo: number;
  readonly filters: string[];
  readonly priceRange: string;
  readonly premium: boolean;
}

export interface Category {
  readonly name: string;
  readonly filter: string;
  readonly children: Category[];
}

export interface GiftedTrayCatalogResponse {
  readonly ingredients: GiftedTrayIngredient[];
  readonly categoryTree: Category[];
}

export async function catalog() {
  const { data } = await webstore.get<NutsJson<GiftedTrayCatalogResponse>>(
    '/api/gifted-trays/catalog',
  );
  return data;
}

export default {};
