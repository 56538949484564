<script setup lang="ts">
import DefaultAddressPopper from '@/components/base/DefaultAddressPopper.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import { useFeatureFlags } from '@/composables/useFeatureFlags';

defineProps<{
  active: boolean;
  dataTest: string;
  href: string;
  text: string;
}>();

const arrowDownIcon = nutshell['img/arrow-down.svg'];

const { flags } = useFeatureFlags();
</script>

<template>
  <li
    class="h-10 pl-6 pr-4 border-t border-solid border-pearl"
    :class="active ? 'bg-white' : 'bg-old-lace'"
  >
    <DefaultAddressPopper
      v-if="flags.supportDefaultAddress && href === '/account/address-book'"
      class="w-full popper"
      location="account"
      v-slot="popperProps"
    >
      <RouteLink
        class="flex items-center justify-between h-full mt-2 link"
        :data-test="dataTest"
        :to="href"
        @click="popperProps?.dismiss()"
      >
        <span class="pr-4 text-base ws-lg:text-base" :class="{ 'font-semibold': active }">
          {{ text }}
        </span>
        <img class="w-2.5 transform -rotate-90" :src="arrowDownIcon" alt="arrow right" />
      </RouteLink>
    </DefaultAddressPopper>
    <RouteLink
      v-else
      class="flex items-center justify-between h-full link"
      :data-test="dataTest"
      :to="href"
    >
      <span class="pr-4 text-base ws-lg:text-base" :class="{ 'font-semibold': active }">
        {{ text }}
      </span>
      <img class="w-2.5 transform -rotate-90" :src="arrowDownIcon" alt="arrow right" />
    </RouteLink>
  </li>
</template>

<style lang="scss" scoped>
.link {
  @apply text-black;
}

.popper {
  border: 0px !important;
  margin: 0px !important;
}
</style>
