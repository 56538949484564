<script setup lang="ts">
import { computed } from 'vue';

import BaseIcon from '@/components/base/BaseIcon.vue';
import type { IconSize } from '@/utils/svg';

const props = withDefaults(
  defineProps<{
    size?: IconSize | [IconSize, IconSize];
    direction?: 'up' | 'down' | 'left' | 'right';
  }>(),
  {
    size: 24 as IconSize,
    direction: 'down',
  },
);

const directionClass = computed(() => {
  switch (props.direction) {
    case 'up':
      return 'transform rotate-180';
    case 'down':
      return 'transform rotate-0';
    case 'left':
      return 'transform rotate-90';
    case 'right':
      return 'transform -rotate-90';
    default:
      return '';
  }
});
</script>

<template>
  <BaseIcon :size="size" viewBox="0 0 24 24" fill="none" :class="directionClass">
    <path
      d="M6 9L12 15L18 9"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </BaseIcon>
</template>
