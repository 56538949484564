import endsWith from 'lodash/endsWith';

/**
 * ```car => cars, Address => Addresses, box => boxes```
 *
 * yes this is a hack and should carefully be made smarter or something;
 * maybe borrow from https://github.com/blakeembrey/pluralize
 * */
export function pluralize(word: string) {
  let pluralizedWord = word;
  if (/(?<![aeiou])y$/.test(word)) {
    pluralizedWord = `${word.substring(0, word.length - 1)}ies`;
  } else if (
    endsWith(word, 'ch') ||
    endsWith(word, 's') ||
    endsWith(word, 'sh') ||
    endsWith(word, 'x')
  ) {
    pluralizedWord += 'es';
  } else {
    pluralizedWord += 's';
  }
  return pluralizedWord;
}

export function pluralizeBy<T>(term: string, quantifier: T[] | number): string {
  return (typeof quantifier === 'number' ? quantifier : quantifier.length) === 1
    ? term
    : pluralize(term);
}
