<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { TypographyTags } from '@/utils/accessibility';
import { CmsRegisteredComponent } from '@/utils/cms';

const Header3 = defineComponent({
  name: 'Header3',
  props: {
    headerTag: { required: false, type: String as PropType<TypographyTags>, default: 'h3' },
  },
});

export const Header3Registration: CmsRegisteredComponent = {
  component: Header3,
  name: 'Header3',
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Header 3 text here' } },
    },
  ],
  defaultStyles: {
    marginTop: '0px',
  },
};

export default Header3;
</script>

<template>
  <component
    :is="headerTag"
    class="text-2xl font-semibold leading-7 header-3 font-gelica lg:leading-9"
  >
    <slot />
  </component>
</template>

<style scoped lang="scss">
.header-3 {
  @apply mb-0;
  @media (min-width: 1024px) {
    font-size: 32px;
  }
}
</style>
