import {
  BuilderContent,
  fetchOneEntry,
  getBuilderSearchParams,
  isEditing,
} from '@builder.io/sdk-vue';
import { MaybeRefOrGetter } from '@vueuse/core';
import camelCase from 'lodash/camelCase';
import { defineStore, storeToRefs } from 'pinia';
import {
  computed,
  onBeforeMount,
  onMounted,
  onServerPrefetch,
  onUpdated,
  ref,
  toValue,
  UnwrapRef,
  watchEffect,
} from 'vue';
import { LocationQuery, useRoute } from 'vue-router';

import { getConfigEntry } from '@/api/config';
import { useCurrentRoute } from '@/composables/navigation/useCurrentRoute';
import { useCallback } from '@/composables/useCallback';
import { CONTACT_EMAIL_COOKIE, CUSTOMER_ORDER_COUNT_COOKIE } from '@/lib/personalization/common';
import { useCustomer } from '@/stores/customer';
import { useInitialRequest } from '@/stores/initialRequest';
import { useSession } from '@/stores/session';
import { sendExperimentViewedEvent } from '@/utils/analytics/experimentViewedEvent';
import {
  contentHasVariations,
  getContentVariationInfoFromCookie,
  normalizeQueryParam,
} from '@/utils/cms';
import { getCookie, setCookie } from '@/utils/isomorphic/cookie';
import { retry } from '@/utils/retry';

export type ContentModel =
  | 'account-banner'
  | 'category-top-shelf'
  | 'cart-discount-message'
  | 'cross-sell-section'
  | 'customizer-recommendations'
  | 'faq'
  | 'global-campaign-carousel'
  | 'header-announcement-bar'
  | 'header-logo'
  | 'mega-menu'
  | 'mobile-menu'
  | 'page'
  | 'promotional-messaging-bar'
  | 'referral-page'
  | 'site-navigation'
  | 'sitewide-banner'
  | 'symbol';

export function isEditingSSR(query: LocationQuery | URLSearchParams) {
  if (query.constructor === URLSearchParams) {
    return query.has('builder.frameEditing');
  }
  return 'builder.frameEditing' in query;
}

type ContentWithTestInfo = Required<Pick<BuilderContent, 'id' | 'testVariationId'>> &
  Pick<BuilderContent, 'name' | 'testVariationName'>;

export const trackedContentIds = new Set<string>();
function trackContentAttribution(content: ContentWithTestInfo) {
  trackedContentIds.add(content.id);
  sendExperimentViewedEvent(
    {
      experiment_id: content.id,
      experiment_name: content.name,
      variation_id: content.testVariationId,
      variation_name: content.testVariationName,
    },
    'Builder.io',
  );
}

interface Options {
  contentVisible?: MaybeRefOrGetter<boolean>;
  loadOnMount?: boolean;
  prefetch?: 'criticalData' | 'setup';
}

export const useCms = <T extends BuilderContent = BuilderContent>(
  model: ContentModel,
  options?: Options,
) => {
  const route = useRoute();

  const customerStore = useCustomer();
  const { url } = storeToRefs(useInitialRequest());
  const sessionStore = useSession();

  const { path, query } = useCurrentRoute(url.value, route);
  const initialRequestUtmParams = computed<{
    utmCampaign?: string;
    utmContent?: string;
    utmMedium?: string;
    utmSource?: string;
    utmTerm?: string;
  }>(() => {
    const { searchParams } = new URL(url.value);
    return Array.from(searchParams).reduce((utmValues, [param, value]) => {
      if (!param.startsWith('utm')) return utmValues;
      return {
        ...utmValues,
        [camelCase(param)]: value,
      };
    }, {});
  });

  const timeoutMs: number = import.meta.env.SSR
    ? Number(import.meta.env.VITE_DY_SERVER_SIDE_TIMEOUT)
    : Number(import.meta.env.VITE_DY_CLIENT_SIDE_TIMEOUT);

  const fetchCmsContent = useCallback(
    (
      urlPath: string,
      urlQuery?: LocationQuery | URLSearchParams,
    ): Promise<UnwrapRef<T> | null | undefined> => {
      const includeUnpublished = normalizeQueryParam(urlQuery, 'includeUnpublished');
      // @ts-expect-error (`BuilderContent` is not assignable to `T` but it should be)
      return fetchOneEntry({
        apiKey: getConfigEntry('builderIo').key,
        fetchOptions: {
          signal: AbortSignal.timeout(timeoutMs),
        },
        model,
        options: {
          ...getBuilderSearchParams(urlQuery as URLSearchParams),
          includeUnpublished: includeUnpublished.split(',').includes(model),
        },
        userAttributes: {
          ...initialRequestUtmParams.value,
          businessIndustry: customerStore.businessIndustry,
          hasBusinessAccount: customerStore.hasBusinessAccount,
          hasBusinessIndustry: !!customerStore.businessIndustry,
          isB2BContact: customerStore.contact?.isB2b ?? false,
          isContact: !!getCookie(CONTACT_EMAIL_COOKIE, false),
          isCustomer: !!getCookie(CUSTOMER_ORDER_COUNT_COOKIE),
          newSession: sessionStore.newDySession,
          pageType: route?.meta.dyPageType ?? 'OTHER',
          urlPath,
          userHasBusinessAccount: sessionStore.userHasBusinessAccount ?? false,
        },
      });
    },
  );

  const cmsStore = defineStore(`cms:${model}`, {
    state: () => ({
      fetchCmsContent,
      prefetchedContent: undefined as T | null | undefined,
      prefetchedContentTestDecision: undefined as string | undefined,
    }),
  })();

  // can be lost in hydration
  if (!('execute' in cmsStore.fetchCmsContent)) {
    // @ts-expect-error (TS thinks there's another UnwrapRef layer)
    cmsStore.fetchCmsContent = fetchCmsContent;
  }

  const content = ref<T | UnwrapRef<T> | null | undefined>(cmsStore.prefetchedContent);

  const contentLoadedEvent = computed<CustomEvent<typeof route | URL>>(
    () =>
      new CustomEvent(`onContentLoaded:${model}`, {
        detail: route ?? new URL(url.value),
      }),
  );

  const loading = ref(!!options?.loadOnMount);

  const loadCmsContent = async (
    urlPath: string = path.value,
    urlQuery: LocationQuery | URLSearchParams = query.value,
  ) => {
    loading.value = true;
    content.value = await cmsStore.fetchCmsContent.execute(urlPath, urlQuery);
    loading.value = false;
    cmsStore.fetchCmsContent.result = undefined; // unset to avoid stale values
    if (options?.prefetch) {
      cmsStore.prefetchedContent = content.value;
    }
  };

  if (options?.prefetch === 'setup') {
    onServerPrefetch(async () => {
      if (isEditingSSR(query.value)) return; // do not SSR for visual editor; currently crashes during hydration
      if (content.value || content.value === null) return; // already set by `criticalData()`
      await loadCmsContent();
    });

    onBeforeMount(() => {
      let hydratingPrefetchedContent = path.value === content.value?.data?.url;
      if (!hydratingPrefetchedContent && path.value.endsWith('/')) {
        hydratingPrefetchedContent = path.value.slice(0, -1) === content.value?.data?.url;
      }

      if (cmsStore.prefetchedContentTestDecision) {
        const cookieKey = `builder.tests.${content.value?.id}`;
        if (!getCookie(cookieKey, false)) {
          setCookie(cookieKey, cmsStore.prefetchedContentTestDecision);
        }
        hydratingPrefetchedContent = true;
        cmsStore.prefetchedContentTestDecision = undefined;
      }

      cmsStore.prefetchedContent = undefined;

      watchEffect(() => {
        if (cmsStore.prefetchedContent?.data?.url !== content.value?.data?.url) {
          if (hydratingPrefetchedContent) return;
          content.value = cmsStore.prefetchedContent;
        }
      });
      hydratingPrefetchedContent = false;
    });

    const raiseEvent = () => document.dispatchEvent(contentLoadedEvent.value);
    onMounted(raiseEvent);
    onUpdated(raiseEvent);
  }

  if (options?.loadOnMount ?? options?.prefetch === 'setup') {
    onMounted(async () => {
      if (content.value === undefined || isEditing()) {
        await loadCmsContent();
      }
    });
  }

  if (options?.contentVisible) {
    // eslint-disable-next-line no-promise-executor-return
    const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));
    onMounted(() => {
      // TBD: abandon analytics if content is set to 0% or 100% audience?
      watchEffect(async () => {
        const currentContent = toValue(content);
        const contentVisible = toValue(options.contentVisible);
        if (!currentContent?.id || !contentHasVariations(currentContent)) return;
        if (!contentVisible || trackedContentIds.has(currentContent.id)) return;
        const { id, name } = currentContent;
        try {
          await retry(
            () =>
              trackContentAttribution({
                ...getContentVariationInfoFromCookie(currentContent),
                id,
                name,
              }),
            {
              onError: async () => {
                await delay(100);
                return true;
              },
            },
          );
        } catch (error) {
          console.error(error);
        }
      });
    });
  }

  watchEffect(() => {
    if (cmsStore.fetchCmsContent.error) {
      cmsStore.fetchCmsContent.result = undefined;
      content.value = undefined;
    }
  });

  return {
    commitTestDecision: (testVariationId: string) => {
      cmsStore.prefetchedContentTestDecision = testVariationId;
    },
    content: computed(() => ({
      error: cmsStore.fetchCmsContent.error,
      loading: loading.value,
      result: content.value,
    })),
    loadCmsContent,
  };
};
