import axios from 'axios';

import type {
  AvsIncomplete,
  AvsInteractionRequired,
  AvsMultiple,
  AvsNone,
  AvsOperation,
  AvsStatus,
  AvsSuggestion,
  AvsVerified,
} from '@/api/address/verification';
import { CountryCodes, NutsAddress } from '@/utils/address';

export interface ExperianAddressSuggestion {
  format: string;
  // eslint-disable-next-line camelcase
  global_address_key: string;
  matched?: [number, number][];
  text: string;
}

export interface ExperianAddressFormat {
  // eslint-disable-next-line camelcase
  address_line_1: string;
  // eslint-disable-next-line camelcase
  address_line_2: string | null;
  // eslint-disable-next-line camelcase
  address_line_3: string | null;
  country: string;
  locality: string;
  // eslint-disable-next-line camelcase
  postal_code: string;
  region: string;
}

export interface ExperianFormatResponse {
  metadata?: {
    // eslint-disable-next-line camelcase
    address_classification: {
      // eslint-disable-next-line camelcase
      delivery_type?: 'business' | 'residential';
    };
  };
  result: {
    address: ExperianAddressFormat;
  };
}

export interface ExperianSearchResponse {
  result: {
    confidence: 'Verified match' | 'Multiple matches' | 'No matches' | 'Insufficient search terms';
    // eslint-disable-next-line camelcase
    more_results_available: boolean;
    suggestions?: ExperianAddressSuggestion[];
  };
}

const headers: { [key: string]: boolean | string } = {
  'Auth-Token': import.meta.env.VITE_EXPERIAN_TOKEN as string,
};
const host = 'https://api.experianaperture.io';

export async function getFormattedAddress(url: string): Promise<ExperianFormatResponse> {
  const config = { headers: { ...headers } };
  config.headers['Add-Metadata'] = true;
  const { data } = await axios.get<ExperianFormatResponse>(url, config);
  return data;
}

function parseQasXml(
  xml: Document,
): AvsIncomplete | AvsInteractionRequired | AvsMultiple | AvsNone | AvsVerified {
  const verificationLevel = xml.querySelector('verifylevel')?.textContent ?? 'None';
  if (['InteractionRequired', 'Verified'].includes(verificationLevel)) {
    let address: NutsAddress = { country: 'US' };
    const addressFields = ['street1', 'street2', 'city', 'state', 'postalCode', 'country'];
    Array.from(xml.querySelectorAll('address line') ?? []).forEach((fieldNode, index) => {
      const field = addressFields[index];
      let value = fieldNode.textContent;
      if (field === 'country') {
        if (value === 'Canada') value = 'CA';
        if (value?.length === 3) value = CountryCodes.fromAlpha3(value);
      }
      address = {
        ...address,
        [field]: value,
      };
    });
    const status = verificationLevel === 'Verified' ? 'verified' : 'interactionRequired';
    return { status, address };
  }

  if (['Multiple', 'PremisesPartial', 'StreetPartial'].includes(verificationLevel)) {
    let status: AvsStatus = 'multipleMatches';
    if (verificationLevel === 'PremisesPartial') status = 'requireUnitNumber';
    if (verificationLevel === 'StreetPartial') status = 'requireBuildingNumber';

    const suggestionList = xml.querySelectorAll('picklist picklistitem');
    let suggestions: AvsSuggestion[] | undefined;
    if (suggestionList.length) {
      suggestions = [];
      Array.from(suggestionList).forEach((itemNode) => {
        const key = itemNode.querySelector('moniker')!.textContent!;
        const text = itemNode.querySelector('partialtext')!.textContent!;
        const postalCode = itemNode.querySelector('postcode')!.textContent!;
        const type =
          itemNode.querySelector('fulladdress')?.textContent === 'True' ? 'expand' : 'refine';
        suggestions!.push({
          key,
          postalCode,
          text,
          type,
        });
      });
      return <AvsIncomplete | AvsMultiple>{ suggestions, status };
    }
    return <AvsIncomplete>{ status };
  }

  return { status: 'noMatches' };
}

type QasAction = 'GetFormattedAddress' | 'refine' | 'search';

async function queryQas(action: QasAction, countryCode: string, term: string) {
  let layout = 'USA2 TitleCase Retention';
  if (countryCode === 'CA') {
    layout = 'USACAN2 TitleCase Retention';
  }

  const formData = new FormData();
  formData.append('action', action);
  formData.append('addlayout', layout);
  formData.append('country', CountryCodes.fromAlpha2(countryCode) ?? 'USA');
  if (action === 'search') {
    formData.append('searchstring', term);
  } else {
    formData.append('moniker', term);
    if (action === 'refine') formData.append('refinetext', '');
  }

  const { data } = await axios.post<string>('/qas_proxy.php', formData);
  const parser = new DOMParser();
  const xml = parser.parseFromString(data, 'text/xml');
  return parseQasXml(xml);
}

export async function search(
  address: NutsAddress,
  refineKey?: string,
  action?: AvsOperation,
): Promise<AvsIncomplete | AvsInteractionRequired | AvsMultiple | AvsNone | AvsVerified>;
export async function search(
  addressFragment: string,
  countryCode: string,
  coordinates?: string,
): Promise<ExperianSearchResponse['result']>;
export async function search(
  addressOrFragment: NutsAddress | string,
  countryCodeOrRefineKey?: string,
  coordinatesOrAction?: QasAction | string,
) {
  if (typeof addressOrFragment === 'string') {
    const addressFragment = addressOrFragment;
    const coordinates = coordinatesOrAction;
    const config = { headers };
    const data = {
      country_iso: CountryCodes.fromAlpha2(countryCodeOrRefineKey!),
      components: { unspecified: [addressFragment] },
      location: coordinates,
    };
    const {
      data: { result },
    } = await axios.post<ExperianSearchResponse>(`${host}/address/search/v1`, data, config);
    return result;
  }
  const action = <AvsOperation>coordinatesOrAction;
  const address = addressOrFragment;
  const refineKey = countryCodeOrRefineKey;
  const term =
    refineKey ||
    [
      // QAS sees `street2` as part of `street1`
      [address.street1, address.street2].filter(Boolean).join(' '),
      '', // empty `street2` placeholder still necessary to avoid false-positive on postal codes
      address.city,
      address.state,
      address.postalCode,
    ].join('|');
  let qasAction: QasAction = 'search';
  if (action) {
    qasAction = action === 'expand' ? 'GetFormattedAddress' : action;
  }
  let avsResult = await queryQas(qasAction, address.country, term);
  if (avsResult.status === 'multipleMatches' && avsResult.suggestions[0].type === 'expand') {
    avsResult = await queryQas(
      'GetFormattedAddress',
      address.country,
      avsResult.suggestions[0].key,
    );
    if (avsResult.status === 'verified') {
      return {
        ...avsResult,
        status: 'interactionRequired',
      };
    }
  }
  return avsResult;
}
