<script setup lang="ts">
import { CallbackTypes, GoogleLogin } from 'vue3-google-login';
import { useStore } from 'vuex';

import { getConfigEntry } from '@/api/config';
import ThemedButton from '@/components/base/ThemedButton.vue';
import { useAuth } from '@/composables/useAuth';
import { useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';

const props = defineProps<{
  fullWidth?: boolean;
  isCheckout?: boolean;
}>();

const emit = defineEmits<{ 'signed-in': [] }>();

const store = useStore();
const { googleCodeSignIn } = useAuth(store);
const { loadCart, loadLineItemExpansions } = useCart(store);

const signIn = useCallback(async (response: CallbackTypes.CodePopupResponse) => {
  const signInResponse = await googleCodeSignIn(response, { preserveCart: props.isCheckout });
  if (signInResponse) {
    emit('signed-in');
    await loadCart(true);
    await loadLineItemExpansions();
  }
});
const { clientId } = getConfigEntry('google').oauth;
</script>

<template>
  <div>
    <GoogleLogin :class="{ 'w-full': fullWidth }" :callback="signIn.execute" :clientId="clientId">
      <ThemedButton
        class="sm:h-12 h-11"
        :fullWidth="fullWidth"
        :isLoading="signIn.isPending"
        theme="white"
      >
        <img
          v-if="!signIn.isPending"
          aria-hidden="true"
          alt="google"
          class="object-contain w-6 h-6 mr-2 shrink-0"
          src="@/assets/social/google.svg"
        />
        Continue with Google
      </ThemedButton>
    </GoogleLogin>
  </div>
</template>

<style scoped>
.api-loading {
  opacity: 100% !important;
  pointer-events: auto !important;
}
</style>
