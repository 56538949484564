/* eslint-disable camelcase */
import { fromNutsJson, NutsJson, webstore } from '@/api';

export interface RestrictedCountrySkusItem {
  readonly [sku: string]: string[];
}

interface RestrictedCountrySkusResponse {
  readonly restrictedCountrySkus: RestrictedCountrySkusItem;
}

export const getRestrictedCountrySkus = async (skus: string[]) => {
  const {
    data: { restrictedCountrySkus },
  } = await fromNutsJson(
    webstore.get<NutsJson<RestrictedCountrySkusResponse>>(
      `/api/restricted-country-skus?skus=${skus.join(',')}`,
    ),
  );
  return restrictedCountrySkus;
};

export default {};
