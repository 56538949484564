<script setup lang="ts">
import '@typeform/embed/build/css/popup.css';

import { createPopup } from '@typeform/embed';
import { onMounted, ref } from 'vue';

import { useLocalStorage } from '@/composables/useLocalStorage';

const { storageKey, surveyId } = defineProps<{ storageKey?: string; surveyId: string }>();

const canBeHidden = ref(!!storageKey);
const isVisible = ref(!canBeHidden.value);

const onClose = () => {
  if (canBeHidden.value) {
    useLocalStorage(storageKey).set('done', true);
    isVisible.value = false;
  }
};

const onClick = () => {
  createPopup(surveyId, { onSubmit: onClose }).toggle();
};

onMounted(() => {
  isVisible.value = !canBeHidden.value || !useLocalStorage(storageKey).get<boolean>('done');
});
</script>

<template>
  <transition name="fade">
    <div v-if="isVisible">
      <slot :onClick="onClick" :onClose="onClose" />
    </div>
  </transition>
</template>
