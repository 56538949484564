/* eslint-disable import/prefer-default-export */
import { useMutationObserver } from '@vueuse/core';
import { computed, onMounted, Ref, ref } from 'vue';

export const useFocusTrap = (element: Ref<HTMLElement | undefined>) => {
  const focusableElements = ref<NodeListOf<HTMLElement> | HTMLElement[]>([]);

  const firstFocusableElement = computed(() => focusableElements.value[0]);
  const lastFocusableElement = computed(
    () => focusableElements.value[focusableElements.value.length - 1],
  );

  const onNextElement = (event: Event) => {
    if (lastFocusableElement.value === document.activeElement) {
      event.preventDefault();
      firstFocusableElement.value?.focus();
    }
  };

  const onPreviousElement = (event: Event) => {
    if (firstFocusableElement.value === document.activeElement) {
      event.preventDefault();
      lastFocusableElement.value?.focus();
    }
  };

  const refreshFocusableElements = () => {
    focusableElements.value =
      element.value?.querySelectorAll<HTMLElement>(
        `[href], input:not([disabled]),
      select:not([disabled]), textarea:not([disabled]):not([name="g-recaptcha-response"]),
      [tabindex]:not([tabindex="-1"]):not([disabled])`,
      ) ?? [];
  };

  onMounted(() => {
    refreshFocusableElements();
    useMutationObserver(element, refreshFocusableElements, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  });

  return {
    onNextElement,
    onPreviousElement,
  };
};
