<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'FAQ',
  props: {
    borderColors: { required: false, type: String, default: 'black' },
    title: { required: false, type: String, default: 'Frequently Asked Questions' },
    titleSize: { required: false, type: String as PropType<'large' | 'medium'>, default: 'large' },
  },
  setup(props) {
    const borderUtilities = computed(() => {
      const utilities: string[] = ['border-t border-b'];
      if (props.borderColors === 'gray') {
        utilities.push('border-nuts-neutral-400 divide-nuts-neutral-400');
      } else {
        utilities.push('border-black divide-black');
      }
      return utilities.join(' ');
    });

    const titleUtilities = computed(() => {
      let utilities;

      if (props.titleSize === 'large') {
        utilities = 'text-3xl md:text-5xl mb-16';
      } else if (props.titleSize === 'medium') {
        utilities = 'text-2xl md:text-4xl mb-8';
      }
      return utilities;
    });

    return { borderUtilities, titleUtilities };
  },
});
</script>

<template>
  <div>
    <div v-if="title" class="font-semibold font-sofia-pro" :class="titleUtilities">
      {{ title }}
    </div>
    <!-- eslint-disable-next-line max-len -->
    <div class="border-t border-b border-solid divide-y divide-solid" :class="[borderUtilities]">
      <slot />
    </div>
  </div>
</template>
