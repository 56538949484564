<script>
import RouteLink from '@/components/base/RouteLink.vue';

export default {
  name: 'SearchBarStaticContent',
  components: {
    RouteLink,
  },
};
</script>

<template>
  <div>
    <input type="submit" class="button" value="Search" data-test="search-button" />
    <p>
      <strong>Looking for </strong>
      <RouteLink
        to="/nuts/almonds/"
        data-promo="1"
        data-promo-name="Header Links"
        data-promo-creative="Search Suggestions: Almonds"
        >Almonds</RouteLink
      >,
      <RouteLink
        to="/nuts/cashews/"
        data-promo="1"
        data-promo-name="Header Links"
        data-promo-creative="Search Suggestions: Cashews"
        >Cashews</RouteLink
      >,
      <RouteLink
        to="/nuts/walnuts/"
        data-promo="1"
        data-promo-name="Header Links"
        data-promo-creative="Search Suggestions: Walnuts"
        >Walnuts</RouteLink
      >
      <strong> or </strong>
      <RouteLink
        to="/organic/"
        data-promo="1"
        data-promo-name="Header Links"
        data-promo-creative="Search Suggestions: Organic"
        >Organic</RouteLink
      >?
    </p>
  </div>
</template>

<style lang="scss" scoped>
.button {
  position: absolute;
  right: 87px;
  top: 4px;
  box-sizing: border-box;
  padding: 0 15px;
  line-height: 28px;
  height: 31px;
  color: black;
  background-color: $sunglow;
  @include respond-max($screen-xs-max) {
    right: 4px;
    z-index: 6;
  }
}
:deep(a) {
  color: $blue-dianne;
  text-decoration: underline;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: $line-height-large;
}
p {
  padding-top: 6px;
  margin-bottom: 0;
  font-size: 14px;
  font-family: $font-family-proxima;
  font-style: normal;
  font-weight: 700;
  line-height: $line-height-large;
  color: #333;
  @include respond-max($screen-xs-max) {
    color: black;
  }
}
</style>
