<script setup lang="ts">
import UnstyledButton from '@/components/base/UnstyledButton.vue';

defineEmits<{ click: [] }>();
const closeIcon = nutshell['img/close.svg'];
</script>

<template>
  <UnstyledButton
    class="flex items-center justify-center w-6 h-6 p-1 overflow-hidden rounded bg-white/50"
    @click="$emit('click')"
  >
    <img
      alt="Close"
      class="h-3.5 w-3.5 md:w-4 md:h-4 shrink-0"
      data-test="close-modal"
      :src="closeIcon"
    />
  </UnstyledButton>
</template>
