import { ctApi } from '@/api';

async function productsByKeysAndAttributes(
  keys: string[],
  limit: number,
  // attributes: { active: boolean; backordered: boolean },
) {
  try {
    if (keys.length === 0) return [];
    const joinedKeys = keys.map((k) => `"${k}"`).join(',');
    const response = await ctApi
      .products()
      .get({
        queryArgs: {
          where: `key in (${joinedKeys})`,
          // and masterVariant(attributes(name="backordered" or value(key="${
          //   attributes.backordered ? 'yes' : 'no'
          // }")) or attributes(name="active" and value="${attributes.active ? 'yes' : 'no'}"))`,
          limit,
        },
      })
      .execute();
    const {
      body: { results: products },
    } = response;
    return products;
  } catch (err) {
    console.error('Failed to fetch products: %o', keys);
    return [];
  }
}

export default productsByKeysAndAttributes;
