import { Cart, Customer, Order } from '@commercetools/platform-sdk';
import { from } from '@nuts/auto-delivery-sdk/dist/utils/money';

import { getAmountToActivateDiscount, getShippingCartDiscount } from '@/lib/cart/discounts';

export const FLAT_RATE_THRESHOLD = from(59);

export function getFreeShippingThresholdFromAlstTag(customer?: Customer) {
  const tags: string[] = customer?.custom?.fields?.tags ?? [];
  const thresholds = tags.flatMap((tag) => tag.match(/alst-(\d+)-free-shipping/)?.[1] ?? []);
  if (!thresholds.length) return undefined;
  const threshold = Math.min(...thresholds.map(Number));
  return threshold;
}

export function getFreeShippingThresholdAmount(customer?: Customer, cart?: Cart | Order) {
  const freeShippingDiscount = getShippingCartDiscount(cart);
  if (freeShippingDiscount) {
    const delta = getAmountToActivateDiscount(freeShippingDiscount.cartPredicate, from(0));
    if (delta) return delta.threshold;
  }
  const alstThreshold = getFreeShippingThresholdFromAlstTag(customer);
  return alstThreshold ? from(alstThreshold) : FLAT_RATE_THRESHOLD;
}
