<script setup lang="ts">
import { computed, watch } from 'vue';

import { isDefined } from '@/utils/isDefined';

const props = withDefaults(
  defineProps<{
    errors: any[];
    fieldName: string;
    invalid?: boolean;
  }>(),
  { invalid: undefined },
);

export interface InputFailedEvent {
  field: string;
  error: string;
}
const emit = defineEmits<{
  'input-failed': [InputFailedEvent];
}>();

const inputFailed = computed(
  () => props.errors.length && (isDefined(props.invalid) ? props.invalid : true),
);

watch(
  () => props.errors,
  (currentError, previousError) => {
    if (currentError.length > 0 && currentError[0] !== previousError[0]) {
      emit('input-failed', { field: props.fieldName, error: currentError[0] });
    }
  },
  { deep: true },
);
</script>

<template>
  <transition name="fade">
    <div
      v-if="inputFailed"
      class="absolute pt-1 pl-px text-xs leading-3 text-nuts-red-800"
      data-test="inline-error"
      role="alert"
    >
      {{ errors[0] }}
    </div>
  </transition>
</template>
