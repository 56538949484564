import { Cart } from '@commercetools/platform-sdk';

import {
  fromAxiosNutsJson,
  FromNutsJsonOptions,
  NutsJson,
  unwrapNutsJsonErrors,
  webstore,
} from '@/api';

interface ApplyDiscountCodeTokenResponse {
  cart?: Cart;
}
export const applyDiscountCodeToken = async (
  token: string,
  onMessages?: FromNutsJsonOptions['onMessages'],
): Promise<ApplyDiscountCodeTokenResponse> => {
  try {
    const response = await fromAxiosNutsJson(
      webstore.post<NutsJson<ApplyDiscountCodeTokenResponse>>(
        `/campaign-landing`,
        {},
        {
          params: { token },
        },
      ),
      { onMessages },
    );

    return response;
  } catch (e) {
    if (typeof onMessages === 'function') {
      onMessages({ errors: unwrapNutsJsonErrors(e) });
    }
    return {};
  }
};

export default {};
