// This file should be used for non-SPA mustache "surrogates" only

export { default as Assets } from '@/components/layout/footer/Assets.vue';
export { default as DynamicYieldPageContext } from '@/components/layout/head/scripts/DynamicYieldPageContext.vue';
export { default as CmsDyBanner } from '@/components/base/layout/CmsDyBanner.vue';
export { default as DyProductRecommendationsRow } from '@/components/recommendations/DyProductRecommendationsRow.vue';
export { default as Footer } from '@/components/layout/footer/Footer.vue';
export { default as BaseHeader } from '@/components/layout/header/BaseHeader.vue';
export { default as BaseFullscreenCenteredBanner } from '@/components/base/layout/BaseFullscreenCenteredBanner.vue';
export { default as GiftFinderWithDropdowns } from '@/components/recommendations/GiftFinderWithDropdowns.vue';
export { default as NavMenu } from '@/components/account/NavMenu.vue';
export { default as OrdersTable } from '@/components/account/OrdersTable.vue';
export { default as RecommendationsWithFilters } from '@/components/recommendations/RecommendationsWithFilters.vue';

export const EasyReorderEntryPoint = () => import('@/components/account/EasyReorderEntryPoint.vue');
export const AccountBanner = () => import('@/components/base/layout/AccountBanner.vue');
export const AutoDelivery = () => import('@/views/AutoDelivery.vue');
export const AutoDeliveryDetails = () => import('@/views/AutoDeliveryDetails.vue');
export const BusinessAccount = () => import('@/views/BusinessAccount.vue');
export const CheckingAccount = () => import('@/components/account/wallet/CheckingAccount.vue');
export const CLP = () => import('@/views/CLP.vue');
export const EasyReorder = () => import('@/views/EasyReorder.vue');
export const OrderDetails = () => import('@/views/OrderDetails.vue');
export const Refer = () => import('@/views/Refer.vue');
export const ReviewOrder = () => import('@/views/ReviewOrder.vue');
export const ReviewSuggestion = () => import('@/components/reviews/ReviewSuggestion.vue');
