import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';

import { ProductSubscriptionCreated, productSubscriptionCreated } from '@/rudder-typer';
import { NutsProductSubscriptionCreated } from '@/utils/analytics/rudderstack';

export function formatProductSubscribed(
  baseProductAnalytics: NutsProductSubscriptionCreated,
): ProductSubscriptionCreated {
  return {
    ...baseProductAnalytics,
    price: dollars(baseProductAnalytics.price),
  };
}

export function sendProductSubscribedEvent(nutsProductSubscribed: NutsProductSubscriptionCreated) {
  productSubscriptionCreated(formatProductSubscribed(nutsProductSubscribed));
}
