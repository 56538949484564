<script setup lang="ts">
import CloseIcon from '@/components/base/assets/CloseIcon.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';

withDefaults(defineProps<{ removable?: boolean; tag?: keyof HTMLElementTagNameMap }>(), {
  tag: 'li',
});

const emit = defineEmits(['click', 'remove']);
</script>

<template>
  <component
    class="relative flex items-center justify-between hover:bg-nuts-stone-100 active:bg-nuts-stone-100 h-9"
    :is="tag"
  >
    <UnstyledButton
      class="flex items-center justify-between w-full h-full px-4"
      data-test="search-item-button"
      @click="emit('click')"
    >
      <div class="flex items-center gap-3">
        <slot name="left" />
        <BaseBodyText>
          <slot />
        </BaseBodyText>
      </div>
      <slot v-if="!removable" name="right" />
    </UnstyledButton>
    <UnstyledButton
      v-if="removable"
      aria-label="remove"
      class="absolute flex items-center justify-center p-1 right-4"
      data-test="remove-search-item-button"
      @click="emit('remove')"
    >
      <CloseIcon class="text-neutral-500" :size="16" />
    </UnstyledButton>
  </component>
</template>
