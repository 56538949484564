<script setup lang="ts">
import { useElementBounding } from '@vueuse/core';
import { ref } from 'vue';

const footerContainer = ref<HTMLElement>();
const { height: footerOffset } = useElementBounding(footerContainer);
</script>

<template>
  <div class="flex flex-col overflow-auto container-height">
    <slot name="header" />
    <div class="flex-1 overflow-auto md:overflow-visible md:contents">
      <slot name="body" />
    </div>
    <template v-if="$slots.footer">
      <span class="footer-offset" />
      <div
        class="fixed bottom-0 hidden w-full p-4 bg-white md:max-w-xl md:visible md:block"
        ref="footerContainer"
      >
        <div class="absolute inset-0 pointer-events-none footer-shadow" />
        <slot name="footer" />
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.container-height {
  max-height: 80vh;
  @media screen and (min-width: $tailwind-md-min) {
    max-height: 100vh;
  }
}

.footer-offset {
  margin-bottom: v-bind('`${footerOffset}px`');
}

.footer-shadow {
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1), 0px -8px 15px -3px rgba(0, 0, 0, 0.1);
}
</style>
