<script setup lang="ts">
// eslint-disable-next-line simple-import-sort/imports
import { computed, ref } from 'vue';

import FormInput from '@/components/base/form/FormInput.vue';

// eslint-disable-next-line import/no-duplicates
import { validationRules } from '@/utils/cms';

interface Props {
  name: string;
  placeholder: string;
  layout?: 'label' | 'placeholder';
  validation?: keyof typeof validationRules;
}

const props = withDefaults(defineProps<Props>(), {
  layout: 'label',
});

const inputValue = ref();

const showLabel = computed(() => props.layout === 'label');
const showPlaceholder = computed(() => props.layout === 'placeholder');

const inputAttributes = computed(() => ({
  name: props.name,
  placeholder: props.placeholder,
}));
</script>

<script lang="ts">
// eslint-disable-next-line import/first, import/no-duplicates
import { BuilderComponent } from '@/utils/cms';

// eslint-disable-next-line import/prefer-default-export
export const FormInputWrapperRegistration: BuilderComponent = {
  name: 'Form Input',
  inputs: [
    {
      name: 'name',
      type: 'string',
      required: true,
      defaultValue: 'input-name',
      helperText: 'Name of the input used for message errors',
    },
    {
      name: 'placeholder',
      type: 'string',
      required: true,
      defaultValue: 'Input placeholder',
      helperText: 'Used for input placeholder and label',
    },
    {
      name: 'layout',
      type: 'string',
      defaultValue: 'label',
      enum: ['label', 'placeholder'],
    },
    {
      name: 'validation',
      type: 'text',
      enum: ['email', 'phone', 'required'],
    },
  ],
};
</script>

<template>
  <FormInput
    v-model="inputValue"
    class="mt-5"
    :inputAttributes="inputAttributes"
    :showLabel="showLabel"
    :showPlaceholder="showPlaceholder"
    :validator="validationRules[validation]"
  />
</template>
