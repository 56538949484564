<script setup lang="ts">
import { computed, toRef, unref, useAttrs } from 'vue';

import { useForm } from '@/composables/useForm';

const props = withDefaults(
  defineProps<{
    invalid?: boolean;
    showPlaceholder?: boolean;
    modelValue?: number | string;
  }>(),
  { modelValue: '' },
);

defineEmits(['blur', 'change', 'input', 'update:modelValue']);

const { useStyles } = useForm();
const attrs = useAttrs();

const { borderRules, fontRules, sizeRules } = useStyles(toRef(props, 'invalid'));
const borderUtilities = computed(() => unref(borderRules).join(' '));
const fontUtilities = computed(() => {
  let utilities = [...unref(fontRules)];
  if (attrs.type === 'password') utilities.push('tracking-widest');
  if (props.showPlaceholder) {
    utilities = utilities.filter((util) => !util.startsWith('placeholder'));
    if (props.invalid) utilities.push('placeholder-red-700');
  }
  return utilities.join(' ');
});
const sizeUtilities = computed(() => [...unref(sizeRules)].join(' '));
</script>

<template>
  <input
    :class="[borderUtilities, fontUtilities, sizeUtilities]"
    :value="typeof $attrs.value !== 'undefined' ? $attrs.value : modelValue"
    @blur="$emit('blur', $event)"
    @input="$emit('input', $event.target.value), $emit('update:modelValue', $event.target.value)"
    @change="$emit('change', $event.target.value)"
  />
</template>
