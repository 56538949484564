<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { computed, ref } from 'vue';

import ChevronIcon from '@/components/base/assets/ChevronIcon.vue';
import ClockTimeArrowIcon from '@/components/base/assets/ClockTimeArrowIcon.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import SearchItem from '@/components/layout/header/search/SearchItem.vue';

const MAX_SUGGESTIONS = 3;

const props = defineProps<{ modelValue: string[] }>();

const emit = defineEmits<{
  click: [string];
  'update:modelValue': [string[]];
}>();

const expanded = ref(false);

const maxSuggestions = computed(() => (expanded.value ? props.modelValue.length : MAX_SUGGESTIONS));
const recentSearches = useVModel(props, 'modelValue', emit);

const removeRecentSearch = (entry: string) => {
  recentSearches.value = recentSearches.value.filter((search) => search !== entry);
};
</script>

<template>
  <div>
    <SmallBodyText class="px-4 text-neutral-500">Your Recent Searches</SmallBodyText>
    <ul class="mt-1">
      <SearchItem
        v-for="entry in recentSearches.slice(0, maxSuggestions)"
        :key="entry"
        removable
        @click="emit('click', entry)"
        @remove="removeRecentSearch(entry)"
      >
        <template #left><ClockTimeArrowIcon :size="20" /></template>
        {{ entry }}
      </SearchItem>
    </ul>
    <div v-if="recentSearches.length > MAX_SUGGESTIONS" class="flex items-center justify-center">
      <UnstyledButton
        class="flex items-center gap-1 px-8 transition-all text-neutral-500"
        @click="expanded = !expanded"
        data-test="recent-searches-see-more-button"
      >
        <SmallBodyText>See {{ expanded ? 'less' : 'more' }}</SmallBodyText>
        <ChevronIcon :direction="expanded ? 'up' : 'down'" :size="16" />
      </UnstyledButton>
    </div>
  </div>
</template>
