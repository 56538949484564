import { NutsJson, webstore } from '@/api';
import { TokenizedPaymentMethod } from '@/api/paymentMethod';
import { OrderDetailsPayload } from '@/utils/orderDetails';

interface ChangeOrderPaymentMethodRequest {
  orderId: number;
  customerId: string;
  paymentMethod: TokenizedPaymentMethod;
}

export interface ChangeOrderPaymentMethodResponse {
  errors?: string[];
  status: string;
  order: OrderDetailsPayload;
}

export async function changeOrderPaymentMethodAndAuthorize(
  request: ChangeOrderPaymentMethodRequest,
) {
  const { data } = await webstore.sessionSpecific.post<NutsJson<ChangeOrderPaymentMethodResponse>>(
    '/api/orders/change-payment-method',
    request,
  );
  return data;
}

export async function clearCopiedOrder() {
  return webstore.sessionSpecific.post('/api/orders/clear-copied-order');
}
