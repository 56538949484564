const numToSxg = (n, f) => {
  const m = '0123456789ABCDEFGHJKLMNPQRSTUVWXYZ_abcdefghijkmnopqrstuvwxyz';
  let d = 0;
  let s = '';

  if (n === undefined || n === 0) {
    return 0;
  }
  while (n > 0) {
    d = n % 60;
    s = m[d] + s;
    // eslint-disable-next-line no-param-reassign
    n = (n - d) / 60;
  }
  if (f === undefined) {
    // eslint-disable-next-line no-param-reassign
    f = 1;
  }
  // eslint-disable-next-line no-param-reassign
  f -= s.length;
  while (f > 0) {
    s = `0${s}`;
    // eslint-disable-next-line
    --f;
  }
  return s;
};

const sxgToNum = (s) => {
  const j = s.length;
  let c = 0;
  let n = 0;

  for (let i = 0; i < j; i += 1) {
    c = s.charCodeAt(i);
    if (c >= 48 && c <= 57) {
      c -= 48;
    } else if (c >= 65 && c <= 72) {
      c -= 55;
    } else if (c === 73 || c === 108) {
      c = 1;
    } else if (c >= 74 && c <= 78) {
      c -= 56;
    } else if (c === 79) {
      c = 0;
    } else if (c >= 80 && c <= 90) {
      c -= 57;
    } else if (c === 95) {
      c = 34;
    } else if (c >= 97 && c <= 107) {
      c -= 62;
    } else if (c >= 109 && c <= 122) {
      c -= 63;
    } else {
      c = 0;
    }
    n = 60 * n + c;
  }
  return n;
};

export default {
  numToSxg,
  sxgToNum,
};
