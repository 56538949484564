<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{ rating: number }>();

const ratingClass = computed(() => {
  const floorRating = `${Math.floor(props.rating * 2) / 2}`;
  return `rating-${floorRating.replace('.', '-')}`;
});
</script>

<template>
  <div class="ptn-rating-stars" :class="ratingClass" data-test="rating-stars">
    <span class="sr-only">{{ rating }} out of 5 stars</span>
  </div>
</template>

<style lang="scss" scoped>
.ptn-rating-stars {
  position: relative;

  &:before {
    content: ' ';
    @include retina-sprite($sprite-stars-grey-1x);
  }

  &:after {
    content: ' ';
    @include retina-sprite($sprite-stars-yellow-1x);
  }

  &.rating-0 {
    &:after {
      width: 0px;
    }
  }
  &.rating-0-5 {
    &:after {
      width: 16px;
    }
  }
  &.rating-1 {
    &:after {
      width: 33px;
    }
  }
  &.rating-1-5 {
    &:after {
      width: 53px;
    }
  }
  &.rating-2 {
    &:after {
      width: 66px;
    }
  }
  &.rating-2-5 {
    &:after {
      width: 90px;
    }
  }
  &.rating-3 {
    &:after {
      width: 101px;
    }
  }
  &.rating-3-5 {
    &:after {
      width: 127px;
    }
  }
  &.rating-4 {
    &:after {
      width: 146px;
    }
  }
  &.rating-4-5 {
    &:after {
      width: 165px;
    }
  }
  &.rating-5 {
    &:after {
      width: 180px;
    }
  }
}
</style>
