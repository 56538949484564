<script setup lang="ts">
import { computed } from 'vue';

import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import ReviewsPopper from '@/components/reviews/ReviewsPopper.vue';
import { SkuForReview } from '@/lib/reviews';

const props = defineProps<{
  orders: {
    date: string;
    id: number;
    status: string;
    year: string;
  }[];
  skuForReview?: SkuForReview;
}>();

const isAccountPage = computed(() => {
  if (typeof window === 'undefined') return false;
  return window.location.pathname === '/account';
});
const firstReviewableOrder = computed(() => {
  if (!props.skuForReview) return undefined;
  return props.orders.find(({ id }) => id === props.skuForReview?.orderNumber);
});
</script>

<template>
  <table
    v-if="orders.length"
    class="divide-y orders-table divide-solid divide-nuts-neutral-200"
    data-test="orders-table"
  >
    <thead>
      <tr class="text-right">
        <th class="text-left">Date</th>
        <th>Status</th>
        <th>Tracking Numbers</th>
        <th>Order</th>
      </tr>
    </thead>
    <tbody class="divide-y divide-solid divide-nuts-neutral-200">
      <tr v-for="order in orders" class="text-right" :key="order.id">
        <td class="text-left">{{ order.date }}{{ order.year }}</td>
        <td>{{ order.status }}</td>
        <td>
          <a :href="`/orders/${order.id}/track`">
            <span class="sr-only">Track order</span>
            Track {{ order.id }}
          </a>
        </td>
        <td>
          <component
            :is="firstReviewableOrder?.id === order.id ? ReviewsPopper : 'span'"
            :location="isAccountPage ? 'account' : 'order-history'"
            v-slot="popperProps"
          >
            <a :href="`/orders/${order.id}`" @click="popperProps?.dismiss()">
              <span class="sr-only">Order details</span>
              {{ order.id }}
            </a>
          </component>
        </td>
      </tr>
    </tbody>
  </table>
  <BaseBodyText v-else class="font-semibold">You haven't placed any orders yet.</BaseBodyText>
</template>

<style lang="scss" scoped>
.orders-table {
  th,
  td {
    @apply p-1.5 first:pl-0 last:pr-0 text-sm lg:text-base;
  }
  td {
    @apply font-semibold;
  }
}
</style>
