import { getFormattedAddress, search } from '@/api/experian';
import { NutsAddress } from '@/utils/address';

export interface ExpandableAddressSuggestion {
  expandUrl?: string;
  id: string;
}

export interface AddressSuggestion {
  label: string;
  matched: [number, number][];
  value: ExpandableAddressSuggestion;
}

export async function expandSuggestion(
  suggestion: ExpandableAddressSuggestion,
  countryCode: string,
): Promise<NutsAddress> {
  return NutsAddress.fromExperian(await getFormattedAddress(suggestion.expandUrl!), countryCode);
}

export async function getAddressSuggestions(
  addressFragment: string,
  countryCode: string,
  coordinates?: string,
): Promise<AddressSuggestion[]> {
  const options: AddressSuggestion[] = [];
  const { suggestions = [] } = await search(addressFragment, countryCode, coordinates);
  options.push(
    ...suggestions.map<AddressSuggestion>((suggestion) => ({
      label: suggestion.text,
      matched: suggestion.matched ?? [],
      value: {
        expandUrl: suggestion.format,
        id: suggestion.global_address_key,
      },
    })),
  );
  return options;
}
