import endsWith from 'lodash/endsWith';

// cars => car, Addresses => Address, boxes => box
// yes this is a hack and should carefully be made smarter or something
// maybe borrow from https://github.com/blakeembrey/pluralize
const singularize = (word: string) => {
  if (endsWith(word, 'sses') || endsWith(word, 'xes')) {
    return word.substring(0, word.length - 2);
  }
  return word.substring(0, word.length - 1);
};

export default singularize;
