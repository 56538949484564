<script setup lang="ts">
import { Price } from '@commercetools/platform-sdk';
import { money } from '@nuts/auto-delivery-sdk/dist/utils/format';
import { computed } from 'vue';

import DiscountBadge from '@/components/base/DiscountBadge.vue';
import Header5 from '@/components/base/typography/Header5.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import { LineSavingsSummary, Money } from '@/utils/money';

const props = defineProps<{
  /** Optional in CT but expected to be required in practice */
  prices?: Price[];
  quantity: number;
  totalPriceBeforeCartLevelDiscount: Money;
  totalSavings?: LineSavingsSummary;
}>();

const totalListPriceBeforeCartLevelDiscount = computed(() => {
  const listPrice = props.prices!.find((p) => !p.channel)!;
  const { value } =
    listPrice.tiers?.filter((t) => props.quantity >= t.minimumQuantity).pop() ?? listPrice;

  return Money.multiply(value, props.quantity);
});
</script>

<template>
  <div class="flex flex-wrap items-end gap-x-2 md:justify-end" data-test="inline-product-price">
    <span v-if="totalSavings" class="sr-only">Discounted price of </span>
    <Header5 :class="totalSavings?.onSale ? 'text-nuts-red-800' : 'text-nuts-neutral-800'">
      {{ money(totalPriceBeforeCartLevelDiscount) }}
    </Header5>
    <DiscountBadge
      v-if="totalSavings?.onSale && totalSavings.discountDisplayValue"
      :discountDisplayValue="totalSavings.discountDisplayValue"
      discountType="product"
      size="sm"
    />
    <span v-if="totalSavings" class="sr-only">Price without discount</span>
    <SmallBodyText
      v-if="totalSavings"
      class="line-through text-nuts-neutral-800"
      data-test="strike-through-price"
    >
      {{ money(totalSavings?.comparisonPrice ?? totalListPriceBeforeCartLevelDiscount) }}
    </SmallBodyText>
  </div>
</template>
