<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EmailDisplay',
  props: {
    email: { required: true, type: String },
  },
  emits: ['change-email'],
  setup(_, { emit }) {
    return {
      onChangeEmail: () => emit('change-email'),
    };
  },
});
</script>

<template>
  <div class="flex flex-col text-sm sm:text-base" :class="{ 'sm:flex-row': email.length <= 30 }">
    <span class="flex mr-2">
      Email:
      <strong class="ml-1">{{ email }}</strong>
    </span>
    <button
      aria-label="change email"
      data-test="change-email"
      class="p-0 mt-1 bg-transparent border-0 appearance-none cursor-pointer sm:mt-0 w-min"
      type="button"
      @click="onChangeEmail"
    >
      <span class="text-sm text-black underline font-proxima-nova">Change</span>
    </button>
  </div>
</template>
