<script setup lang="ts">
import { reportEngagement } from '@/api/dynamic-yield/engagement';
import brokenPeanut from '@/assets/broken-peanut.png';
import RouteLink from '@/components/base/RouteLink.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import { BaseChoice } from '@/lib/personalization/dynamicYield';
import { sendProductClickedEvent } from '@/utils/analytics/rudderstack';
import { ProductCardData } from '@/utils/productCard';

const props = withDefaults(
  defineProps<{
    decisionId?: BaseChoice['decisionId'];
    imageSize?: 'small' | 'medium';
    index?: number;
    linkToPDP?: boolean;
    product: ProductCardData;
  }>(),
  {
    linkToPDP: true,
  },
);

const reportClick = () => {
  if (props.decisionId && props.product.slotId) {
    reportEngagement(props.decisionId, props.product.slotId);
  }

  if (props.linkToPDP) {
    sendProductClickedEvent(props.product, props.index);
  }
};
</script>

<template>
  <div class="flex items-center">
    <component
      :is="linkToPDP ? RouteLink : 'div'"
      :to="linkToPDP ? product.path : undefined"
      class="flex items-center justify-center no-underline hover:no-underline"
      @click="reportClick"
    >
      <div
        class="bg-white"
        :class="{
          'w-20 h-14': imageSize === 'small',
          'w-24 h-14 md:h-20 md:w-32': imageSize === 'medium',
        }"
      >
        <img
          v-if="product.imageUrl"
          :alt="product.name"
          class="object-contain w-full h-full"
          :src="product.imageUrl"
        />
        <img
          v-else
          alt="placeholder image"
          class="object-contain w-16 h-12 md:w-24 md:h-16 shrink-0"
          :src="brokenPeanut"
        />
      </div>
    </component>
    <div class="flex flex-col w-full ml-2">
      <component
        :is="linkToPDP ? RouteLink : 'div'"
        :to="linkToPDP ? product.path : undefined"
        class="no-underline hover:no-underline"
        @click="reportClick"
      >
        <BaseBodyText class="font-semibold text-black" data-test="product-title">
          <slot name="title">{{ product.name }}</slot>
        </BaseBodyText>
      </component>
      <div class="flex items-center mt-1">
        <slot name="price" />
        <div v-if="product.averageRating" class="flex items-center">
          <img
            aria-hidden="true"
            alt="star"
            class="object-contain w-4 h-4 mx-1 shrink-0"
            src="@/assets/pdp/star-full.svg"
          />
          <span class="sr-only" data-test="total-reviews">
            {{ product.averageRating.toFixed(1) }} out of 5 stars
            {{ product.totalReviews ? `and ${product.totalReviews} reviews` : '' }}
          </span>
          <BaseBodyText aria-hidden="true" class="text-neutral-500">
            ({{ product.averageRating.toFixed(1) }}) {{ product.totalReviews }}
          </BaseBodyText>
        </div>
      </div>
      <slot name="button" />
    </div>
  </div>
</template>
