<script lang="ts">
import { defineComponent, ref } from 'vue';

import FloatingLabel from '@/components/base/form/FloatingLabel.vue';
import Select from '@/components/base/form/OutlinedSelect.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';

export default defineComponent({
  name: 'GiftFinderWithDropdowns',
  props: {
    backgroundColor: { required: false, type: String, default: 'nuts-sky-100' },
    body: { required: true, type: String },
    callToActionText: { required: false, type: String, default: 'See Gift Suggestions' },
    header: { required: true, type: String },
  },
  components: {
    FloatingLabel,
    // eslint-disable-next-line vue/no-reserved-component-names
    Select,
    ThemedButton,
  },
  setup() {
    const selectedOccasion = ref();
    const selectedBudget = ref();
    const selectedTaste = ref();

    const occasionOptions = [
      { label: 'Birthday', value: 'Birthday' },
      { label: 'Anniversary', value: 'Anniversary' },
    ];
    const budgetOptions = [
      { label: 'Under $40', value: 'Under $40' },
      { label: 'Under $70', value: 'Under $70' },
      { label: 'Premium gifts', value: 'Premium gifts' },
    ];
    const tasteOptions = [
      { label: 'Sweet', value: 'Sweet' },
      { label: 'Salty', value: 'Salty' },
    ];

    const handleSeeSuggestions = () => {
      // eslint-disable-next-line no-console
      console.log('get suggestions from algolia');
    };

    return {
      budgetOptions,
      handleSeeSuggestions,
      occasionOptions,
      selectedBudget,
      selectedOccasion,
      selectedTaste,
      tasteOptions,
    };
  },
});
</script>

<template>
  <div
    class="flex flex-col items-center justify-center w-full h-auto px-6 py-10 md:h-96"
    :class="`bg-${backgroundColor}`"
    data-promo="1"
    data-promo-name="GLP Gift Finder"
  >
    <div class="flex items-center text-center">
      <img
        src="https://nuts.com/images/dy/2022/start_left.27266a4b.png"
        class="max-h-12 md:max-h-16"
      />
      <div class="mx-4 text-3xl font-bold md:text-4xl">{{ header }}</div>
      <img
        src="https://nuts.com/images/dy/2022/star_right.2a07a4a2.png"
        class="max-h-12 md:max-h-16"
      />
    </div>
    <div class="max-w-3xl my-4 text-base font-semibold text-center md:text-lg">{{ body }}</div>
    <div class="flex flex-col w-full max-w-5xl gap-4 mt-5 mb-8 sm:flex-row sm:items-center">
      <div class="relative w-full md:w-1/3">
        <Select
          v-model="selectedOccasion"
          :options="occasionOptions"
          id="gift-occasion"
          name="What's the occasion?"
          aria-label="select occasion"
        />
        <FloatingLabel class="left-0">What's the occasion?</FloatingLabel>
      </div>
      <div class="relative w-full md:w-1/3">
        <Select
          v-model="selectedBudget"
          :options="budgetOptions"
          id="gift-buget"
          name="What's your budget?"
          placeholder="What's your budget?"
          aria-label="select occasion"
        />
        <FloatingLabel class="left-0">What's your budget?</FloatingLabel>
      </div>
      <div class="relative w-full md:w-1/3">
        <Select
          v-model="selectedTaste"
          :options="tasteOptions"
          id="gift-taste"
          name="What do they like?"
          placeholder="What do they like?"
          aria-label="select occasion"
        />
        <FloatingLabel class="left-0">What do they like?</FloatingLabel>
      </div>
    </div>
    <ThemedButton
      theme="gray"
      data-promo="1"
      data-promo-creative="See Gift Suggestions"
      data-promo-name="GLP Gift Finder"
      @click="handleSeeSuggestions"
    >
      {{ callToActionText }}
    </ThemedButton>
  </div>
</template>
