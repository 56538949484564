<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  dataTest: string;
  description?: string;
  extraDetails?: string;
  imageSource: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  title: string;
}>();

const emit = defineEmits(['selected']);

const borderStyles = computed(() => {
  if (props.isDisabled) {
    return 'border-dashed border-neutral-300';
  }
  if (props.isSelected) {
    return 'border-solid border-black cursor-pointer';
  }
  if (!props.isSelected) {
    return 'border-solid border-neutral-300 cursor-pointer';
  }

  return null;
});

const handleSelect = () => emit('selected');
const arrow = nutshell['img/caret-right.svg'];
</script>

<template>
  <div
    class="flex items-center justify-between h-20 p-4 border rounded-2xl md:h-28"
    :class="borderStyles"
    @click="handleSelect"
    @keydown.enter="handleSelect"
    tabindex="0"
    :data-test="dataTest"
  >
    <div class="flex items-center">
      <img :src="imageSource" class="w-1/3 h-full mr-4" alt />
      <div class="text-left">
        <div
          class="text-sm font-semibold md:text-base"
          :class="isDisabled ? 'text-neutral-500' : 'text-inherit'"
        >
          {{ title }}
        </div>
        <div v-if="description" class="text-xs md:text-sm text-neutral-500">
          {{ description }}
        </div>
      </div>
    </div>
    <div v-if="extraDetails" class="text-sm font-semibold md:text-base">{{ extraDetails }}</div>
    <div v-else class="hidden md:inline" :class="{ 'bg-white opacity-30': isDisabled }">
      <img :src="arrow" class="w-3 h-3" alt />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hidden {
  visibility: visible;
}
</style>
