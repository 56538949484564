/* eslint-disable no-shadow */
import { computed } from 'vue';

import { useState } from '@/composables/useState';

export enum ChatStatus {
  DISABLED = 'disabled',
  OFFLINE = 'offline',
  ONLINE = 'online',
  HIDDEN = 'hidden',
  NONE = 'none',
}

export function useChat() {
  const { isInitiated, displayChatInfoText, ignoreChatInfoText } = useState('chat', () => ({
    isInitiated: false,
    displayChatInfoText: false,
    ignoreChatInfoText: false,
  }));

  function openChat() {
    window.Kustomer?.open();
  }

  const isChatStatusPending = computed(
    () => !displayChatInfoText.value && !ignoreChatInfoText.value,
  );

  function initChat() {
    if (!isInitiated.value) {
      isInitiated.value = true;
      setTimeout(() => {
        ignoreChatInfoText.value = true;
      }, 3000);
      window.addEventListener('kustomerStarted', () => {
        const status = window.Kustomer.isChatAvailable();
        if (status.availability === ChatStatus.ONLINE && !ignoreChatInfoText.value) {
          displayChatInfoText.value = true;
        }
      });
    }
  }

  return {
    initChat,
    displayChatInfoText,
    openChat,
    isChatStatusPending,
  };
}

export default {};
