import { readonly } from 'vue';

import { UseCallback } from '@/composables/useCallback';
import { useState } from '@/composables/useState';

interface LoginModalState {
  callback?: UseCallback;
  destination?: string;
  initialStep: 'sign-in' | 'sign-up';
  selectBusiness: boolean;
  isOpen: boolean;
  isCheckout: boolean;
}

type Payload = Partial<Omit<LoginModalState, 'isOpen'>>;

const initialState: LoginModalState = {
  callback: undefined,
  destination: undefined,
  isCheckout: false,
  initialStep: 'sign-in',
  selectBusiness: false,
  isOpen: false,
};

export function useLoginModal() {
  const { state } = useState<{ state: LoginModalState }>('login-modal', () => ({
    state: { ...initialState },
  }));

  const handleClose = () => {
    Object.assign(state.value, initialState);
  };

  const handleOpen = (options?: Payload) => {
    Object.assign(state.value, options, { isOpen: true });
  };

  return {
    handleClose,
    handleOpen,
    state: readonly(state.value),
  };
}
export default {};
